function GemInsultGm(){
    return (
        <>
        <p id="MsoBodyTextIndent" className="green"> Для геморрагического инсульта при внутримозговой локализации, характерно: - видимое скопление крови в мозговой ткани, чаще 
        всего в области подкорковых ядер, мозгового ствола или мозжечка, размер и расположение гематомы зависят от степени и локализации разрыва сосуда; - отек головного мозга 
        (проявляется увеличением объема мозга, сглаживанием извилин и сужением борозд); cдавление желудочков мозга или их смещение из-за нарастания внутричерепного давления 
        (возможно крововоизлияние в сами желудочки головного мозга, либо прорыв кровоизлияния в них); - очаги некроза вокруг зоны кровоизлияния.
        1. Пример макроскопического описания головного мозга с внутримозговым кровоизлиянием, которое влияет на функционирование мозга и само по себе или через свои осложнения может 
        быть причиной смерти: 
Головной мозг увеличен в объеме, отмечается выраженная пастозность. Извилины сглажены, борозды сужены, что указывает на наличие общего отека мозга. Оболочки мозга напряжены, гиперемированы, с признаками пропитывания кровью.
В области левой височной доли обнаружено крупное внутримозговое кровоизлияние диаметром 5 см. Гематома темно-красного цвета, четко отграничена от окружающей ткани, с признаками разрушения мозговой паренхимы. Вокруг зоны кровоизлияния ткань мозга размягчена, с признаками компрессии.
Желудочки мозга сужены, IV желудочек смещен и деформирован за счет вклинения миндалин мозжечка в большое затылочное отверстие. Наблюдаются признаки дислокации структур мозга: смещение средней линии и компрессия ствола мозга.
Мозжечок пастозен, с выраженной инъекцией сосудов. Миндалины мозжечка вклинены в большое затылочное отверстие, что подтверждает нарастание внутричерепного давления и угрозу компрессии ствола мозга.
2. Пример макроскопического описания головного мозга с внутримозговым кровоизлиянием, которое не вызвает значительных изменений, таких как отек или компрессия, носит характер сопутствующего заболевания и не имеет значимого влияния на механизм смерти: 
Головной мозг без значительного увеличения в объеме. Извилины и борозды отчетливо видны, признаки выраженного общего отека отсутствуют. Мягкие мозговые оболочки прозрачные, умеренно гиперемированы.
В левой височной доле наблюдается небольшой участок внутримозгового кровоизлияния диаметром 1,5 см. Гематома имеет темно-красный цвет, окружена зоной легкой компрессии прилегающей мозговой ткани. Разрушение мозговой паренхимы минимально выражено, признаки некроза или разрывов ткани незначительны. Очаг кровоизлияния выглядит относительно стабильным, без признаков прорыва в желудочковую систему или значительного воздействия на окружающие отделы мозга.
Желудочки мозга не смещены и не деформированы. Признаки повышения внутричерепного давления отсутствуют. Ствол мозга и мозжечок без патологических изменений, миндалины мозжечка не вклинены.
</p>
<p id="MsoBodyTextIndent" className="green">
  Для геморрагического инсульта с локализацией субарахноидально либо субдурально, характерно:
Головной мозг увеличен в объеме из-за отека и накопления крови.
Поверхность мозга покрыта кровоизлияниями, видимыми в виде темно-красных или багровых пятен.
Борозды сглажены из-за отека мозга и давления кровоизлияния.
Извилины кажутся сплющенными.
Наблюдается выраженный отек, с повышенной влажностью и тугопластичностью ткани мозга.
Разрез мозга демонстрирует набухшие структуры с уплощением вентрикулярной системы.
В области основания мозга кровь может заполнять базальные цистерны, затрудняя дифференцировку анатомических структур.
При тяжелом субарахноидальном кровоизлиянии может быть отмечено вклинивание ствола мозга в большое затылочное отверстие.
При наличии длительного кровоизлияния кровь может быть частично разложившейся, приобретая темно-коричневый или черный цвет.
В случаях массивного кровоизлияния мозг может быть сдавлен до значительной деформации.
Макроскопическая картина при субарахноидальном и субдуральном геморрагическом инсульте включает значительное повреждение оболочек мозга, выраженный отек и массивное кровоизлияние, что приводит к компрессии структур мозга и жизнеугрожающим состояниям.
Субарахноидальное кровоизлияние:
Визуализируется скопление крови в субарахноидальном пространстве между паутинной и мягкой мозговыми оболочками.
Кровь часто имеет жидкую или сгустковую консистенцию, распространяясь по бороздам и щелям головного мозга, особенно в области базальных цистерн и борозд большого мозга.
Мягкая мозговая оболочка пропитана кровью, что придает ей интенсивно красный цвет.
Субдуральное кровоизлияние:
Локализуется между твердой мозговой оболочкой и паутинной оболочкой.
Кровоизлияние имеет вид плоского или выпуклого кровяного сгустка, сдавливающего прилегающие отделы мозга.
Твердая оболочка может быть напряжена и темно-красного оттенка из-за давления.
</p>
<p id="MsoBodyTextIndent" className="green">
Возможные макроскопические изменения в сосудах головного мозга при геморрагическом инсульте:
1. Разрыв сосуда:
Чаще обнаруживается в зоне кровоизлияния. На вскрытии выявляется дефект стенки артерии, который может быть окружен кровяным сгустком.
2. Аневризма:
В месте разрыва может быть выявлено истончение стенки сосуда или наличие мешковидной выпячивающейся аневризмы диаметром 5-10 мм.
3. Артериовенозная мальформации (АВМ):
В зоне кровоизлияния может быть выявлен участок аномальной сосудистой сети.
АВМ представлена переплетением артерий и вен без нормального капиллярного звена, что придает ей хаотичный вид.
Размер АВМ варьирует от нескольких миллиметров до нескольких сантиметров.
Наиболее частая локализация: кора головного мозга, субкортикальные структуры или области глубоких ядер (например, таламус).
Аномальные сосуды имеют разную толщину стенки: артериальные элементы истончены и могут быть значительно расширены, вены — расширены и извитые.
На разрезе сосуды часто наполнены жидкой кровью или сгустками.
Возможны мелкие аневризматические расширения на артериях, питающих АВМ.
Зона вокруг АВМ может быть пропитана кровью в случае разрыва, часто выявляются признаки ишемии и глиоза в прилежащих тканях.
Мозговая ткань может быть компримирована из-за отека или объемного эффекта мальформации.
Если мальформация стала причиной инсульта, кровоизлияние может быть как паренхиматозным (в ткани мозга), так и субарахноидальным.
Кровь окружает АВМ или заполняет прилегающие борозды.
Часто обнаруживаются множественные склерозированные сосуды, свидетельствующие о хронической перегрузке сосудистой сети.
При длительном существовании мальформации в ткани мозга можно увидеть кисты, заполненные кровью или некротическими массами.
4. Атеросклеротические изменения:
Выражены в виде утолщения стенки сосуда, наличия атеросклеротических бляшек, иногда с изъязвлением или кальцинозом.
5. Амилоидоз сосудов:
Стенка сосудов плотная, стекловидная, бледная, с ломкостью при пальпации, характерно для церебральной амилоидной ангиопатии.
6. Изменение просвета сосудов: - стеноз (просвет артерии может быть резко сужен, что увеличивает риск разрыва стенки при повышении артериального давления).
- тромбоз или эмболия (в некоторых случаях обнаруживаются тромбы в крупных сосудах (например, базилярная артерия), что могло спровоцировать повышение давления и разрыв более 
мелких сосудов).
7. Общие признаки сосудистой патологии:
Сосуды на вскрытии выглядят извитыми, с утолщенной стенкой, местами кальцинированными или хрупкими.
На фоне гипертонической болезни мелкие артериолы (артериолосклероз) могут быть обнаружены с фибриноидным некрозом и разрывами.
Пример описания геморрагического инсульта с патологией в сосудах головного мозга:
На вскрытии обнаружено массивное субарахноидальное кровоизлияние, преимущественно в области сильвиевой борозды правого полушария. В месте кровоизлияния выявлена мешковидная 
аневризма средней мозговой артерии диаметром 8 мм с разрывом в области её купола. Стенки сосуда истончены, бледно-желтые, с признаками атеросклероза. В соседних артериях 
отмечены многочисленные мелкие атеросклеротические бляшки, стенозирующие просвет до 30-40%.
        </p> 
        <p id="MsoBodyTextIndent" className="green"> Эволюция очага геморрагического инсульта при внутримозговой локализации проходит несколько стадий, каждая из которых 
          сопровождается характерными макроскопическими изменениями:
1. Острая стадия (первые часы–дни)
-	Кровоизлияние: на разрезе мозга виден участок пропитанной кровью ткани. Он может быть представлен сгустком крови (гематомой) или диффузным пропитыванием паренхимы. Цвет: от 
ярко-красного до темно-красного в зависимости от времени с момента кровоизлияния.
-	Отек мозга: выраженный отек вокруг очага кровоизлияния. Извилины сглажены, борозды сужены, наблюдается эффект масс-эффекта (смещение мозговых структур, вклинение в стволовые 
отделы).
-	Границы очага: очаг плохо отграничен от окружающей ткани, ткани мозга разрушены, пропитаны кровью.
2. Подострая стадия (4–15 дней)
-	Резорбция крови: начинается распад гемоглобина в кровоизлиянии, что приводит к изменению цвета очага с красного на бурый, а затем желтоватый. Появляются участки, где кровь 
рассасывается, и формируются полости.
-	Воспалительная реакция: вокруг очага видны зоны воспаления и демаркации. Ткани окружают макрофаги, активно захватывающие продукты распада крови.
-	Размягчение ткани: ткань мозга в зоне поражения становится более мягкой, могут образоваться небольшие кисты.
3. Ранняя стадия восстановления (2–4 недели)
-	Образование кисты: очаг кровоизлияния частично замещается кистозной полостью, заполненной прозрачной или желтоватой жидкостью.
-	Глиоз: на месте разрушенной ткани формируется плотная глиальная ткань.
-	Цветовые изменения: остатки крови (гемосидерин) окрашивают ткани в буро-желтый цвет, особенно в зоне вокруг кисты.
4. Поздняя стадия восстановления (месяцы–годы)
-	Зрелая киста: на месте кровоизлияния формируется окончательная киста, окруженная плотной глиозной капсулой. Полость кисты обычно гладкая, заполнена жидкостью.
-	Атрофия мозга: вокруг очага развивается атрофия соседних участков мозга. извилины в зоне поражения становятся более узкими, борозды — расширенными.
-	Пигментные отложения: остатки гемосидерина могут сохраняться в тканях вокруг кисты, придавая им бурый или золотистый оттенок.
</p>
        </>               
    )
}
export default GemInsultGm