function InfarctMiocSerdceAorta(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для инфаркт миокарда, характерно: 
Увеличение размеров сердца (кардиомегалия): при наличии сопутствующей гипертрофии миокарда, особенно в случаях длительной ишемической болезни сердца.
Расширение полостей: может наблюдаться при хронической сердечной недостаточности.
Выраженный атеросклероз коронарных артерий с наличием тромбоза в месте разрыва атеросклеротической бляшки.
Истончение стенки в зоне инфаркта: характерно для хронического течения.
Возможны: разрыв миокарда (в первые дни инфаркта из-за некроза и лизиса тканей (особенно в случае трансмурального инфаркта)); возникновение аневризмы сердца (острая аневризма 
(выпячивание истонченной и некротизированной стенки сердца) или хроническая аневризма (образование из соединительной ткани с уплотнением стенки)); образование пристеночных тромбов 
на фоне нарушенного кровотока и повреждения эндокарда.
Макроскопические изменения в сердце при инфаркте миокарда различной давности (по периодам):
1. Ранний период (до 6 часов):
Макроскопические изменения минимальны, часто не выявляются без специальной обработки. Сердце внешне выглядит практически неизмененным. Возможна умеренная тусклость поверхности 
миокарда в области ишемии. Дополнительные методы: реакция с хлоридом тетразолия (ТТХ) выявляет зоны некроза: нормальная ткань окрашивается в красно-коричневый цвет, а некротическая 
остается бледной.
2. Острая стадия (6 часов – 7 суток):
6–12 часов:
-	Миокард в зоне поражения приобретает тусклый вид, становится серовато-желтоватым.
-	Появляется легкая отечность.
-	Заметна слабая граница между пораженной и здоровой тканью.
12–24 часа:
-	Зона некроза становится более четкой, приобретает серовато-желтый оттенок.
-	Возможны точечные кровоизлияния по периферии.
-	Сосуды переполнены кровью.
1–3 суток:
-	Некротическая ткань становится более серой или серо-желтой.
-	Появляется гиперемия (красная кайма) по периферии очага, связанная с воспалительной реакцией.
3–7 суток:
-	Зона некроза приобретает желтовато-белый цвет.
-	Границы очага становятся четкими.
-	Сердечная стенка в области некроза может стать утонченной.
-	На перикарде возможны наложения фибрина, если некроз близок к эпикарду.
3. Подострая стадия (7–28 суток):
-	Зона некроза становится белесоватой или серовато-белой.
-	Начинается замещение некротической ткани грануляционной.
-	Гиперемия по краям очага уменьшается.
-	Сердечная стенка в зоне инфаркта утончена, возможна аневризма (при массивном поражении).
4. Стадия рубцевания (от 1 месяца и далее):
1–2 месяца:
-	Зона инфаркта полностью замещается плотной соединительной тканью.
-	Очаг выглядит как белесоватый рубец, отчетливо выделяющийся на фоне розового миокарда.
5. Более поздние сроки:
-	Рубец становится более выраженным, белым, с атрофией окружающего миокарда.
-	Возможна деформация сердечной стенки (аневризма), истончение или даже разрыв рубцовой ткани.
Пример описания макроскопических изменений при вскрытии:
Острая стадия (2-е сутки):
-	В передней стенке левого желудочка в области верхушки обнаружен участок серо-желтого цвета размером 3×4 см, с гиперемированной каймой по краям.
-	Поверхность эпикарда над очагом тусклая, с наложениями фибрина.
Подострая стадия (14-е сутки):
-	В задней стенке левого желудочка определяется участок белесоватого цвета размером 4×5 см.
-	Границы очага четкие, периферия розоватая. Стенка сердца в этой области утончена.
Рубцовая стадия (2 месяца):
-	В передней стенке левого желудочка рубец размером 5×6 см, плотный, белесоватый, с истончением стенки до 0,5 см.
        </p>                
    )
}
export default InfarctMiocSerdceAorta