function IshInsultVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При жизни имелся хронический ишемический инсульт в бассейне правой средней мозговой артерии (киста постишемического генеза в 
правой височно-теменной области (размер 3×2×2 см), зона глиоза по периферии, остатки пенистых макрофагов), на что указывают макро- и микро- скопические изменения головного мозга 
и его сосудов. 
В данном случае ишемический инсульт не является непосредственной причиной смерти и не находится в прямой причинной связи с её наступлением. Однако его наличие свидетельствует о 
наличии хронических сосудистых изменений, которые могли способствовать ухудшению общего состояния организма и, в совокупности с основным патологическим процессом, повлиять на 
наступление смерти.
        Ишемический инсульт — это острое нарушение мозгового кровообращения, вызванное частичным или полным прекращением кровотока в артериях головного мозга, приводящее к 
        недостатку кислорода и глюкозы в тканях мозга, развитию ишемии, некроза нейронов и нарушению их функций. Основные причины ишемического инсульта включают:
-	Тромбоз: образование тромба в месте атеросклеротической бляшки в церебральных артериях.
-	Эмболия: блокировка мозговых сосудов эмболом, который может происходить из сердца или других сосудов.
-	Системная гипоперфузия: снижение общего кровоснабжения мозга из-за гипотонии или остановки сердца.
Клинически ишемический инсульт характеризуется:
-	Внезапной очаговой неврологической симптоматикой (например, паралич, афазия, потеря чувствительности).
-	Симптомами поражения определённой области мозга, в зависимости от локализации и объема ишемии.
        </p>
    )
}
export default IshInsultVyvody