function SepsisPochki(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При сепсисе в почках возможна картина гнойничкового (септического метастатического гнойного) нефрита:
почки увеличены в объеме, часто отечные. Капсула туго снимается, под ней видна измененная ткань (выявляются множественные мелкие возвышающиеся очаги желтоватого цвета (абсцессы), окруженные красной каймой гиперемии).
Поверхность органа может быть пестрой: серо-желтые участки (гнойники) чередуются с красноватыми (гиперемия, кровоизлияния).
Области воспаления и некроза могут быть желтого цвета на фоне серо-коричневой паренхимы.
В корковом слое на разрезе видны мелкие множественные гнойники, которые выглядят как желтоватые точки диаметром 1–3 мм, окруженные зоной гиперемии.
Между гнойниками встречаются мелкие точечные и полосовидные кровоизлияния. Возможен отек межуточной ткани.
В мозговом слое гнойнички могут распространяться на пирамиды, но реже, чем в корковом слое.
Видны расширенные сосуды с зонами гиперемии.
В полости лоханки может содержаться мутная, гнойная жидкость, особенно при восходящем присоединении инфекции.
Слизистая оболочка утолщена, красного цвета.
Мочеточники могут быть отечным, с участками гиперемии или воспаления, если инфекция распространилась нисходящим путем.
В артериолах и капиллярах почки могут наблюдаться микротромбы, что связано с системной инфекцией и ДВС-синдромом.
Встречаются гнойничковые очаги вокруг сосудов, что указывает на гематогенное происхождение инфекции.
Гнойничковый нефрит может осложняться пери- и пара- нефритом.
При септическом эндокардите в почках возможно развитие иммунокомплексного диффузного гломерулонефрита:
почки увеличены, влажные, с пестрой поверхностью. Характерными признаками являются утолщение коркового слоя, выраженная гиперемия, отек, точечные кровоизлияния и изменение клубочков (выраженная гиперемия вокруг 
клубочков и в межуточной ткани; помутнение клубочков: они увеличены, выглядят как сероватые или красноватые точки). 
Эти изменения отражают иммунокомплексное повреждение с развитием васкулита и воспаления, что характерно для вторичного гломерулонефрита при септическом процессе.
        </p>                
    )
}
export default SepsisPochki