function PostgemAnemiSerdce(){
    return (
        <p id="MsoBodyTextIndent" className="green">При острой постгеморрагической анемии возможны следующие изменения в сердце: 
        Острая дилатация сердца, особенно правых отделов. Это обусловлено компенсацией недостатка крови увеличением сердечного выброса. Камеры сердца могут быть пустыми или 
        содержать минимальное количество жидкой крови (гиповолемия). Миокард может выглядеть бледным, дряблым, с явлениями жировой дистрофии. Полосовидные кровоизлияния, 
        возникающие под эндокардом левого желудочка сердца при острой кровопотере (пятна Минакова) - характерны для тяжёлых случаев острой постгеморрагической анемии.
        Кровеносные сосуды на вскрытии могут быть коллабированы из-за снижения объема циркулирующей крови. Кровь в сосудах — жидкая или отсутствует, с признаками гипокоагуляции.
        </p>
    )
}
export default PostgemAnemiSerdce