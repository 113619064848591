function AlcoPochki(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При хроническом алкоголизме возможно развитие алкогольной нефропатии:
        На начальных этапах почки могут быть увеличены из-за отека паренхимы с гладкой поверхностью, в дальнейшем происходит уменьшение размеров почек (сморщивание) из-за склеротических изменений, их поверхность 
        становится мелкозернистой или мелкоузловатой из-за склероза клубочков и интерстициальной ткани, капсула с трудом отделяется от паренхимы, что свидетельствует о фиброзных изменениях. Происходит 
        истончение коркового слоя почки. На разрезе паренхима почки приобретает пестрый вид: желтоватые очаги (жировая дистрофия), темные пятна (застой крови, кровоизлияния). Граница между корковым и 
        мозговым слоями сглажена.
        </p>                
    )
}
export default AlcoPochki