function OglomeruloVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">При жизни имелся острый гломерулонефрит (дифузный экссудативно-пролиферативный гломерулонефрит, признаки отека и полнокровия 
        коркового вещества, дистрофия и некроз эпителия канальцев, отложения фибрина в капсуле Боумена), на что указывают макро- и микро- скопические изменения в почках;
        результаты гистохимического и иммуногистохимического исследований: - обнаружение фибрина в капсуле Боумена (окраска фибрином), - наличие отложений иммуноглобулинов 
        (IgG, IgA, IgM) и комплемента (C3) на базальной мембране клубочков; а так же лабораторные исследования мочи и крови: - моча (протеинурия (белок), гематурия (эритроциты), 
        цилиндрурия (гранулярные, гиалиновые цилиндры), - кровь (повышение уровней креатинина и мочевины, гипоальбуминемия, повышение С-реактивного белка).
        Острый гломерулонефрит — это иммуновоспалительное заболевание почек, характеризующееся преимущественным поражением клубочков (гломерул), а также вовлечением канальцев и 
        интерстициальной ткани. Основой патогенеза является образование иммунных комплексов и их отложение в почечных клубочках, что приводит к активации воспалительного процесса, 
        нарушению почечной фильтрации и развитию клинических проявлений. Клинические признаки:
-	Отеки: чаще всего на лице, особенно по утрам.
-	Артериальная гипертензия.
-	Гематурия: моча приобретает вид «мясных помоев».
-	Олигурия: снижение суточного объема мочи.
-	Протеинурия: наличие белка в моче.
Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default OglomeruloVyvody