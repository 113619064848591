function IshInsultLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При ишемическом инсульте при проведении <u>гистологического исследования</u> можно обнаружить следующие изменения:
        1.	Головной мозг:
        Микроскопические (гистологические) изменения отражают стадийность патологического процесса, начиная с повреждения нейронов и заканчивая развитием глиоза. Эти изменения 
        зависят от времени, прошедшего с момента инсульта:
1.1. Острая стадия (первые 6–24 часа)
-	Ишемическое повреждение нейронов:
Нейроны в зоне ишемии набухают (цитотоксический отек), теряют ядра или ядрышки (карикоцитолиз).
Характерно появление "красных" нейронов (эозинофильное окрашивание цитоплазмы из-за денатурации белков).
Нарушение структуры дендритов и аксонов, фрагментация нейропиля.
-	Глиальные клетки:
Набухание астроцитов и их отростков.
Вакуолизация олигодендроцитов.
-	Сосудистые изменения:
Стаз крови в капиллярах, набухание эндотелиальных клеток.
Периваскулярный отек (скопление жидкости вокруг сосудов).
1.2. Подострая стадия (2–14 дней)
-	Некроз нейронов и тканей:
Прогрессирует гибель нейронов, что приводит к появлению обширных зон лизиса ткани мозга.
Появляются клетки теней — остатки некротизированных нейронов с утратой ядра.
-	Реактивный глиоз:
Гипертрофия астроцитов, которые начинают заполнять область повреждения своими отростками.
Астроциты содержат многочисленные глиальные волокна (глиофибриллярный белок).
-	Воспалительная реакция:
Миграция макрофагов и микроглии в зону некроза для удаления остатков тканей (фагоцитоз).
Макрофаги содержат липиды (пенистые клетки) и гемосидерин в случае геморрагической трансформации.
1.3. Стадия восстановления (3 недели – месяцы)
-	Резорбция некротических масс:
Продукты распада клеток полностью поглощаются макрофагами.
Появляются зоны разрежения (кистозные полости).
-	Глиоз:
Астроцитарный глиоз доминирует; образуется глиальная ткань, которая замещает утраченные нейроны.
Формируются глиальные рубцы на границе зоны повреждения.
-	Сосудистые изменения:
Неоваскуляризация (образование новых капилляров) в зоне поражения.
Фиброз сосудистых стенок.
Пример описания гистологической картины:
В зоне ишемии наблюдается гибель нейронов (клетки теней), выраженная вакуолизация ткани, фрагментация нейропиля и набухание астроцитов. Видны многочисленные макрофаги с остатками 
липидов, а также разрастание глиальных волокон. Сосуды демонстрируют признаки стаза, эндотелиальную пролиферацию и периваскулярный отек.
Эти изменения отражают реакцию мозга на гипоксию и развивающийся некроз, а также компенсаторные процессы, направленные на восстановление поврежденной ткани.
        2.	Сосуды мозга:
        При ишемическом инсульте гистологическое исследование структуры сосудов может выявить целый ряд изменений, связанных с повреждением сосудистой стенки, формированием 
        тромбов и эмболов, а также признаками хронических сосудистых заболеваний. Вот как это проявляется:
2.1. Структура сосудистой стенки
Утолщение интимы за счёт пролиферации гладкомышечных клеток и отложений соединительной ткани.
Разрушение внутренней эластической мембраны, особенно при выраженном атеросклерозе.
Утрата нормального строения сосудистой стенки при фибриноидном некрозе (деструкция клеточных элементов и пропитывание стенки плазматическими белками, включая фибрин).
Гиалиноз стенок артериол, характерный для артериальной гипертензии, приводит к их утолщению и снижению эластичности.
2.2. Наличие тромбов
Часто выявляются тромбы в просвете сосудов, перекрывающие кровоток.
Слоистая структура тромба: чередующиеся слои тромбоцитов и фибрина (при наличии красных клеток — "красный тромб").
Признаки организации тромба: врастание соединительной ткани и капилляров при длительном процессе.
Тромбы могут быть выявлены как в магистральных сосудах (например, сонной артерии), так и в церебральных артериях, питающих поражённый участок мозга.
2.3. Эмболы
Могут быть обнаружены эмболы (например, тромбоэмболы, жировые эмболы, а также эмболы атероматозного материала) в просвете сосудов мозга.
Тромбоэмболы: состоят из фибрина и клеточных элементов.
Жировые эмболы: видны как крупные капли жира, окрашенные специальными липофильными красителями (судан III).
Атероматозные эмболы: фрагменты атероматозных масс, содержащие кристаллы холестерина.
2.4. Фиброз
В хронических стадиях ишемического инсульта наблюдается фиброз сосудистой стенки в месте поражения.
Разрастание соединительной ткани в интиме и медии.
Уменьшение просвета сосуда за счёт фибротических изменений.
В окружающих тканях — глиоз (разрастание астроцитов в ответ на повреждение).
2.5. Атеросклеротические изменения
Атеросклероз сосудов характерен для крупных и средних артерий, может быть основной причиной ишемии.
Атероматозные бляшки, содержащие кристаллы холестерина, пенистые клетки (макрофаги, заполненные липидами).
Кальцификация бляшек и изъязвления их поверхности.
Закупорка просвета сосуда бляшкой, тромбом или их сочетанием.
2.6. Воспаление (васкулит)
Может быть редкой причиной ишемического инсульта, связанной с аутоиммунными, инфекционными или токсическими процессами.
Инфильтрация стенки сосуда лимфоцитами, макрофагами, плазматическими клетками.
Некротические изменения стенки сосуда.
Утолщение интимы за счёт воспалительных клеток и фиброза, сужение или окклюзия просвета.
        3.	Изменения в других органах:
        - Сердце: признаки гипертрофии миокарда, часто наблюдаемые при длительно существующей артериальной гипертензии. Гистологически это проявляется увеличением размеров мышечных волокон и их ядра.
        - Почки: признаки гипертонической ангиопатии с утолщением и склерозом стенок артериол, что может свидетельствовать о длительном повышенном артериальном давлении.
        - Легкие: застой крови в капиллярах с возможными кровоизлияниями в альвеолы, особенно в условиях острой сердечной недостаточности. <u>Биохимические исследования (по возможности):</u> 
        - Исследование уровня глюкозы и электролитов в крови или тканях мозга для выявления метаболических нарушений, сопутствующих инсульту.
- Определение маркеров гипоксии (например, накопление молочной кислоты). <u>Радиологическое исследование (если проводилось прижизненно):</u> 
        Оценка данных КТ или МРТ (при наличии). <u>Молекулярные исследования:</u> анализ мутаций в генах, связанных с тромбозами или нарушениями сосудистой стенки (если предполагается генетическая предрасположенность).
        </p>                
    )
}
export default IshInsultLab