function GreyvsVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При жизни имелась болезнь Грейвса (диффузный токсический зоб) (гиперплазия и гипертрофия фолликулярного эпителия щитовидной 
        железы, лимфоцитарная инфильтрация стромы, экзофтальм (умеренный)), на что указывают макро- и микро- скопические изменения органов (щитовидной железы и глаз), 
        низкий уровень ТТГ (тиреотропного гормона) и повышенный уровень гормонов щитовидной железы (Т4 свободного и Т3) при биохимическом исследовании крови, увеличенный уровень 
        антител к рецептору тиреотропного гормона (AT-rTTG) при серологическом исследовании крови. Болезнь Грейвса (диффузный токсический зоб) — это аутоиммунное заболевание, 
        характеризующееся гиперфункцией щитовидной железы, диффузным её увеличением и системными проявлениями гипертиреоза, такими как тахикардия, потеря веса, тремор и экзофтальм.
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.</p>
    )
}
export default GreyvsVyvody