import { useSelector } from 'react-redux';

//Импорт компонентов пат изменений:
import GbLab from '../../patan/gb/GbLab';
import SaharDiab1Lab from '../../patan/saharDiab1/saharDiab1Lab';
import SaharDiab2Lab from '../../patan/saharDiab2/saharDiab2Lab';
import VichLab from '../../patan/vich/VichLab';
import CovidLab from '../../patan/covid/CovidLab';
import GepatBLab from '../../patan/gepatB/GepatBLab';
import GepatСLab from '../../patan/gepatС/GepatСLab';
import SifilisLab from '../../patan/sifilis/SifilisLab';
import GrippLab from '../../patan/gripp/GrippLab';
import TubercLab from '../../patan/tuberc/TubercLab';
import KronaLab from '../../patan/krona/KronaLab';
import NjakLab from '../../patan/njak/NjakLab';
import GreyvsLab from '../../patan/greyvs/GreyvsLab';
import HoshimotoLab from '../../patan/hoshimoto/HoshimotoLab';
import GemInsultLab from '../../patan/gemInsult/GemInsultLab';
import OglomeruloLab from '../../patan/oglomerulo/OglomeruloLab';
import FeAnemiLab from '../../patan/feAnemi/FeAnemiLab';
import PostgemAnemiLab from '../../patan/postgemAnemi/PostgemAnemiLab';
import HronPieloLab from '../../patan/hronPielo/HronPieloLab';
import IshInsultLab from '../../patan/ishInsult/IshInsultLab';
import InfarctMiocLab from '../../patan/infarctMioc/InfarctMiocLab';
import AlcoLab from '../../patan/alco/AlcoLab';
import DilCardioLab from '../../patan/dilCardio/DilCardioLab';
import GipCardioLab from '../../patan/gipCardio/GipCardioLab';
import MiocarditLab from '../../patan/miocardit/MiocarditLab';
import SepsisLab from '../../patan/sepsis/SepsisLab';
import HibsLab from '../../patan/hibs/HibsLab';
import MkbLab from '../../patan/mkb/MkbLab';


function PatanLab() {

    //Получаем из хранилища состояния пат изменений:
    const gb = useSelector((state) => state.pat.gb);
    const saharDiab1 = useSelector((state) => state.pat.saharDiab1);
    const saharDiab2 = useSelector((state) => state.pat.saharDiab2);
    const vich = useSelector((state) => state.pat.vich);
    const covid = useSelector((state) => state.pat.covid);
    const gepatB = useSelector((state) => state.pat.gepatB);
    const gepatC = useSelector((state) => state.pat.gepatC);
    const sifilis = useSelector((state) => state.pat.sifilis);
    const gripp = useSelector((state) => state.pat.gripp);
    const tuberc = useSelector((state) => state.pat.tuberc);
    const krona = useSelector((state) => state.pat.krona);
    const njak = useSelector((state) => state.pat.njak);
    const greyvs = useSelector((state) => state.pat.greyvs);
    const hoshimoto = useSelector((state) => state.pat.hoshimoto);
    const gemInsult = useSelector((state) => state.pat.gemInsult);
    const oglomerulo = useSelector((state) => state.pat.oglomerulo);
    const feAnemi = useSelector((state) => state.pat.feAnemi);
    const postgemAnemi = useSelector((state) => state.pat.postgemAnemi);
    const hronPielo = useSelector((state) => state.pat.hronPielo);
    const ishInsult = useSelector((state) => state.pat.ishInsult);
    const infarctMioc = useSelector((state) => state.pat.infarctMioc);
    const alco = useSelector((state) => state.pat.alco);
    const dilCardio = useSelector((state) => state.pat.dilCardio);
    const gipCardio = useSelector((state) => state.pat.gipCardio);
    const miocardit = useSelector((state) => state.pat.miocardit);
    const sepsis = useSelector((state) => state.pat.sepsis);
    const hibs = useSelector((state) => state.pat.hibs);
    const mkb = useSelector((state) => state.pat.mkb);
    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);
    
    return (
        <>
            {/* Туберкулез: */}
            {tuberc && <><TubercLab/>
            <br></br></>}
            {/* Сифилис: */}
            {sifilis && <><SifilisLab/>
            <br></br></>}
            {/* Гепатит B: */}
            {gepatB && <><GepatBLab/>
            <br></br></>}
            {/* Гепатит C: */}
            {gepatC && <><GepatСLab/>
            <br></br></>}
            {/* ВИЧ: */}
            {vich && <><VichLab/>
            <br></br></>}
            {/* Грипп: */}
            {gripp && <><GrippLab/>
            <br></br></>}
            {/* Covid-19: */}
            {covid && <><CovidLab/>
            <br></br></>}
            {/* Железодеф анемия: */}
            {feAnemi && <><FeAnemiLab/>
            <br></br></>} 
            {/* Острая постгеморрагическая анемия: */}
            {postgemAnemi && <><PostgemAnemiLab/>
            <br></br></>} 
            {/* болезнь Грейвса: */}
            {greyvs && <><GreyvsLab/>
            <br></br></>}
            {/* Хашимото: */}
            {hoshimoto && <><HoshimotoLab/>
            <br></br></>}
            {/* СД 1 типа: */}
            {saharDiab1 && <><SaharDiab1Lab/>
            <br></br></>}
            {/* СД 2 типа: */}
            {saharDiab2 && <><SaharDiab2Lab/>
            <br></br></>}
             {/* Хронический алкоголизм: */}
             {alco && <><AlcoLab/>
            <br></br></>}
            {/* ГБ: */}
            {gb && <><GbLab/>
            <br></br></>}
            {/* Инфаркт миокарда: */}
            {infarctMioc && <><InfarctMiocLab/>
            <br></br></>}
            {/* ХИБС: */}
            {hibs && <><HibsLab/>
            <br></br></>}
             {/* Сепсис: */}
             {sepsis && <><SepsisLab/>
            <br></br></>}
            {/* Миокардит: */}
            {miocardit && <><MiocarditLab/>
            <br></br></>}
            {/* Дилатационная кардиомиопатия: */}
            {dilCardio && <><DilCardioLab/>
            <br></br></>}
            {/* Гипертрофическая кардиомиопатия: */}
            {gipCardio && <><GipCardioLab/>
            <br></br></>}
            {/* Геморрагический инсульт: */}
            {gemInsult && <><GemInsultLab/>
            <br></br></>}
            {/* Ишемический инсульт: */}
            {ishInsult && <><IshInsultLab/>
            <br></br></>}
            {/* болезнь Крона: */}
            {krona && <><KronaLab/>
            <br></br></>}
            {/* НЯК: */}
            {njak && <><NjakLab/>
            <br></br></>}
            {/* О. гломерулонефрит: */}
            {oglomerulo && <><OglomeruloLab/>
            <br></br></>}
            {/* Хронический пиелонефрит: */}     
            {hronPielo && <><HronPieloLab/>
            <br></br></>} 
            {/* МКБ: */}     
            {mkb && <><MkbLab/>
            <br></br></>}  
            {/* Ссадины: */}
            {/* {ssadiny  && <><SsadinyDiagnoz/>
            <br></br></>} */}
        </>
    );
}

export default PatanLab;