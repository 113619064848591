function SepsisLungs(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При сепсисе в легких возможны следующие изменения:
они увеличены в объеме, тяжелые и плотные, без характерной воздушности при пальпации (выраженная отечность). Участки уплотнений чередуются с более мягкими и разрыхленными 
участками, что указывает на фокальные изменения. Поверхность легких матовая, часто с участками, напоминающими консистенцию печени ("гепатизация").
Плевра часто покрыта тонким слоем фибринозного экссудата (фибринозный плеврит). Возможны геморрагические полосы и участки кровоизлияний на поверхности.
Возможный цвет легких при сепсисе:
- серо-красный или бурый (из-за застоя крови, отека и мелких кровоизлияний).
- темно-красный или синий (при застое крови в легочных капиллярах и венах).
- мраморный (связано с чередованием участков гиперемии, ишемии и геморрагий).
При разрезе из легочной ткани выделяется обильная пенистая жидкость, часто с примесью крови (серозно-геморрагический или гнойный характер).
При септикопиемии видны мелкие гнойные очаги (септические микроабсцессы) и множественные мелкие кровоизлияния.
Возможен отек межуточной ткани и альвеол.
        </p>
    )
}
export default SepsisLungs