function HoshimotoSerdceAorta(){
    return (
        <p id="MsoBodyTextIndent" className="green">При аутоиммунном тиреоидите с гипотиреозом возможно увеличение массы сердца (микседематозное сердце): оно может быть увеличено 
в размерах из-за дилатации камер (это связано с нарушением нормальной работы миокарда и перегрузкой сердца). На внешнем осмотре можно наблюдать утолщение стенок, особенно левого 
желудочка. Миокард может быть мягким, бледным и несколько рыхлым. Внутри сердечной мышцы часто встречаются жировые отложения, особенно в области субэндокардиальной и 
интерстициальной ткани. В некоторых случаях миокард может иметь желтоватый оттенок, что является признаком жировой инфильтрации.
Миокард может быть отечным и плотным. Это отек из-за накопления жидкости в межуточной ткани, что является характерным для гипотиреоза. В более поздних стадиях гипотиреоза можно 
наблюдать очаговый фиброз (замещение нормальной ткани соединительной тканью), что также влияет на работу сердца. В этих областях миокарда может быть снижено количество нормальных 
кардиомиоцитов. Возможны признаки серозного перикардита или фиброзные изменения в перикарде, которые могут возникать из-за длительного нарушенного обмена веществ при гипотиреозе.
Иногда при гипотиреозе могут быть признаки атеросклероза в коронарных артериях, что также связано с метаболическими нарушениями (например, повышенным уровнем липидов в крови).
        </p>                
    )
}
export default HoshimotoSerdceAorta