function FeAnemiShit(){
    return (
        <p id="MsoBodyTextIndent" className="green">
        При железодефицитной анемии можно обнаружить признаки аутоиммунного тиреоидита, который опосредственно может являться причиной дефицита железа:
        Щитовидная железа часто увеличена, имеет диффузное или очаговое утолщение. Это состояние известно как зоб. Увеличение может 
        быть симметричным или асимметричным. Щитовидная железа становится плотной, уплотнённой, реже может быть неоднородной на ощупь. Цвет ткани может быть серовато-розовым или 
        желтовато-серым с участками фиброза. На разрезе могут наблюдаться мелкие узловатые изменения, участки рубцевания или очаги дегенерации. Заметны участки выраженного фиброза 
       (светлые, белесоватые или сероватые участки с более плотной и жесткой на ощупь поверхностью, которые отличаются от остальной ткани железы по цвету), особенно в запущенных 
       случаях заболевания. В случае длительно текущего заболевания возможно уменьшение размеров железы (атрофия) вследствие прогрессирующего разрушения тиреоидной ткани. 
        </p>
    )
}
export default FeAnemiShit