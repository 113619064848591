function SepsisGm(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При сепсисе возможен гематогенный занос инфекции с формированием мелких гнойных очагов в ткани мозга, в таком случае:
головной мозг может быть увеличен в объеме из-за выраженного отека.
Мозговые оболочки гиперемированы, отечны, могут быть мутными. На поверхности оболочек видны мелкие точечные кровоизлияния.
Венозные сосуды полнокровны, часто расширены; в мелких сосудах возможны тромбозы.
На поверхности мозга можно заметить пеструю окраску, связанная с чередованием участков гиперемии и гнойничков.
На разрезе ткани мозга видны множественные мелкие очаги (до 1–2 мм в диаметре), желтовато-белого цвета.
Очаги окружены зоной гиперемии и отека (красноватый ободок).
Микроабсцессы часто обнаруживаются в белом веществе полушарий, мозжечке и стволе мозга; чаще всего располагаются вокруг сосудов, что указывает на гематогенный занос инфекции.
Ткань мозга отечная, влажная, дряблая, на разрезе поверхность блестящая.
Борозды сглажены, извилины уплощены из-за внутричерепной гипертензии.
Возможны точечные и полосовидные кровоизлияния в окружающей ткани, указывающие на повреждение сосудов.
Спинномозговая жидкость мутная, с повышенным содержанием белка и клеточных элементов; в некоторых случаях может содержать примесь гноя.
        </p>                
    )
}
export default SepsisGm