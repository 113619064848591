function HibsVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При жизни имелась хроническая ишемическая болезнь сердца: кардиосклероз (диффузный и очаговый), аневризма передней стенки и 
        верхушки левого желудочка (размером до 4 см), пристеночный тромб в полости аневризмы, атеросклероз коронарных артерий (сужение просвета до 85%),
        что подтверждается макро- и микро- скопическими изменениями сердца. 
        Хроническая ишемическая болезнь сердца (кардиосклероз и аневризма сердца) — это форма хронической патологии сердечно-сосудистой системы, обусловленная длительным 
        нарушением коронарного кровообращения, которое приводит к прогрессирующему поражению миокарда, проявляющемуся развитием диффузного и/или очагового кардиосклероза 
        (замещение функциональной сердечной мышечной ткани соединительной), а также формированием аневризмы сердца, характеризующейся локальным истончением и выпячиванием стенки 
        сердца.
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.</p>
    )
}
export default HibsVyvody