function HronPieloPochki(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При хроническом пиелонефрите почки могут быть уменьшены в размерах (сморщенные почки) из-за атрофии паренхимы и замещения её 
        соединительной тканью. В некоторых случаях возможна асимметрия размеров почек: одна почка больше другой. Поверхность почки неровная, крупнобугристая (пиелонефритический 
        нефросклероз). Это связано с рубцеванием ткани в результате хронического воспалительного процесса. Капсула почки утолщена и трудно отделяется от поверхности органа, при её снятии могут оставаться участки, прочно 
        сросшиеся с паренхимой. Корковый слой истончен, а мозговой слой может быть деформирован. В области пирамид можно обнаружить участки рубцовой ткани.	Лоханки и чашечки 
        расширены и деформированы, что связано с нарушением оттока мочи (гидронефротическая трансформация), часто в лоханке встречаются камни. Стенки лоханок и чашечек могут быть 
        утолщены и фиброзно изменены. На разрезе можно обнаружить утолщенные стенки сосудов из-за гипертензии, часто сопутствующей хроническому пиелонефриту. Паренхима почек может 
        быть неоднородной: чередование светлых и тёмных участков (рубцы и участки сохранённой ткани). Эти изменения являются следствием длительного воспалительного процесса, 
        некроза канальцев, рубцевания и нарушения функции органа.
        </p>                
    )
}
export default HronPieloPochki