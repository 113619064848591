function FeAnemiVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелась железодефицитная анемия, хроническая, тяжелая степень (гипохромная микроцитарная), на что указывают макро- и микро- скопические 
        изменения во внутренних органах (костный мозг, сердце, печень, селезенка); отсутствие гранул железа в клетках костного мозга (негативная реакция Перлса), истощение депо 
        железа в макрофагах печени и селезенки; 
        биохимический анализ крови: сывороточное железо: сильно снижено (менее 10 мкмоль/л), ферритин: снижено (менее 15 мкг/л), увеличение общей железосвязывающей способности сыворотки (ОЖСС): более 70 мкмоль/л; 
        прижизненный общий анализ крови: уровень гемоглобина: менее 70 г/л (тяжелая степень), эритроциты: снижены (менее 3,0 × 10⁹/л), гипохромия: снижение цветового показателя 
        (менее 0,85), микроцитоз: уменьшение среднего объема эритроцитов (MCV менее 80 фл).
        Железодефицитная анемия — это клинико-гематологический синдром, характеризующийся снижением уровня гемоглобина в крови и уменьшением количества эритроцитов вследствие 
        дефицита железа, необходимого для синтеза гемоглобина. Проявляется слабостью, бледностью кожи и слизистых, ломкостью ногтей, выпадением волос и нарушением функций 
        внутренних органов из-за хронической гипоксии тканей. Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default FeAnemiVyvody