function InfarctMiocVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При жизни имелся острый трансмуральный инфаркт миокарда передней стенки левого желудочка (давность 5–7 суток), атеросклероз 
        коронарных артерий (стеноз до 90%), очаговый кардиосклероз, гипертрофия миокарда левого желудочка (масса сердца 520 г, толщина стенки 18 мм), на что указывают макро- и 
        микро- скопические изменения сердца. Инфаркт миокарда — это острое состояние, характеризующееся развитием очагового некроза сердечной мышцы, вызванного недостаточным 
        кровоснабжением (ишемией) вследствие окклюзии коронарной артерии тромбом, спазмом, атеросклеротической бляшкой или их комбинацией.
Это состояние сопровождается необратимым повреждением кардиомиоцитов и нарушением их функциональной активности, что клинически проявляется болью в грудной клетке, признаками 
сердечной недостаточности или кардиогенного шока. Указанное заболевание в причинной связи с наступлением смерти - не состоит.</p>
    )
}
export default InfarctMiocVyvody