function GreyvsKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При болезни Грейвса (диффузный токсический зоб) возможны:
1. Экзофтальм: выпячивание глазных яблок, часто симметричное. Возможна отёчность век и гиперпигментация периорбитальной области.
Застывшее выражение лица из-за невозможности полного смыкания век. Лицо кажется напряжённым из-за экзофтальма ("маскообразное" лицо).
Из-за экзофтальма может быть лагофальм (незакрытие глаз), что приводит к высыханию роговицы и возможным язвам.
2. Увеличение шеи (зоб):
Щитовидная железа может быть увеличена, с видимым утолщением в передней части шеи.
3. Пигментация кожи: возможна гиперпигментация в области шеи, подмышек, локтевых сгибов.
Кожа на ладонях и стопах может быть гиперпигментированной.
4. Трофические изменения волос и ногтей: волосы тонкие, ломкие, склонные к выпадению (диффузная алопеция).
Ногти могут быть тонкими и ломкими, возможен симптом Пламмера (отслоение ногтя от ногтевого ложа).
5. Претибиальная микседема: уплотнения и отёки кожи в области передней поверхности голеней.
Кожа может быть плотной, с нарушенной пигментацией.
6. Отёки нижних конечностей при хронической сердечной недостаточности.
        </p>
    )
}
export default GreyvsKozha