function AlcoLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для хронического алкоголизма, характерно: при <u>гистологическом исследовании:</u>  1. Сердце:
        -  Отек стромы миокарда (за счет нарушений кровообращения);
        -	Очаговая жировая дистрофия кардиомиоцитов: накопление липидных капель (липофусцина) в цитоплазме клеток.
        - Жировое перерождение кардиомиоцитов (выявляется дополнительной окраской Нильским-Голубым);
        -	Диффузный или очаговый интерстициальный фиброз (кардиосклероз): разрастание соединительной ткани между кардиомиоцитами носит характер сетчатого, диффузного, часто с 
        выраженной пролиферацией клеток (подтверждается окраской препаратов по Ван-гизону).
        -	Гипертрофия кардиомиоцитов: увеличение размеров клеток с гиперхромными ядрами.
        - Разрастание жировой ткани в интерстиции.
        - Истончение, вытянутость, извитость, возможна слабо выраженная поперечная исчерченность кардиомиоцитов (может быть усилена или утрачена полностью, клетки могут погибать 
        и замещаться соединительной тканью - некроз отдельных кардиомиоцитов: участки распада клеток с воспалительной инфильтрацией); 
        - Склероз сосудов (отмечается периваскулярный склероз, при котором сосуды сливаются с соединительной тканью (такой склероз отмечается и при 
гипертонической болезни, но при последней обязательно должна быть выраженная гипертрофия миоцитов)). 
- Гиалиноз артериол. 
- В строме миокарда наличие мелкоочаговых лимфогистиоцитарных инфильтратов. 
- резкое переполнение вен и капилляров, с наличием диапедезных кровоизлияний.
- Отечность стенок сосудов, с наличием сладжей, тромбов. 
- При окраске ГОФП реактивом или при окраске по Рего выявляются метаболические повреждения миокарда ишемического характера, в виде неравномерного диффузного прокрашивания. 
- Гликоген выявляется Шик-реакцией лишь в виде единичных следов под сарколемой в первых пяти рядах кардиомиоцитов под эндокардом. 
        2. Печень:
        -	Жировая дистрофия (стеатоз): накопление жировых капель в гепатоцитах, особенно в центре долек ("центролобулярная дистрофия").
        -	Алкогольный гепатит: баллонная дистрофия гепатоцитов: клетки увеличены, с вакуолизированной цитоплазмой; наличие телец Маллори (гиперэозинофильные включения в 
        цитоплазме гепатоцитов); инфильтрация портальных трактов и междольковых перегородок нейтрофилами.
        -	Алкогольный цирроз: узлы регенерации, окруженные плотной фиброзной тканью; нарушение нормальной архитектуры печени.
        3. Желудок:
        Хронический алкогольный гастрит (атрофия слизистой оболочки, истончение желез; очаги воспалительной инфильтрации (лимфоциты, плазматические клетки); поверхностные 
        эрозии и микрокровоизлияния).
        4. Легкие:
        -	Застойная гиперемия: расширенные капилляры, наполненные эритроцитами; признаки отека (накопление жидкости в альвеолах).
        -	Очаги микрокровоизлияний: в межальвеолярных перегородках.
        5. Поджелудочная железа:
        Хронический панкреатит: атрофия ацинарной ткани; замещение ацинарных клеток соединительной тканью (фиброз); инфильтрация ткани лимфоцитами и макрофагами; деструкция 
        протоков с явлениями кистозной трансформации.
        6. Головной мозг:
        -	Атрофия коры: уменьшение количества нейронов, их ядра сморщены и гиперхромны; разрастание глиальной ткани (глиоз) в участках утраты нейронов.
        -	Алкогольная энцефалопатия: отек ткани мозга; мелкие кровоизлияния в белом веществе; дегенерация миелиновых оболочек нейронов.
        7. Почки:
        Алкогольная нефропатия: дистрофия эпителия канальцев (набухание клеток, вакуолизация цитоплазмы); фиброз межуточной ткани; cклероз и гиалинизация клубочков.
        8. Селезенка: Конгестивная спленомегалия: расширение синусов красной пульпы, застой крови; разрастание соединительной ткани в капсуле и трабекулах.
        Общая картина: микроскопические изменения свидетельствуют о системном воздействии алкоголя, проявляющемся дистрофическими, воспалительными и фиброзными процессами во 
        многих органах. Эти изменения коррелируют с макроскопической картиной и клиническими проявлениями хронического алкоголизма.
        При <u>токсикологическом исследовании:</u> наличие ацетальдегида, повышенного уровня изопропанола, этилглюкуронида (EtG) и других метаболитов алкоголя и жирных кислот 
        этилового эфира (FAEE) в органах, таких как печень, мозг и почки - могут служить маркерами хронического употребления алкоголя.
        При <u>Иммуногистохимическом исследовании:</u> 1. Повышение уровня белков теплового шока (таких как HSP70, HSP60, и HSP90) в органах и тканях (печени, сердца, мозга и других) 
        может указывать на хроническое воздействие алкоголя, поскольку алкоголь вызывает клеточный стресс и повреждения. Это является индикатором хронической интоксикации.
        2. Оксидативный стресс (исследование на маркеры перекисного окисления липидов):
Малоновый диальдегид (MDA): является конечным продуктом перекисного окисления липидов и одним из наиболее часто используемых маркеров оксидативного стресса.
Формальдегид и ацетальдегид: продукты метаболизма этанола, которые также могут вызывать повреждения клеток через окислительные процессы.
Продукты окисления фосфолипидов: таких как 4-гидрокси-2-ненал и другие соединения, которые увеличиваются при длительном употреблении алкоголя.
Концентрация продуктов окисления липидов (например, диеновых конъюгатов) в тканях также может быть повышена при хроническом алкоголизме, особенно в печени и других органах, 
подверженных воздействию алкоголя.
Дополнительные маркеры окислительного стресса:
Снижение антиоксидантной активности (например, уровня глутатиона) может также служить косвенным признаком хронического алкоголизма, поскольку алкоголь приводит к истощению 
запасов антиоксидантов.
Повышение уровня оксида азота (NO) и других активных молекул, которые играют роль в воспалении и повреждении клеток.
        </p>                
    )
}
export default AlcoLab