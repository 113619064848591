function HibsKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При хронической ишемической болезни сердца (ХИБС) возможны:
1. Признаки хронической сердечной недостаточности:
-	Отёки: отёчность нижних конечностей (чаще симметричная), иногда отёки на лице, шее или в области живота (асцит). Эти признаки связаны с нарушением кровообращения и задержкой жидкости в организме из-за недостаточной работы сердца.
-	Цианоз: синий или синюшный оттенок кожи, особенно на губах, ушных раковинах, кончиках пальцев (периферический цианоз), что указывает на хроническое недостаточное снабжение тканей кислородом.
-	Инфильтрация в области шеи (венозный застой): расширенные и набухшие шейные вены, что также может свидетельствовать о хроническом венозном застое из-за недостаточности правого желудочка.
2. Признаки кардиосклероза:
-	Преобладание признаков хронической гипоксии: при наличии гипертрофии миокарда и расширении сердца могут наблюдаться различные виды цианоза и синюшности в области лица и конечностей, а также утрата эластичности кожи и подкожной клетчатки из-за длительного нарушения кровоснабжения.
3. Признаки аневризмы сердца:
-	Необычные контуры грудной клетки: если аневризма была значительной, возможно заметное изменение контуров грудной клетки в области левого подреберья (если аневризма передней стенки сердца), что может свидетельствовать о её увеличении и локальном выпячивании стенки сердца. Это может быть видимо на поздних стадиях болезни.
4. Общее состояние:
-	Общее истощение: при хронической сердечной недостаточности и длительном течении ишемической болезни сердца пациент может выглядеть истощённым с выраженной слабостью и уменьшением массы тела.
5. Признаки гипертензии (если имеется):
-	Гиперемия лица: сильное покраснение кожи лица, иногда с высыпаниями, может быть связано с хроническим повышением артериального давления, которое часто сопровождает ишемическую болезнь сердца.
-	Характерные следы от давления манжеты (если ранее были проведены измерения давления): на коже рук могут оставаться следы от длительного применения манжет для измерения артериального давления, что также может быть признаком гипертонии на фоне ХИБС.
6. Наличие внешних признаков на коже:
-	Пигментация и истончение кожи: признаки хронической гипоксии и застоя крови могут проявляться в виде пигментации кожи, особенно на ногах, а также утратой упругости и эластичности кожи.
-	Трофические изменения: на коже ног могут наблюдаться язвы или трофические изменения, связанные с нарушением микроциркуляции, что типично для пациентов с длительной ишемической болезнью сердца.
-   Наличие ксантом и ксантелаз может указывать на гиперхолестеринемию.
        </p>
    )
}
export default HibsKozha