function GemInsultSerdce(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При геморрагическом инсульте, возможны изменения как в сердце, так и 
        в сосудах:
        -	Увеличение массы сердца за счет гипертрофии левого желудочка (при сопутствующей гипертонии). 
        -	При длительном застое (при хронической сердечной недостаточности): расширение полостей сердца (особенно правых отделов).
        -	Признаки хронической ишемической болезни сердца: жировая дистрофия, возможны участки рубцовых изменений миокарда (при перенесенном инфаркте).
        -	Миокард: полнокровие капилляров и мелкие кровоизлияния.
        -	Атеросклероз крупных артерий (аорты, почечных, коронарных).
	    -   Венозный застой в периферических венах.
        </p>                
    )
}
export default GemInsultSerdce