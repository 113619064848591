function MiocarditKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При миокардите возможны:
Бледность или цианоз (синюшность) кожи, особенно в периферических зонах (конечности, губы, носогубный треугольник) — следствие сердечной недостаточности.
Отечность, преимущественно нижних конечностей (стоп и голеней), что связано с застойной сердечной недостаточностью.
Выраженное похудание, кахексия в случае длительного течения миокардита.
Возможны признаки сопутствующих инфекций, например, специфические сыпи (корь, скарлатина).
Возможно умеренное выбухание в области сердца, особенно при значительном увеличении его размеров.
Выраженное набухание яремных вен, что свидетельствует о застое в большом круге кровообращения.
Пастозный отек лица.
Увеличение объема живота за счет асцита при тяжелой сердечной недостаточности, связанной с миокардитом.
Появление пенного содержимого у рта и носа, указывающее на острый отек легких вследствие внезапной сердечной недостаточности.
Пролежни (при длительном обездвиживании и нахождении в тяжелом состоянии до смерти).
Трофические изменения кожи и ногтей (хрупкость, ломкость) при хронической сердечной недостаточности.
Возможны точечные или более крупные геморрагии на коже, вплоть до инфарктов мягких тканей, проявляющихся некрозом мышц и кожи, с образованием чёрных или багровых участков 
(при тромбоэмболии крупных артерий).
        </p>
    )
}
export default MiocarditKozha