function FeAnemiLab(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Для железодефицитной анемии при <u>гистологическом исследовании:</u> выявляются изменения, связанные с гипоксией, нарушением метаболизма железа и компенсаторными 
            процессами. Примеры микроскопических изменений:
1. Костный мозг:
-	Гиперплазия эритроидного ростка: увеличение числа клеток эритроидного ряда в костном мозге (эритробластов и нормобластов) как компенсаторный ответ на анемию.
-	Отмечается преобладание мелких и гипохромных эритроцитов (микроцитоз и гипохромия).
-	Уменьшение или полное отсутствие сидеробластов (незрелых эритроцитов, в цитоплазме которых содержится железо, депонированное в виде гранул).
2. Миокард:
-	Признаки гипоксии кардиомиоцитов: набухание клеток, вакуолизация цитоплазмы, снижение гликогена.
-	Очаговые зоны липофусциноза (отложение пигмента из-за стресса клеток при хронической гипоксии).
-	Могут наблюдаться участки некроза и дистрофии кардиомиоцитов при тяжелой и длительной анемии.
3. Печень:
-	Жировая дистрофия гепатоцитов: вакуолизация цитоплазмы с накоплением жировых капель.
-	Гипоксия усиливает нарушения метаболизма, что приводит к очаговым зонам некроза в печени.
4. Слизистые оболочки (например, желудок, кишечник):
-	Атрофия эпителия слизистой оболочки: истончение эпителиального слоя и снижение количества железистых клеток.
-	Нарушение процессов регенерации слизистой.
-	Уменьшение количества сосудов в подслизистом слое и их патологическое сужение.
5. Скелетные мышцы:
-	Признаки гипоксии мышечных волокон: вакуолизация цитоплазмы, отложение липофусцина.
-	Уменьшение диаметра мышечных волокон и потеря поперечной исчерченности.
6. Головной мозг:
-	Очаговые изменения нейронов: набухание клеток, вакуолизация цитоплазмы, нарушение структуры ядра.
-	Признаки гипоксической энцефалопатии, такие как отек периваскулярных и перицеллюлярных пространств.
7. Почки:
-	В канальцах можно увидеть дистрофические изменения эпителия: зернистая и вакуолярная дистрофия.
-	Очаговый некроз эпителия канальцев в тяжелых случаях хронической гипоксии.
8. Легкие:
-	Полнокровие капилляров, особенно в альвеолярных перегородках, как следствие хронической гипоксии.
-	Умеренный отек интерстициальной ткани.
9. Кожа:
-	Уменьшение толщины эпидермиса.
-	Снижение количества меланоцитов и нарушения кератинизации (гиперкератоз).
(При В12/фолиеводефицитной анемии в звездчатых ретикулоэндотелиоцитах, гепатоцитах, макрофагах костного мозга и селезенки, в эпителии проксимальных канальцев встречаются отложения 
гемосидерина, также как и при гемолитических анемиях). <u>Гистохимическое исследование</u> (окраска тканей на железо (реакция Перлса) - выявление депо железа в тканях (особенно в костном мозге, печени и селезенке)): при ЖДА 
наблюдается уменьшение или отсутствие сидеробластов в костном мозге (отсутствие гранул железа в клетках костного мозга (негативная реакция Перлса)), истощение депо железа в 
макрофагах печени и селезенки. <u>Биохимический анализ тканей:</u> 
-	Определение содержания железа в крови.
-	Измерение уровня ферритина в тканях печени или селезенки.
-	Исследование уровня трансферрина и насыщенности его железом. <u>Исключение других видов анемий:</u> 
1. Апластической анемии: увеличение количества жировых клеток и уменьшение объема кроветворной ткани в костном мозге (при ЖДА - наоборот).
2. Авитаминозной анемии (В12/фолиеводефицитной анемии):
 - наличие мегалобластов в костном мозге (отсутствует при ЖДА).
 - наличие гиперхромных макроцитов (не характерно для ЖДА).
3. Гемолитической анемии: признаки гемолиза (желтуха, увеличение селезенки). <u>Обнаружение причины дефицита железа (по возможности):</u> - Желудочно-кишечный тракт (очаги хронической кровопотери (язвы, опухоли, полипы, кровоточащие эрозии), атрофический 
гастрит, нарушение всасывания в тонком кишечнике).
- Учитывать признаки хронических заболеваний (почечной недостаточности, аутоиммунных процессов).
- Гинекологические причины:
1. Гиперпластические изменения эндометрия (увеличение толщины слизистой оболочки матки (до 15 мм и более), часто сопровождается избыточными кровотечениями (меноррагиями), что 
приводит к хронической кровопотере).
2. Миома матки: матка может быть увеличена за счет множественных или одиночных миоматозных узлов.
Узлы могут быть субмукозными (подслизистыми), интрамуральными или субсерозными.
Подслизистые узлы часто связаны с обильными менструальными кровотечениями.
Узлы на разрезе плотные, с волокнистой структурой, иногда с признаками некроза или дегенерации).
3. Эндометриоз: эндометриоидные очаги на серозных оболочках, яичниках, стенках матки или в брюшной полости.
Очаги темно-коричневого цвета ("шоколадные" кисты яичников) указывают на повторяющиеся микрокровотечения, которые могут усугублять анемию.
4. Полипы эндометрия в полости матки: полипы могут быть различного размера и структуры. Часто сопровождаются аномальными маточными кровотечениями.
5. Гормонально активные опухоли яичников:
например, гранулезоклеточные опухоли, которые могут вызывать гиперэстрогению и, как следствие, гиперплазию эндометрия и кровотечения.
6. Хронические кровопотери из-за разрывов матки, влагалища или шейки матки (особенно после родов или травм), если кровопотеря не была адекватно восполнена при жизни.
Признаки заживления на местах разрывов или рубцевания могут указывать на хронический характер кровопотерь.
7. Наличие ВМС в полости матки: некоторые виды ВМС (без гормонального компонента) могут провоцировать обильные или продолжительные менструации.
8. Маточные кровотечения неизвестного генеза: остатки свернувшейся крови в полости матки или влагалище могут указывать на недавние кровотечения.
9. Замершая беременность: остатки плода или плодных оболочек, признаки нарушения беременности.
10. Послеродовые осложнения: гипотония матки, задержка частей плаценты, разрывы.
11. Хронический эндометрит: утолщение или истончение стенок матки. Наличие гнойных выделений или воспалительных изменений, которые могут усугублять кровопотери.
Каждое из этих состояний при жизни может сопровождаться хронической кровопотерей, приводящей к развитию железодефицитной анемии. <u>Токсикологические исследования (по необходимости):</u> 
исключение отравлений веществами, которые могут вызывать анемию (свинец, бензол и др.).
        </p>
    )
}
export default FeAnemiLab