function PostgemAnemiVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелась острая постгеморрагическая анемия на фоне ... (например, желудочно-кишечного) кровотечения (потеря крови ≈ 1000 мл)., на что указывают макро- и микро- 
            скопические изменения во внутренних органах (сердце, лёгкие, печень, селезенка, источник кровотечения - например язвы/эрозии желудка с кровоизлиянием), наличие крови в 
            ... (желудке и/или кишечнике, например, жидкая или свернувшаяся кровь в желудке, мелена в кишечнике), низкий гемоглобин, уменьшение количества эритроцитов, признаки 
            гипоксии.
            Острая постгеморрагическая анемия — это патологическое состояние, возникающее вследствие быстрой и значительной кровопотери, характеризующееся резким снижением объёма 
            циркулирующей крови, концентрации гемоглобина и количества эритроцитов, что приводит к гипоксии тканей и нарушению функций жизненно важных органов.
            Это состояние (острая постгеморрагическая анемия) могло способствовать наступлению смерти.
        </p>
    )
}
export default PostgemAnemiVyvody