function MiocarditLungs(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При миокардите из-за хронической сердечной недостаточности в легких возможны следующие изменения:
-	Застойная гиперемия и отек (легкие тяжелые, плотные, увеличены в объеме, багрового цвета, на разрезе выделяется обильная пенистая кровянистая жидкость)
-	"Бурая индурация" (в долгосрочной перспективе возможно развитие бурой индурации легких, связанной с отложением гемосидерина в альвеолярных макрофагах).
Также возможны: инфаркты (тромбоэмболические осложнения) и участки мелкоточечных кровоизлияний в легких, гидроторакс и асцит.
Инфаркт в легком имеет характерную клиновидную форму с чётко очерчеными границами, с основанием, обращённым к плевре, и вершиной, направленной к корню лёгкого (к месту закупорки сосуда).
Свежий инфаркт: плотный участок тёмно-красного или багрового цвета из-за выраженного кровенаполнения и геморрагического пропитывания ткани.
Поздняя стадия: через несколько дней инфаркт может становиться мягким в центре (из-за некроза ткани), коричневатым из-за разрушения эритроцитов и образования гемосидерина.
Размер зависит от калибра поражённого сосуда. Инфаркт может быть небольшим (несколько сантиметров) или затрагивать значительные участки лёгкого.
Поверхность инфаркта часто слегка выпуклая из-за отёка и набухания поражённой ткани. При поражении поверхностных участков лёгкого инфаркт может вызывать втянутость плевры из-за 
воспалительной реакции.
Плевра над инфарктом часто втянута и покрыта фибринозным экссудатом из-за реактивного воспаления (фибринозный плеврит).
Возможны мелкие кровоизлияния по периферии инфаркта.
        </p>
    )
}
export default MiocarditLungs