function GemInsultVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При жизни имелся геморрагический инсульт (внутримозговое кровоизлияние в левой височной доле диаметром 1,5 см), на что 
        указывают макро- и микро- скопические изменения головного мозга. 
        Обнаруженное внутримозговое кровоизлияние в левой височной доле представляет собой небольшой очаг, не вызвавший значительных изменений, таких как отек или компрессия 
        головного мозга. 
        Данный инсульт носит характер сопутствующего заболевания и не имел значимого влияния на механизм смерти. Указанное заболевание в причинной связи с наступлением смерти - 
        не состоит. Геморрагический инсульт – полиэтиологическое заболевание, включающее все формы нетравматического внутричерепного кровоизлияния. Возможными причинами развития 
геморрагического инсульта могут являться: артериальная гипертензия, аневризмы, артериовенозные мальформации, амилоидная ангиопатия, коагулопатии и прием антикоагулянтов, атеросклероз,
системные и инфекционные васкулиты, опухоли, генетические и аутоиммунные заболевания, вторичные факторы (декомпенсация сахарного диабета (повышение проницаемости сосудов), курение, 
алкоголизм (увеличивают сосудистую ломкость), хронический стресс и сильное физическое напряжение, провоцирующие гипертонические кризы).
        </p>
    )
}
export default GemInsultVyvody