function SepsisKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При сепсисе возможны:
1. Изменения кожи и слизистых оболочек:
-	Кожные высыпания: петехиальные кровоизлияния (мелкие точечные красные пятна), особенно на коже туловища, конечностей, слизистых оболочек; экхимозы (обширные кровоизлияния), 
возникающие из-за ДВС-синдрома; геморрагическая пурпура — участки синюшно-фиолетовых пятен, часто несимметричные.
-	Гнойничковые элементы и некрозы: наличие абсцессов или язв в области ран, инъекций, хирургических швов; некрозы кожи, связанные с микротромбозами и ишемией тканей.
-	Цвет кожи: бледно-сероватый или желтушный оттенок кожи из-за анемии и билирубинемии; мраморность кожи, особенно на конечностях, указывает на сосудистую недостаточность.
-   Выраженная венозная сеть (или венозный рисунок) на коже трупа является одним из характерных признаков, которые можно наблюдать при сепсисе, особенно в случаях длительного 
септического процесса, сопровождающегося тяжелыми нарушениями микроциркуляции и гемодинамики; cеть выглядит в виде четко очерченных, расширенных вен, часто с синеватым или 
фиолетовым оттенком, чаще всего проявляется в области груди, живота, боковых поверхностей туловища и конечностей; узоры напоминают "мраморную" структуру, особенно в сочетании с 
кожной мраморностью.
2. 	Признаки инфекции в местах ран, хирургических швов или пролежней: 
-   Покраснение, отек, инфильтрация окружающих тканей.
-	Выделения из ран: гной, серозная жидкость с неприятным запахом.
-	Наличие свищей или язв с признаками гнойного расплавления.
3. Системные признаки интоксикации:
-	Общая истощенность: резкое снижение массы тела (кахексия), дряблость тканей.
-	Дегидратация: сухость кожи и слизистых оболочек; запавшие глаза, тусклость склер.
4. Изменения, связанные с полиорганной недостаточностью:
-	Желтушность кожи и склер: указывает на поражение печени и билирубинемию.
-	Цианоз: синюшность губ, носа, кончиков пальцев вследствие гипоксии и нарушений микроциркуляции.
-	Отечность: генерализованные отеки (анасарка) или локализованные (например, в области лица или нижних конечностей) из-за почечной или сердечно-сосудистой недостаточности.
-	Гиперемия лица: иногда может быть выражена из-за лихорадки в терминальной стадии.
5. Признаки ДВС-синдрома:
-	Геморрагии: множественные кровоизлияния в местах инъекций, травм; «трупные пятна» с геморрагическим компонентом.
-	Тромбообразование: возможно наличие тромбированных сосудов, заметных под кожей.
6. Специфические изменения конечностей:
-	Синдром септического шока: синюшные конечности; признаки гангрены пальцев или участков кожи из-за нарушения перфузии.
7. Особенности трупных изменений:
-	Раннее и выраженное трупное охлаждение: из-за кахексии и общего истощения организма.
-	Мраморность кожи: резко выраженная из-за нарушений микроциркуляции при жизни.
-	Интенсивные трупные пятна: более темного цвета, иногда с геморрагическим компонентом.
8. Признаки, связанные с первичным очагом инфекции:
-   При септикопиемии ворота инфекции предствляют собой септический очаг с гнойным лимфангитом, лимфаденитом и гнойным тромбофлебитом; при септицемии септический очаг, как правило, 
отсутствует (входные ворота инфекции не обнаруживаются)
-	Абдоминальный сепсис: признаки гнойного воспаления кожи в области хирургического шва или дренажей.
-	Сепсис, связанный с мягкими тканями: флегмоны, некротические изменения (например, при гнойных процессах конечностей).
-	Катетер-ассоциированный сепсис: признаки воспаления или нагноения в местах введения катетеров.
9. Реакция лимфатической системы на системную инфекцию: увеличение регионарных лимфоузлов (в непосредственной близости от первичного очага инфекции), либо общая лимфаденопатия (
    увеличиваются несколько групп лимфоузлов по всему телу, включая паховые, подмышечные, шейные).
10. Периферические признаки септического эндокардита:
- Петехии: точечные кровоизлияния, обычно расположенные на слизистых оболочках (конъюнктива, полость рта), на коже туловища, конечностей; появляются вследствие васкулита или микроэмболий.
- Геморрагическая сыпь: мелкие пятна и папулы красного или пурпурного цвета, вызванные повреждением мелких сосудов.
- Пятна Джейнуэя: безболезненные, плоские геморрагические пятна на ладонях и подошвах; возникают из-за микротромбозов и микроэмболий.
- Кровоизлияния в конъюнктиву: петехии или полосовидные кровоизлияния под слизистой оболочкой конъюнктивы (
петехиальные кровоизлияния в коньюктиве глаз у внутреннего угла нижнего века - пятна Лукина-Либмана).
- Пятна Рота: белые или желтоватые пятна с геморрагическим ободком на сетчатке.
- Подногтевые геморрагии: продольные полоски под ногтями (сплинтер-геморрагии); обусловлены микроэмболией или васкулитом.
- Узелки Ослера: болезненные подкожные образования (узелки) красного или пурпурного цвета на ладонных поверхностях кистей, подушечках пальцев рук и ног; обусловлены иммунными комплексами и васкулитом.
- Утолщение ногтевых фаланг ("барабанные палочки").
- Отек суставов: связан с воспалительными процессами и нарушением кровообращения.
- Очаги некроза в подкожной жировой клетчатке.
- Желтуха.
        </p>
    )
}
export default SepsisKozha