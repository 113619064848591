function GipCardioSerdceAorta(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для гипертрофической кардиомиопатии (ГКМП) характерно:
сердце значительно увеличено (кардиомегалия), масса сердца может превышать 500–600 г и достигать 1 кг и более, что заметно превышает норму. Возможно наличие жидкости в полости перикарда (гидроперикард).
Чаще всего наблюдается асимметричная гипертрофия межжелудочковой перегородки (МЖП), при которой толщина МЖП превышает 15 мм и в 1,5-2 раза больше толщины свободной стенки левого желудочка. Это приводит к сужению 
выносящего тракта левого желудочка.
Очаговое утолщение межжелудочковой перегородки можно наблюдать под аортальным клапаном (идиопатический мышечный субаортальный стеноз), реже, в средней части межжелудочковой перегородки. Также очаги гипертрофии могут 
наблюдаться в верхушке сердца, в передней или задней стенках левого желудочка. Возможна также редкая равномерная (симметричная) концентрическая гипертрофия миокарда левого желудочка.
Полость левого желудочка обычно уменьшена из-за утолщения стенок, что затрудняет наполнение желудочка кровью в диастолу, в 5-10 % случаев - полость левого желудочка расширена, что означает быстрое нарастание 
декомпенсации.
Ткань миокарда может выглядеть плотной, «перегруженной»; на разрезе — неоднородной, с множественными мелкими участками фиброза (диффузный кардиосклероз), что придает ей пятнистый вид. На горизонтальных разрезах 
межжелудочковой перегородки, проходящих через очаги гипертрофии, отмечается беспорядочное переплетение пучков кардиомиоцитов (завихрения).
Возможно утолщение передней створки митрального клапана и её смещение (SAM-феномен), что способствует обструкции выносящего тракта левого желудочка. Пристеночный эндокард под аортальным клапаном утолщен, белесоватого
цвета.
Коронарные артерии обычно не имеют признаков стенозирующего атеросклероза, просветы их даже шире обычного. Встречается аномальное расположение или структура коронарных артерий (дисплазия интрамуральных артерий и 
артериол).
При раздельном взвешивание частей сердца (РВЧС) можно обнаружить следующие отклонения:
1. Увеличение массы левого желудочка (ЛЖ) может достигать 200–350 г или более в зависимости от степени гипертрофии.
Асимметричная гипертрофия межжелудочковой перегородки (обычно толщина ≥15 мм, может превышать 20–30 мм).
Гипертрофия свободной стенки ЛЖ (толщина более 12–15 мм).
2. Уменьшение массы правого желудочка (ПЖ) или её относительная сохранность. Однако, в некоторых случаях может наблюдаться компенсаторная гипертрофия.
3. Увеличение массы межжелудочковой перегородки (МЖП), её толщина значительно превышает норму (≥15–20 мм). МЖП доминирует в массе ЛЖ, создавая асимметрию.
4. Увеличение массы левого предсердия (гипертрофия и дилатация) до 25–30 г из-за хронической диастолической дисфункции ЛЖ.
5. Общая масса сердца  значительно увеличена, часто превышает 450–500 г.
6. Индекс эпикардиального жира (ИЭЖ) (процентное отношение веса эпикардиального жира к общему весу сердца): масса эпикардиального жира может быть относительно снижена из-за гипертрофии миокарда, увеличивающей общий 
вес сердца.
7. Индекс левого желудочка (ИЛЖ) (процентное отношение веса левого желудочка к чистому весу сердца): увеличение массы ЛЖ при гипертрофии приводит к повышению ИЛЖ.
8. Индекс правого желудочка (ИПЖ) (процентное отношение веса правого желудочка к чистому весу сердца): масса ПЖ остается нормальной или несколько снижена, в результате ИПЖ может снижаться.
9. Индекс миокардиально-ростовой (ИМР) (отношение чистого веса сердца к росту; единица измерения — г/см с округлением до сотых долей): увеличение чистого веса сердца (из-за гипертрофии) приводит к увеличению ИМР.
10. Желудочковый индекс (ЖИ) (отношение веса правого желудочка к весу левого, измеряемое безразмерным, неименованным числом с точностью до сотых долей единицы): уменьшается из-за значительного увеличения массы ЛЖ при 
нормальной или сниженной массе ПЖ.
11. Индекс Фултона (ИФ) (отношение суммы весов свободной стенки левого желудочка и межжелудочковой перегородки к весу свободной стенки правого желудочка, измеряется 
    неименованными числами с десятыми долями): увеличение массы ЛЖ и МЖП приводит к повышению ИФ.
12. Индекс кровоснабжения миокарда (ИКМ) (отношение чистого веса сердца к суммарной площади просвета венечных артерий): чистый вес сердца увеличивается из-за гипертрофии, однако площади просветов артерий обычно не 
увеличиваются пропорционально, что приводит к повышению ИКМ (недостаточность кровоснабжения гипертрофированного миокарда).
Эти отклонения отражают основную морфологическую особенность ГКМП — гипертрофию левого сердца и асимметричное утолщение межжелудочковой перегородки, а также относительное ухудшение коронарного кровоснабжения.
        </p>                
    )
}
export default GipCardioSerdceAorta