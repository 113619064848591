function HoshimotoKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При аутоиммунном тиреоидите с гипотиреозом возможны:
1.	Отеки мягких тканей: особенно выражены в области лица (микседема); лицо «маскообразное», бледное, одутловатое, с припухшими веками; отечность плотная, надавливание не оставляет ямки.
2.	Сухость и утолщение кожи: кожа грубая, бледно-желтоватого оттенка (восковидного цвета); возможны шелушение, гиперкератоз (особенно локтей и коленей).
3.	Волосы ломкие, истонченные, тусклые, могут быть диффузное поредение волос или очаговая алопеция.
4.	Ногти тонкие, хрупкие, с продольной исчерченностью.
5.	Шея: возможное увеличение щитовидной железы (зоб); визуально заметные или пальпируемые плотные участки на месте щитовидной железы (при диффузном или очаговом фиброзе).
6.	Подкожная жировая клетчатка: неравномерное распределение с преобладанием отложения в области живота и бедер, снижение общего тургора тканей.
8.	Мышечный тонус: мышцы дряблые, возможна гипотрофия.
9.	Избыточная масса тела или ожирение.
10.	Изменения в полости рта: гипертрофия языка (язык широкий, отпечатки зубов по краям), возможна сухость слизистых оболочек.
        </p>
    )
}
export default HoshimotoKozha