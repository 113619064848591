function MiocarditSerdceAorta(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При миокардите сердце может быть увеличено в размерах (кардиомегалия), масса сердца превышает возрастные и половые нормы.
Миокард имеет пятнистую окраску, чередование участков бледно-желтого, серого, красного или темно-красного цвета. Это связано с очагами воспаления, кровоизлияний, дегенерации или 
некроза. Миокард может быть дряблым, что указывает на его воспалительное поражение и дегенеративные изменения, в далеко зашедшей стадии в миокарде могут встречаться очаги фиброза. Расширение полостей сердца, чаще
левого желудочка и левого предсердия, свидетельствует о нарушении сократительной функции миокарда. Возможны мелкоточечные кровоизлияния в эндокарде и в эпикарде. Перикардиальная полость может содержать серозный или 
серозно-фибринозный выпот (перикардит как сопутствующее поражение). При идиопатическом миокардите Абрамова-Фидлера типично распространение поражения миокарда всех отделов сердца (сердце увеличено в размерах, дряблое, 
полости растянуты и как правило с тромботическими наложениями), мышца на разрезе пестрая, клапаны интактны, в исходе идиопатического миокардита развивается очаговый или диффузный кардиосклероз, нередко в сочетании с 
гипертрофией миокарда.
        </p>                
    )
}
export default MiocarditSerdceAorta