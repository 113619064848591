function AlcoVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При жизни имелся хронический алкоголизм: алкогольная кардиомиопатия, жировая дистрофия печени, хронический алкогольный гепатит, 
        хронический алкогольный панкреатит, алкогольная энцефалопатия, на что указывают макро- и микро- скопические 
        изменения внутренних органов (печени, сердца, поджелудочной железы и головного мозга), маркеры хронического употребления алкоголя (ацетальдегид и жирные кислоты этилового 
        эфира (FAEE)), обнаруженные при токсикологическом исследовании ткани печени и головного мозга, алкогольный анамнез имеющийся в материалах дела. 
        Хронический алкоголизм — это прогрессирующее заболевание, обусловленное систематическим употреблением алкогольных напитков, характеризующееся физической и психологической 
        зависимостью от этанола, развитием толерантности (повышения дозы для достижения эффекта), поражением внутренних органов, нарушением обмена веществ и социальной деградацией.
Основными проявлениями хронического алкоголизма являются:
-	Физическая зависимость.
-	Психическая зависимость.
-	Соматические нарушения.
-	Неврологические изменения.
-	Психические расстройства.
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.</p>
    )
}
export default AlcoVyvody