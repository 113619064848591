import React from 'react';
import styles from './SideMenuPatan.module.css'; // Импортируем стили как модуль

import { useSelector, useDispatch } from 'react-redux';
// import { dopPovrezhdenia } from '../store/index';
import { patan } from '../store/index';

function SideMenuPatan() {
    const gb = useSelector((state) => state.pat.gb);
    const saharDiab1 = useSelector((state) => state.pat.saharDiab1);
    const saharDiab2 = useSelector((state) => state.pat.saharDiab2);
    const vich = useSelector((state) => state.pat.vich);
    const covid = useSelector((state) => state.pat.covid);
    const gepatB = useSelector((state) => state.pat.gepatB);
    const gepatC = useSelector((state) => state.pat.gepatC);
    const sifilis = useSelector((state) => state.pat.sifilis);
    const gripp = useSelector((state) => state.pat.gripp);
    const tuberc = useSelector((state) => state.pat.tuberc);
    const krona = useSelector((state) => state.pat.krona);
    const njak = useSelector((state) => state.pat.njak);
    const greyvs = useSelector((state) => state.pat.greyvs);
    const hoshimoto = useSelector((state) => state.pat.hoshimoto);
    const gemInsult = useSelector((state) => state.pat.gemInsult);
    const oglomerulo = useSelector((state) => state.pat.oglomerulo);
    const feAnemi = useSelector((state) => state.pat.feAnemi);
    const postgemAnemi = useSelector((state) => state.pat.postgemAnemi);
    const hronPielo = useSelector((state) => state.pat.hronPielo);
    const ishInsult = useSelector((state) => state.pat.ishInsult);
    const infarctMioc = useSelector((state) => state.pat.infarctMioc);
    const alco = useSelector((state) => state.pat.alco);
    const dilCardio = useSelector((state) => state.pat.dilCardio);
    const gipCardio = useSelector((state) => state.pat.gipCardio);
    const miocardit = useSelector((state) => state.pat.miocardit);
    const sepsis = useSelector((state) => state.pat.sepsis);
    const hibs = useSelector((state) => state.pat.hibs);
    const mkb = useSelector((state) => state.pat.mkb);

    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);

    const dispatchFunction = useDispatch();
    function changeStatusHandler(type) {
        if (type === 'gb') {
            dispatchFunction(patan.gb());
        } else if (type === 'saharDiab1') {
            dispatchFunction(patan.saharDiab1());
        } else if (type === 'saharDiab2') {
            dispatchFunction(patan.saharDiab2());
        } else if (type === 'vich') {
            dispatchFunction(patan.vich());
        } else if (type === 'covid') {
            dispatchFunction(patan.covid());
        } else if (type === 'gepatB') {
            dispatchFunction(patan.gepatB());
        } else if (type === 'gepatC') {
            dispatchFunction(patan.gepatC());
        } else if (type === 'sifilis') {
            dispatchFunction(patan.sifilis());
        } else if (type === 'gripp') {
            dispatchFunction(patan.gripp());
        } else if (type === 'tuberc') {
            dispatchFunction(patan.tuberc());
        } else if (type === 'krona') {
            dispatchFunction(patan.krona());
        } else if (type === 'njak') {
            dispatchFunction(patan.njak());
        } else if (type === 'greyvs') {
            dispatchFunction(patan.greyvs());
        } else if (type === 'hoshimoto') {
            dispatchFunction(patan.hoshimoto());
        } else if (type === 'gemInsult') {
            dispatchFunction(patan.gemInsult());
        } else if (type === 'oglomerulo') {
            dispatchFunction(patan.oglomerulo());
        } else if (type === 'feAnemi') {
            dispatchFunction(patan.feAnemi());
        } else if (type === 'postgemAnemi') {
            dispatchFunction(patan.postgemAnemi());
        } else if (type === 'hronPielo') {
            dispatchFunction(patan.hronPielo());
        } else if (type === 'ishInsult') {
            dispatchFunction(patan.ishInsult());
        } else if (type === 'infarctMioc') {
            dispatchFunction(patan.infarctMioc());
        } else if (type === 'alco') {
            dispatchFunction(patan.alco());
        } else if (type === 'dilCardio') {
            dispatchFunction(patan.dilCardio());
        } else if (type === 'gipCardio') {
            dispatchFunction(patan.gipCardio());
        } else if (type === 'miocardit') {
            dispatchFunction(patan.miocardit());
        } else if (type === 'sepsis') {
            dispatchFunction(patan.sepsis());
        } else if (type === 'hibs') {
            dispatchFunction(patan.hibs());
        } else if (type === 'mkb') {
            dispatchFunction(patan.mkb());
        } 
        // else if (type === 'ushibRanaNoogr') {
        //     dispatchFunction(dopPovrezhdenia.ushibRanaNoogr());
        // }
    }

    return (
        
        <div className={styles["side-menu-wrapper"]}>
            <div className={styles["side-menu"]}>
                <ul> Дополнительно добавить патологию внутренних органов:  
                    <li
                        className={`${tuberc ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('tuberc')}
                    >
                        Туберкулез
                    </li>  
                    <li
                        className={`${sifilis ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('sifilis')}
                    >
                        Сифилис
                    </li>  
                    <li
                        className={`${gepatB ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('gepatB')}
                    >
                        Вирусный гепатит B
                    </li>
                    <li
                        className={`${gepatC ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('gepatC')}
                    >
                        Вирусный гепатит C
                    </li>  
                    <li
                        className={`${vich ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('vich')}
                    >
                        ВИЧ-инфекция, стадия (период) СПИД
                    </li>
                    <li
                        className={`${gripp ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('gripp')}
                    >
                        Грипп
                    </li> 
                    <li
                        className={`${covid ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('covid')}
                    >
                        COVID-19
                    </li> 
                    <li
                        className={`${feAnemi ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('feAnemi')}
                    >
                        Железодефицитная анемия, в том числе вторичная (вследствие хронической потери крови)
                    </li> 
                    <li
                        className={`${postgemAnemi ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('postgemAnemi')}
                    >
                        Острая постгеморрагическая анемия
                    </li> 
                    <li
                        className={`${greyvs ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('greyvs')}
                    >
                        Болезнь Грейвса (диффузный токсический зоб)
                    </li> 
                    <li
                        className={`${hoshimoto ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('hoshimoto')}
                    >
                        Аутоиммунный тиреоидит (тиреоидит Хашимото) с гипотиреозом
                    </li> 
                    <li
                        className={`${saharDiab1 ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('saharDiab1')}
                    >
                        Сахарный диабет 1 типа
                    </li>         
                    <li
                        className={`${saharDiab2 ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('saharDiab2')}
                    >
                        Сахарный диабет 2 типа
                    </li>     
                    <li
                        className={`${alco ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('alco')}
                    >
                        Хронический алкоголизм (алкогольная кардиомиопатия)
                    </li>     
                    <li
                        className={`${gb ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('gb')}
                    >
                        ГБ высокой степени и стадии (3 степень и 2-3 стадии)
                    </li>                  
                    <li
                        className={`${infarctMioc ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('infarctMioc')}
                    >
                        Инфаркт миокарда
                    </li>
                    <li
                        className={`${hibs ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('hibs')}
                    >
                        ХИБС (кардиосклероз, аневризма сердца)
                    </li>
                    <li
                        className={`${sepsis ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('sepsis')}
                    >
                        Бактериальный эндокардит (сепсис)
                    </li>                    
                    <li
                        className={`${miocardit ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('miocardit')}
                    >
                        Миокардит (+ инфаркты внутренних органов в результате тромбоэмболии)
                    </li>                  
                    <li
                        className={`${dilCardio ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('dilCardio')}
                    >
                        Дилатационная кардиомиопатия (ДКМП)
                    </li>                  
                    <li
                        className={`${gipCardio ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('gipCardio')}
                    >
                        Гипертрофическая кардиомиопатия (ГКМП) 
                    </li>                  
                    <li
                        className={`${gemInsult ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('gemInsult')}
                    >
                        Геморрагический инсульт
                    </li>                  
                    <li
                        className={`${ishInsult ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('ishInsult')}
                    >
                        Ишемический инсульт
                    </li>                  
                    <li
                        className={`${krona ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('krona')}
                    >
                        Болезнь Крона
                    </li>                  
                    <li
                        className={`${njak ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('njak')}
                    >
                        Язвенный колит (НЯК)
                    </li>                  
                    <li
                        className={`${oglomerulo ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('oglomerulo')}
                    >
                        Острый гломерулонефрит
                    </li>                  
                    <li
                        className={`${hronPielo ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('hronPielo')}
                    >
                        Хронический пиелонефрит
                    </li>                  
                    <li
                        className={`${mkb ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('mkb')}
                    >
                        Мочекаменная болезнь (нефролитиаз)
                    </li>                  
                   
                    {/* <li
                        className={`${ushibRanaNoogr ? styles.linkActive : ''}`}
                        onClick={() => changeStatusHandler('ushibRanaNoogr')}
                    >
                        Ушибленная рана (неограниченная поверхность)
                    </li> */}
                </ul>
            </div>
        </div>
    );
}

export default SideMenuPatan;

