function NjakKishki(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При НЯК могут быть следующие изменения в толстом кишечнике: Слизистая оболочка: - язвенные дефекты различной глубины и 
        протяженности; - гиперемия и отечность слизистой оболочки; - отсутствие нормальной складчатости (кишечник выглядит «гладким» из-за атрофии слизистой). Язвы: - поверхностные 
        язвы, обычно расположенные диффузно (в отличие от глубоких язв при болезни Крона); - возможны соединения соседних язв, образующие обширные эрозивно-язвенные участки. 
        Псевдополипы: - образования, возникающие в результате регенерации эпителия на фоне язв, напоминающие полипы. Толщина стенки: - умеренное утолщение стенки кишечника в 
        тяжелых случаях; - в отличие от болезни Крона, значительное фиброзное утолщение встречается реже. Кровоизлияния: - петехиальные кровоизлияния в слизистой оболочке. 
        Перфорация (при осложнениях): - в местах глубоких язв может наблюдаться прободение стенки кишечника, что приводит к перитониту. Расширение кишечника (токсический мегаколон):
- в тяжелых случаях при НЯК может развиваться токсический мегаколон; - толстая кишка значительно растянута (диаметр может превышать 6 см); - стенка истончена, в воспаленной 
области возможно прободение.
</p>
    )
}
export default NjakKishki