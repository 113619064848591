import { useSelector } from 'react-redux';

//Импорт компонентов пат изменений:
import GbPochki from '../../patan/gb/GbPochki';
import SaharDiab1Pochki from '../../patan/saharDiab1/SaharDiab1Pochki';
import SaharDiab2Pochki from '../../patan/saharDiab2/SaharDiab2Pochki';
import CovidPochki from '../../patan/covid/CovidPochki';
import GepatBPochki from '../../patan/gepatB/GepatBPochki';
import GepatСPochki from '../../patan/gepatС/GepatСPochki';
import GrippPochki from '../../patan/gripp/GrippPochki';
import TubercPochki from '../../patan/tuberc/TubercPochki';
import GreyvsPochki from '../../patan/greyvs/GreyvsPochki';
import HoshimotoPochki from '../../patan/hoshimoto/HoshimotoPochki';
import OglomeruloPochki from '../../patan/oglomerulo/OglomeruloPochki';
import FeAnemiPochki from '../../patan/feAnemi/FeAnemiPochki';
import PostgemAnemiPochki from '../../patan/postgemAnemi/PostgemAnemiPochki';
import HronPieloPochki from '../../patan/hronPielo/HronPieloPochki';
import IshInsultPochki from '../../patan/ishInsult/IshInsultPochki';
import GemInsultPochki from '../../patan/gemInsult/GemInsultPochki';
import InfarctMiocPochki from '../../patan/infarctMioc/InfarctMiocPochki';
import AlcoPochki from '../../patan/alco/AlcoPochki';
import DilCardioPochki from '../../patan/dilCardio/DilCardioPochki';
import GipCardioPochki from '../../patan/gipCardio/GipCardioPochki';
import MiocarditPochki from '../../patan/miocardit/MiocarditPochki';
import SepsisPochki from '../../patan/sepsis/SepsisPochki';
import HibsPochki from '../../patan/hibs/HibsPochki';
import MkbPochki from '../../patan/mkb/MkbPochki';

function PatanPochki() {

    //Получаем из хранилища состояния пат изменений:
    const gb = useSelector((state) => state.pat.gb);
    const saharDiab1 = useSelector((state) => state.pat.saharDiab1);
    const saharDiab2 = useSelector((state) => state.pat.saharDiab2);
    const covid = useSelector((state) => state.pat.covid);
    const gepatB = useSelector((state) => state.pat.gepatB);
    const gepatC = useSelector((state) => state.pat.gepatC);
    const gripp = useSelector((state) => state.pat.gripp);
    const tuberc = useSelector((state) => state.pat.tuberc);
    const greyvs = useSelector((state) => state.pat.greyvs);
    const hoshimoto = useSelector((state) => state.pat.hoshimoto);
    const oglomerulo = useSelector((state) => state.pat.oglomerulo);
    const feAnemi = useSelector((state) => state.pat.feAnemi);
    const postgemAnemi = useSelector((state) => state.pat.postgemAnemi);
    const hronPielo = useSelector((state) => state.pat.hronPielo);
    const ishInsult = useSelector((state) => state.pat.ishInsult);
    const gemInsult = useSelector((state) => state.pat.gemInsult);
    const infarctMioc = useSelector((state) => state.pat.infarctMioc);
    const alco = useSelector((state) => state.pat.alco);
    const dilCardio = useSelector((state) => state.pat.dilCardio);
    const gipCardio = useSelector((state) => state.pat.gipCardio);
    const miocardit = useSelector((state) => state.pat.miocardit);
    const sepsis = useSelector((state) => state.pat.sepsis);
    const hibs = useSelector((state) => state.pat.hibs);
    const mkb = useSelector((state) => state.pat.mkb);
    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);

    return (
        <>
            {/* Туберкулез: */}
            {tuberc && <><TubercPochki/>
            <br></br></>}
            {/* Гепатит B: */}
            {gepatB && <><GepatBPochki/>
            <br></br></>}
            {/* Гепатит C: */}
            {gepatC && <><GepatСPochki/>
            <br></br></>}
            {/* Грипп: */}
            {gripp && <><GrippPochki/>
            <br></br></>}
            {/* Covid-19: */}
            {covid && <><CovidPochki/>
            <br></br></>}
            {/* Железодеф анемия: */}
            {feAnemi && <><FeAnemiPochki/>
            <br></br></>} 
            {/* Острая постгеморрагическая анемия: */}
            {postgemAnemi && <><PostgemAnemiPochki/>
            <br></br></>} 
            {/* болезнь Грейвса: */}
            {greyvs && <><GreyvsPochki/>
            <br></br></>}
            {/* Хашимото: */}
            {hoshimoto && <><HoshimotoPochki/>
            <br></br></>}
            {/* Сахар диабет 1: */}
            {saharDiab1 && <><SaharDiab1Pochki/>
            <br></br></>}
            {/* Сахар диабет 2: */}
            {saharDiab2 && <><SaharDiab2Pochki/>
            <br></br></>}
             {/* Хронический алкоголизм: */}
             {alco && <><AlcoPochki/>
            <br></br></>}
            {/* ГБ: */}
            {gb && <><GbPochki/>
            <br></br></>}
            {/* Инфаркт миокарда: */}
            {infarctMioc && <><InfarctMiocPochki/>
            <br></br></>}
            {/* ХИБС: */}
            {hibs && <><HibsPochki/>
            <br></br></>}
            {/* Сепсис: */}
            {sepsis && <><SepsisPochki/>
            <br></br></>}
            {/* Миокардит: */}
            {miocardit && <><MiocarditPochki/>
            <br></br></>}
            {/* Дилатационная кардиомиопатия: */}
            {dilCardio && <><DilCardioPochki/>
            <br></br></>}
            {/* Гипертрофическая кардиомиопатия: */}
            {gipCardio && <><GipCardioPochki/>
            <br></br></>}
            {/* Геморрагический инсульт: */}
            {gemInsult && <><GemInsultPochki/>
            <br></br></>}
            {/* Ишемический инсульт: */}
            {ishInsult && <><IshInsultPochki/>
            <br></br></>}
            {/* О. гломерулонефрит: */}
            {oglomerulo && <><OglomeruloPochki/>
            <br></br></>}
            {/* Хронический пиелонефрит: */}     
            {hronPielo && <><HronPieloPochki/>
            <br></br></>} 
            {/* МКБ: */}     
            {mkb && <><MkbPochki/>
            <br></br></>}  
 
            {/* Ссадины: */}
            {/* {ssadiny  && <><SsadinyDiagnoz/>
            <br></br></>} */}
        </>
    );
}

export default PatanPochki;