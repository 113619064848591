function KronaKishki(){
    return (
        <p id="MsoBodyTextIndent" className="green">
       При болезни Крона наиболее часто поражается терминальный отдел подвздошной кишки, но процесс может затрагивать любую часть желудочно-кишечного тракта. При этом: - стенка 
       кишечника утолщена, с утратой эластичности. - Наблюдаются глубокие язвы и трещины, которые могут проникать до серозного слоя, образуя так называемый «булыжниковый» рельеф 
       слизистой оболочки. - Возможно наличие фиброзных стриктур (сужений), приводящих к стенозу кишечника. - В тяжелых случаях можно обнаружить наружные и внутренние свищи, а 
       также абсцессы, возникающие из-за глубоких язвенных поражений.
</p>
    )
}
export default KronaKishki