function FeAnemiKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При железодефицитной анемии может отмечаться:
1. Общий истощенный вид (похудение или признаки кахексии (при тяжелой и длительной анемии), атрофия скелетных мышц, снижение массы мышц из-за длительного 
кислородного голодания).
2. Бледность кожи и слизистых оболочек (кожа может иметь выраженный бледный оттенок, иногда с желтоватым или сероватым оттенком, особенно заметно это на лице, губах, 
внутренней поверхности век (конъюнктива) и ногтевых ложах; так же может быть отмечена сухость и шелушение кожи, в некоторых случаях можно отметить участки атрофии эпидермиса или 
наличие трещин). Возможен легкий цианоз губ и ногтевых лож из-за хронической гипоксии тканей.
3. Изменения волос и ногтей (волосы могут быть истонченными, тусклыми, ломкими и легко выпадать; ногти могут быть тонкими, ломкими, деформированными, приобретая вогнутую 
("ложкообразную") форму (койлонихия)).
4. Гладкий и бледный язык (возможна атрофия сосочков языка ("лакированный язык")), признаки заед (трещины и эрозии в углах рта), связанные с дефицитом железа.
5. Признаки хронических кровопотерь (если они имели место при жизни):
- Следы кровотечений: в области желудочно-кишечного тракта (рвотные массы с кровью при наличии на коже) или на белье (если кровотечения были наружными).
- Признаки тяжелых менструальных кровотечений (у женщин): возможны характерные пятна или прокладки с кровью в интимной зоне.
6. Вторичные признаки хронической гипоксии:
- Отеки (легкие периферические отеки в области стоп и голеней, связанные с нарушением обмена веществ и гипоксией тканей).
- Изменения волос и кожи головы (возможно появление участков диффузного выпадения волос).
        </p>
    )
}
export default FeAnemiKozha