function FeAnemiPochki(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При железодефицитной анемии можно обнаружить следующие изменения почек, которые могут являться причиной дефицита железа 
        (хроническая почечная недостаточность):
Уменьшенные, сморщенные почки (при хроническом гломерулонефрите, пиелонефрите или нефросклерозе).
Масса почек снижена, поверхность почек зернистая или мелкобугристая.
Капсула плохо отделяется, плотная, сращенная с тканью почки (при нефросклерозе).
Корковое вещество утонченное, бледное, истончено до 2–3 мм.
Мозговое вещество резкое уменьшение выраженности пирамид, серовато-белый или серо-желтоватый оттенок.
Расширение и деформация (при вторичном гидронефрозе) лоханок и чашечек.
Атеросклеротические изменения артерий почек. 
Еще одним из вариантов причины дефицита железа возможен волчаночный нефрит, при котором: почки увеличенны или сморщенны, гиперемия и 
петехиальные кровоизлияния на их поверхности.
        </p>                
    )
}
export default FeAnemiPochki