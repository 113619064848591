function SepsisKosti(){
    return (
        <p id="MsoBodyTextIndent" className="green">При сепсисе, в результате гематогенного заноса возбудителей в сустав, возможен 
гнойный артрит: утолщение и отек суставной капсулы.
Гиперемия и полнокровие околосуставных тканей.
Иногда наблюдаются гнойные инфильтраты в прилежащих мягких тканях.
Наличие значительного количества гнойного экссудата в полости сустава (цвет — мутный, желто-зеленый; консистенция — густая, вязкая; возможны примеси крови при тяжелом течении).
Уменьшение объема нормальной суставной жидкости.
Синовиальная оболочка утолщена, покрыта серо-желтыми гнойными наложениями; поверхность шероховатая, неровная, местами изъязвлена.
Хрящ сустава истончен или разрушен.
На поверхности хряща могут быть видны некротические изменения.
Местами хрящ полностью отсутствует, обнажена подлежащая кость.
При длительном течении воспаления возможно вовлечение субхондральной кости (очаги остеонекроза; гнойное расплавление).
В околосуставных тканях (сухожилия, мышцы) — отек, воспаление и гнойные инфильтраты.
Также, при гематогенном распространении инфекции на кость, возможен гнойный остеомиелит:
костный мозг насыщен гнойным экссудатом (цвет — желто-зеленый; консистенция — вязкая; на разрезе выявляются очаги гнойного расплавления костного мозга; возможно образование 
абсцессов внутри кости).
Очаги остеонекроза: участки кости серо-желтого цвета, хрупкие, лишенные нормальной структуры; размягчение костной ткани, связанное с гнойным расплавлением.
Наличие секвестров: фрагменты некротической кости, окруженные гнойным экссудатом.
Имеются участки новообразованной кости (периостальная реакция).
Периост (надкостница) утолщена, инфильтрирована воспалительными клетками и гноем; может быть покрыта фибринозными наложениями.
Мягкие ткани вокруг кости: возможно образование гнойных ходов (свищей), которые могут прорываться наружу (гнойные затеки); выраженный отек и гиперемия мягких тканей.
При вовлечении суставов возможно развитие вторичного гнойного артрита.
        </p>
    )
}
export default SepsisKosti