function AlcoGm(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При хроническом алкоголизме возможны следующие изменения головного мозга и его оболочек:
        -   Сращение твердой мозговой оболочки с костями черепа; огрубление пахионовых грануляций с некоторым увеличением их площади; спайки между оболочками мозга; 
образование интрадуральных гематом; геморрагический пахименингит по Вирхову (утолщение и помутнение твердой мозговой оболочки, наличие в ней множественных мелких или крупных 
кровоизлияний, видимых на поверхности оболочки, возможны участки организовавшихся гематом или рубцов). 
        -	Атрофия головного мозга (уменьшение массы мозга, расширение борозд и желудочков, расширение полостей желудочков, повышенное содержание спинномозговой жидкости).
        -	Алкогольная энцефалопатия (отек и атрофия головного мозга, мелкие кровоизлияния в белом веществе (возможно формирование геморрагических очагов в стволе мозга и 
        мозжечке), полнокровие венозных синусов, серо-желтые участки некроза в коре головного мозга и подкорковых структурах (чаще встречаются в таламусе, гипоталамусе и области 
        среднего мозга)).
        -   Синдромом Веркгафта (алкогольного поражения ствола мозга): уменьшение объема ствола мозга (что часто сопровождается его сглаживанием и утратой четкой границы между 
        различными частями мозга), отечность, гиперемию, кровоизлияния (небольшие кровоизлияния или микротромбы в области ствола мозга; кровоизлияния могут быть локализованы в 
        области мостовидной структуры или в области продолговатого мозга), а также признаки атрофии и 
        патологических изменений в черепных нервах (особенно окуломоторного, глазодвигательного и лицевого нервов), что может быть связано с развитием птоза верхнего века, 
        нарушением мимики или двигательных функций лица. Эти изменения связаны с токсическим воздействием алкоголя на нервные клетки, 
        нарушением микроциркуляции и кровообращения в мозге, что приводит к развитию неврологических симптомов и тяжелых функциональных нарушений.
        </p>                
    )
}
export default AlcoGm