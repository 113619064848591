function DilCardioVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При жизни имелась дилатационная кардиомиопатия (выраженная дилатация полостей сердца, преимущественно левого желудочка, 
        истончение стенки левого желудочка до 8 мм, дряблый миокард, диффузный интерстициальный фиброз, хроническая сердечная недостаточность (хронический венозный застой в 
        печени ("мускатная печень"), застойная индурация легких)), что подтверждается макро- и микро- скопическими изменениями внутренних органов (сердца, печени и легких), 
        наряду с отсутствием других причин дилатации сердца (ишемии, воспаления или токсического воздействия) и обнаружением мутаций в генах LMNA и TTN, связанных с 
        миофибриллярными белками и энергетическим метаболизмом кардиомиоцитов.
        Дилатационная кардиомиопатия (ДКМП) — это заболевание миокарда, характеризующееся патологическим увеличением (дилатацией) полостей сердца, преимущественно левого желудочка, 
        с прогрессирующим снижением сократительной способности сердечной мышцы и развитием хронической сердечной недостаточности. ДКМП может быть первичной (идиопатической), 
        связанной с генетическими нарушениями, или вторичной, развивающейся на фоне инфекционных, токсических (включая алкоголь), аутоиммунных и метаболических факторов. 
        Патогенез включает дегенерацию кардиомиоцитов, интерстициальный фиброз и компенсаторную гипертрофию, что приводит к нарушению насосной функции сердца.
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.</p>
    )
}
export default DilCardioVyvody