function AlcoPodj(){
    return (
        <p id="MsoBodyTextIndent" className="green">При хроническом алкоголизме в поджелудочной железе возможны следующие изменения:       
      -	Хронический панкреатит (за счет рефлюкса желчи из 12-перстной кишки): уменьшение размеров железы, плотная консистенция; поверхность с рубцами, разрез серо-желтоватый с 
      очагами фиброза.
        - Панкреонекроз (поджелудочная железа мягкая и дряблая, в очагах некроза консистенция может напоминать кашицеобразную массу; часто железа увеличена в объеме (отек), но в 
        некоторых случаях может быть уменьшена за счет массивного некроза тканей. Неровные края железы из-за участков разрушения ткани. Пестрый внешний вид железы: бледно-серые 
        или желтоватые участки (жировой некроз), темно-красные или черноватые зоны (геморрагический некроз). На разрезе: чередование плотных участков воспаления, зон жирового 
        некроза и кровоизлияний, белесоватые или сероватые участки указывают на отложение кальция в местах некроза (кальцификация). Возможны признаки вовлечения окружающих тканей:
реактивный перитонит (наличие фибринозных налетов или воспалительного экссудата в брюшной полости), сращения с соседними органами. В брюшной полости может обнаруживаться мутный, 
геморрагический или серозный экссудат. При тяжелом течении — наличие абсцессов или псевдокист. Жировой некроз: желтовато-белые очаги на поверхности и внутри железы, а также на 
соседних тканях (в брюшной клетчатке, на стенках кишечника)). 
        </p>
    )
}
export default AlcoPodj