function SepsisLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для сепсиса, характерно: при <u>гистологическом исследовании:</u> 
1. Легкие:
-	Отек альвеол: заполнение просветов альвеол серозным или геморрагическим экссудатом.
-	Микротромбы в капиллярах: характерно для ДВС-синдрома.
-	Гнойный экссудат: в просветах альвеол и бронхов при развитии септических абсцессов.
-	Скопление нейтрофилов: в альвеолярных перегородках и просветах.
2. Печень:
-	Жировая дистрофия гепатоцитов: увеличенные клетки с вакуолями в цитоплазме.
-	Фокальные некрозы: мелкие участки гибели гепатоцитов, окруженные инфильтратом из лимфоцитов и нейтрофилов.
-	Скопления бактерий: видимые внутри синусоидов или в фокусах некроза.
-	Застой крови в синусоидах.
3. Селезенка:
-	Гиперплазия лимфоидных фолликулов: с расширением герминативных центров.
-	Депрессия лимфоидной ткани: истощение белой пульпы при длительном течении сепсиса.
-	Множественные мелкие некрозы: в красной пульпе.
-	Капиллярные микротромбы и геморрагии.
4. Сердце:
-	Вакуольная дистрофия кардиомиоцитов.
-	Микроабсцессы в миокарде: фокальное скопление нейтрофилов, бактерий и некротизированных клеток.
-	Точечные кровоизлияния: в интерстициальной ткани.
-   При септическом бактериальном эндокардите: вегетации на створках митрального или аортального клапана состоят из фибрина, тромбоцитов, полиморфноядерных лейкоцитов (нейтрофилов) 
и бактериальных клеток. Фибринозные массы, образующие мягкие желтоватые или сероватые вегетации, которые могут быть частично разрушены. Наличие бактерий в центре вегетации, что 
подтверждается окраской по Граму или ПЦР. Вокруг вегетаций часто наблюдается воспаление с признаками васкулита (воспаление сосудов клапана), проявляющееся в виде утолщения и 
фиброзных изменений сосудистых стенок. На участках клапанов, где наблюдается изъязвление, обнаруживаются очаги некроза, разрушение клеток эндотелия и других тканей клапана.
Очаги некроза ткани клапана, окруженные инфильтратом из нейтрофилов и моноцитов.
Образование язв, с наличием бактерий в некротических участках ткани клапана.
Повышенная проницаемость сосудов с отеком и инфильтрацией воспалительными клетками. В миокарде могут быть обнаружены очаги воспаления, абсцессы и признаки гнойного миокардита.
Микроабсцессы в миокарде — полости с желтоватым содержимым, окруженные воспалительными клетками (нейтрофилами, макрофагами).
Внутри абсцессов часто можно найти бактерии (при окраске по Граму или методом ПЦР).
Гнойный миокардит: инфильтрация межуточной ткани миокарда полиморфноядерными лейкоцитами, макрофагами и лимфоцитами. Воспаление может затрагивать как межуточную, так и сосудистую 
ткань. В результате длительного воспаления и нарушения кровоснабжения, в миокарде образуются участки рубцовой ткани.
Участки фиброза в миокарде с накоплением коллагеновых волокон.
Окружение абсцессов миокарда фибробластами и плотными коллагеновыми волокнами, что может привести к образованию рубца.
В области рубцов — уменьшение количества мышечных клеток, замещение их соединительной тканью.
В случаях, когда инфекция распространяется на перикард, может наблюдаться фибринозный или гнойный перикардит.
Отек и инфильтрация перикарда нейтрофилами, лимфоцитами.
Присутствие фибрина в просвете перикарда или гнойных наложений.
В тяжёлых случаях — сращение перикарда с миокардом.
5. Почки:
-	Тубулярная дистрофия: набухание и вакуолизация эпителия канальцев.
-	Микроабсцессы: в интерстициальной ткани.
-	Тромбоз капилляров клубочков: нарушение фильтрационной функции.
-	Острый некротический нефроз: повреждение эпителия извитых канальцев.
6. Головной мозг:
-	Периваскулярные кровоизлияния.
-	Отек тканей: расширение перицеллюлярных пространств.
-	Микротромбы в капиллярах.
-	Очаги гнойного воспаления: при септическом энцефалите.
7. Кишечник:
-	Эрозии и язвы: с инфильтрацией нейтрофилами.
-	Дистрофия энтероцитов.
-	Микротромбоз сосудов подслизистого слоя: ишемические изменения.
8. Общее:
-	ДВС-синдром: множественные микротромбы в сосудах органов.
-	Системное воспаление: инфильтрация тканей нейтрофилами, лимфоцитами и макрофагами.
-	Септические микроабсцессы: некротические очаги с гнойным содержимым в различных органах.
-	Наличие бактерий: в тканях и сосудах (бактериемия).
Эти гистологические изменения отражают системное повреждение органов, вызванное инфекционно-токсическим процессом и нарушением микроциркуляции. <u>Микробиологическое 
    исследование:</u> посев крови (гемокультура) из крупных сосудов (выявление бактерий, грибков или других возбудителей); посев содержимого внутренних органов 
и патологических очагов (абсцессы, выпоты) и идентификация возбудителя. <u>Молекулярно-генетическое исследование:</u> ПЦР-диагностика для выявления ДНК/РНК возбудителей инфекции; 
специфическая идентификация микробов (бактерии, вирусы, грибы). <u>Имуногистохимическое исследование:</u> окраска тканей для выявления бактериальных антигенов и воспалительных 
маркеров. <u>Биохимические исследования:</u> анализ жидкостей тела (кровь, серозный выпот) на признаки сепсиса (уровень прокальцитонина (маркер сепсиса), С-реактивный белок, ферменты, 
свидетельствующие о полиорганной недостаточности), маркеры системного воспаления (цитокины: интерлейкин-6, фактор некроза опухолей-α), признаки ДВС-синдрома (уровень фибриногена, 
D-димеры, тромбоциты). <u>Токсикологическое исследование:</u> исключение других причин смерти, таких как отравления, которые могли спровоцировать инфекцию. <u>Документальный 
    анализ:</u> изучение анамнеза болезни и медицинской документации (хронические инфекции, травмы, операции, инвазивные вмешательства, наличие 
диагностических критериев сепсиса (по Sepsis-3)). Для постановки диагноза "Сепсис" необходимо подтвердить инфекционный характер процесса, наличие системной 
воспалительной реакции и дисфункцию органов. Диагноз основывается на результатах макроскопических, микроскопических, микробиологических и дополнительных лабораторных исследований.
        </p>                
    )
}
export default SepsisLab