function OglomeruloLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для острого гломерулонефрита при <u>гистологическом исследовании</u> можно обнаружить следующие характерные изменения:
1. Почки
Гломерулы:
- Пролиферация клеток: увеличение числа клеток в клубочках (пролиферация эндотелиальных и мезангиальных клеток).
- Инфильтрация: в гломерулы проникают нейтрофилы, лимфоциты и мононуклеары.
- Гиперемия капилляров: расширение капиллярных петель с застоем крови.
- Фибрин: может откладываться в капсуле Боумена и в просветах капилляров (фибриноидный некроз).
- Полулуния: при тяжелых формах в просвете капсулы Боумена появляются полулуния из пролиферирующих эпителиальных клеток и фибрина.
Канальцы:
- Дегенерация эпителия: отек, вакуолизация и дистрофия клеток эпителия канальцев.
- Гиалиновые цилиндры: обнаруживаются в просвете канальцев как результат выпадения белков в осадок.
Интерстиций:
- Отек: интерстициальная ткань отечна, с участками воспалительной инфильтрации лимфоцитами и мононуклеарами.
- Мелкие кровоизлияния: особенно в корковом веществе.
2. Легкие
- Отек: в альвеолах обнаруживается розоватый белковый экссудат.
- Мелкие кровоизлияния: в стенках альвеол и интерстициальной ткани.
- Воспалительные изменения: возможен лимфоцитарный инфильтрат в интерстициальной ткани.
3. Сердце
-	Гипертрофия кардиомиоцитов: увеличение их объема, гиперхромные ядра.
-	Фиброз: в тяжелых случаях возможно развитие интерстициального фиброза.
-	Признаки уремического перикардита: отложение фибрина на поверхности эпикарда.
4. Мозг
-	Отек: расширение периваскулярных и перицеллюлярных пространств.
-	Микрогеморрагии: в области белого вещества или мозговых оболочек.
-	Дистрофические изменения нейронов: набухание, пикноз ядер, плазмолиз.
5. Печень
-	Жировая дистрофия: увеличение размеров гепатоцитов с накоплением капель липидов в цитоплазме.
-	Застойные изменения: расширение центральных вен и синусоидов с застоем крови.
6. Селезенка
-	Гиперплазия лимфоидной ткани: увеличение количества клеток в фолликулах.
-	Венозный застой: расширение синусов с избыточным количеством крови. 
Гистологические изменения отражают воспалительный процесс в клубочках почек, нарушение их фильтрационной функции и вторичные изменения в других органах из-за артериальной 
гипертензии, отеков и накопления токсических продуктов метаболизма. Эти данные помогают уточнить диагноз и оценить степень повреждения органов при остром 
гломерулонефрите. <u>Лабораторные исследования биологических жидкостей:</u> -	Моча из мочевого пузыря: анализ на наличие белка, эритроцитов, цилиндров.
-	Кровь: биохимический анализ (повышение уровня мочевины, креатинина, дисбаланс электролитов), анализ на маркеры воспаления (С-реактивный белок, повышение иммуноглобулинов).
-	Спинномозговая жидкость: исследование на признаки уремии. <u>Иммуногистохимическое исследование:</u> - Определение иммунных комплексов и отложений комплемента (C3) в клубочках.
- Окраска на IgA, IgG, IgM для выявления характерных иммунных отложений в почках. <u>Токсикологическое исследование:</u> исключение токсических поражений почек (например, 
нефротоксинов или лекарственных препаратов). <u>Микробиологические исследования:</u> исследование на возможную инфекционную природу (стрептококковая инфекция, вирусные патогены):
- ПЦР или бактериологический посев из различных органов.
- Определение антистрептолизина-О (АСЛО) в крови, если доступно.
        </p>
    )
}
export default OglomeruloLab