function MiocarditPochki(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При миокардите, в результате сочетания венозного застоя, гипоксии, действия токсинов и воспалительных медиаторов, в почках 
        возможны следующие изменения: - гиперемия почек (они увеличены, гиперемированы, на разрезе отмечается полнокровие сосудов коркового слоя) и - дистрофические изменения:
иногда выявляются признаки дистрофии эпителия канальцев. Также возможен инфаркт почки, возникающий в результате тромбоэмболии. 
Он обычно имеет клиновидную форму с четкими границами. Основание клина направлено к корковому слою, а вершина — к воротам почки. Размер зависит от калибра окклюзированной артерии: 
может быть как небольшим (поражение мелкой ветви), так и крупным, затрагивающим значительную часть органа.
Свежий инфаркт: участок выглядит бледным, серовато-жёлтым или беловатым, плотный на ощупь из-за ишемического некроза ткани; по периферии инфаркта часто виден узкий красноватый 
ободок, обусловленный реактивным кровоизлиянием из мелких сосудов соседних участков (геморрагическая зона); поверхность почки может быть гладкой, а участок поражения немного 
приподнят.
Поздний период: некротическая ткань становится серовато-жёлтой, иногда с зеленоватым оттенком, инфаркт становится мягче из-за начавшегося распада или организации; из-за развития 
рубцовой ткани, границы инфаркта могут быть менее выражены, зона инфаркта слегка втягивается, что может приводить к деформации почки.
Над зоной инфаркта капсула может быть утолщённой, нередко фиксируется фибринозный налёт (при сопутствующем воспалении).
Отмечается гиперемия и отёк прилежащей к инфаркту паренхимы.
В случае небольшой зоны некроза возможна организация с образованием рубца.
При массивных инфарктах возможно развитие вторичных осложнений, таких как абсцесс или нагноение.
В надпочечниках инфаркты могут быть двусторонними, что иногда приводит к синдрому Уотерхауса-Фридериксена (массивное кровоизлияние в надпочечники с развитием острой 
надпочечниковой недостаточности, коллапса кровообращения и полиорганной недостаточности). Макроскопически они выглядят как кровоизлияния, сочетанные с некрозом 
коркового и мозгового слоёв.
        </p>                
    )
}
export default MiocarditPochki