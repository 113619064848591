function DilCardioKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При дилатационной кардиомиопатии возможны:
1. Признаки хронической сердечной недостаточности:
синюшная окраска кожи, особенно выраженная в периферических зонах (губы, нос, ушные раковины, кончики пальцев) из-за хронической гипоксии и застойных явлений.
Выраженные отеки мягких тканей, особенно в области нижних конечностей, голеней, а также возможны полостные отеки (асцит, гидроторакс).
2. Признаки застойных явлений в большом круге кровообращения:
возможно выпячивание в области подреберья из-за увеличенной печени.
Желтушность кожных покровов может наблюдаться в сочетании с признаками холестаза, вызванного застоем крови в печени.
Видимое расширение вен на шее (набухшие шейные вены) или грудной клетке, особенно в положении лежа.
3. Признаки общего истощения:
значительная потеря массы тела, выраженное истощение мягких тканей, что связано с длительной сердечной недостаточностью.
Истончение и дряблость мышц, связанная с недостаточным кровоснабжением и гипоксией тканей.
4. Признаки гипоксических изменений:
сухость, шелушение кожи, язвы на голенях или стопах из-за недостаточного кровоснабжения.
Выраженные трупные пятна с синюшным оттенком, особенно на нижних частях тела.
5. Признаки легочного застоя:
ортопноэ (посмертное положение): может быть замечено следствие вынужденного положения тела, характерного для выраженной одышки при жизни.
Розовая пенистая жидкость в дыхательных путях: это косвенный признак отека легких при жизни.
6. Сердечный горб (возможный признак):
при длительной дилатации сердечных камер может быть выражено выпячивание грудной клетки в области сердца (особенно у детей и молодых людей).
7. Ногтевые изменения:
"барабанные палочки" и "часовые стекла": могут наблюдаться при длительной хронической гипоксии, вызванной застойными процессами.
        </p>
    )
}
export default DilCardioKozha