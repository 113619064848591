function MiocarditGm(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При миокардите возможен выраженный отёк головного мозга с уплощением извилин и сужением борозд. 
        Венозный застой, увеличение наполненности сосудов кровью. Возможны точечные кровоизлияния в мозговом веществе или под оболочками, а также
        очаги инфарктов в результате тромбоэмболии.
Зона инфаркта обычно соответствует бассейну поражённой артерии, часто имеет неправильные очертания. Размеры зависят от диаметра окклюзированного сосуда и обширности поражения 
(маленькие лакунарные инфаркты или крупные ишемические зоны). Часто вовлекаются участки серого и белого вещества.
Свежий инфаркт: участок выглядит бледным, сероватым или светло-жёлтым, плотный, границы инфаркта плохо выражены. 
Позже возможна геморрагическая трансформация (появление красных или багровых участков из-за вторичных кровоизлияний); по мере прогрессирования некроза ткань становится мягкой, 
кашицеобразной; cо временем демаркационная линия становится более заметной, отделяя зону некроза от окружающей ткани.
Зачастую инфаркты множественные и сопровождаются геморрагической трансформацией из-за нарушения микроциркуляции.
        </p>                
    )
}
export default MiocarditGm