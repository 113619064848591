function SepsisSerdceAorta(){
    return (
        <> 
        <p id="MsoBodyTextIndent" className="green"> При сепсисе в сердце возможно межуточное воспаление (межуточный эксудативный миокардит).
Макроскопические изменения в сердце при этом состоянии обусловлены отеком, сосудистыми нарушениями и накоплением экссудата в межуточной (интерстициальной) ткани:
Сердце может быть увеличено в размерах из-за отека и воспаления.
Масса сердца увеличена, что заметно при взвешивании.
Эпикард может быть тусклым из-за отека и воспаления.
Иногда покрыт тонким слоем серозного или фибринозного экссудата, особенно при сочетанном эпикардите.
Цвет миокарда тускло-красный или бледно-красный. Местами отмечаются синюшные участки, связанные с венозным застоем.
Возможны точечные кровоизлияния (петехии) на поверхности и в разрезе.
Миокард становится дряблым, с выраженной потерей тонуса. На ощупь менее эластичный по сравнению с нормой.
Межуточная ткань пропитана серозным или серозно-гнойным экссудатом.
На разрезе видны множественные точечные или мелкие кровоизлияния, связанные с повреждением капилляров.
Утолщение межуточных перегородок за счет воспалительного инфильтрата и отека.
Расширение мелких сосудов, вен и капилляров. В некоторых случаях видны мелкие тромбы в сосудах миокарда, характерные для ДВС-синдрома, сопровождающего сепсис.
На разрезе миокард матовый, часто видны отечные участки с неравномерной окраской. При надавливании может выделяться небольшое количество мутной жидкости (экссудат).
Полости сердца могут быть расширены, особенно в результате нарушения насосной функции из-за миокардита.
Клапанные структуры обычно не вовлечены, но могут иметь признаки эндокардита, если процесс генерализован.
При сепсисе миокардит часто сопровождается жировой дистрофией кардиомиоцитов, что макроскопически проявляется желтоватыми участками на разрезе миокарда.
В запущенных случаях возможны очаги некроза в ткани миокарда.
        </p> 
        <p id="MsoBodyTextIndent" className="green"> Также при сепсисе возможно поражение клапанов сердца - септический бактериальный эндокардит (СБЭ).
Макроскопические изменения при СБЭ зависят от стадии процесса и его выраженности. Сердце может быть увеличено в объеме, особенно при сочетании с миокардитом и кардиомегалией, вызванной сопутствующими изменениями.
Масса увеличена из-за отека и воспалительных изменений.
Наиболее поражаемые клапаны: митральный и аортальный клапаны; у наркозависимых: трикуспидальный клапан.
Вегетации - основной диагностический признак. Они представляют собой рыхлые, ворсинчатые образования серого, желтовато-зеленого или грязно-коричневого цвета.
Размеры варьируются от мелких точечных до крупных узловидных структур.
Локализуются на краях клапанных створок, иногда распространяются на пристеночный эндокард, а при поражении аортальных клапанов распространяются на интиму аорты.
Поверхность клапанов может быть разрушена, с неровными краями и участками некроза (эрозии и изъязвления). В запущенных случаях наблюдаются разрывы створок клапанов. Иногда встречаются обызвествления у пациентов с 
хроническим эндокардитом.
Увеличение полостей левого желудочка и предсердия при недостаточности митрального или аортального клапанов.
Расширение полостей правого предсердия и желудочка при поражении трикуспидального клапана.
В полостях могут быть обнаружены пристеночные тромбы.
Часто отмечаются изменения, связанные с сопутствующим миокардитом:
Миокард дряблый, тускло-красного цвета. Видны множественные мелкие кровоизлияния.
Возможны микроабсцессы или зоны некроза в миокарде в результате гематогенного заноса инфекции.
Петехиальные кровоизлияния могут быть видны на эндокарде, особенно в области предсердий и клапанов.
При поражении левого сердца: могут выявляться множественные септические эмболы в системном кровотоке (легкие, почки, селезенка, мозг).
При поражении правого сердца: эмболы в легочной артерии.
Пристеночный эндокард может быть тусклым, с кровоизлияниями, некрозами и мелкими вегетациями.
Особенности вегетаций: состоят из скоплений фибрина, лейкоцитов, бактерий и некротических тканей; легко крошатся при пальпации, что объясняет склонность к эмболии; могут полностью покрывать поверхность клапанов, 
нарушая их функцию.
        </p> 
        </>               
    )
}
export default SepsisSerdceAorta