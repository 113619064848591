function MiocarditSpleen(){
    return (
        <p id="MsoBodyTextIndent" className="green">При миокардите возможна картина застойной селезенки: она увеличена, полнокровна, на разрезе имеет 
        темно-вишневый цвет, с увеличением пульпы. Также возможен инфаркт селезёнки, возникающий в результате тромбоэмболии. Он имеет клиновидную форму, с 
        основанием, направленным к капсуле селезёнки, и вершиной, обращённой к воротам селезёнки, где локализуется окклюзия сосуда. Границы чётко очерчены, особенно если вокруг 
        инфаркта развивается реактивное воспаление.
Свежий инфаркт: участок бледного, серовато-жёлтого или беловатого цвета, плотный и ломкий из-за ишемии и некроза ткани; по периферии инфаркта может наблюдаться узкая красноватая 
полоса из-за реактивного кровенаполнения (геморрагическая зона); поверхность инфаркта может быть гладкой или незначительно выпуклой.
Поздняя стадия: инфаркт приобретает тёмно-коричневый или сероватый оттенок, становится ещё плотнее  из-за разрушения клеток крови и развития фиброза; зона инфаркта обычно слегка 
втянута в поздних стадиях (из-за рубцевания).
Инфаркт может быть небольшим или затрагивать значительные участки селезёнки, в зависимости от калибра окклюзированного сосуда.
Капсула селезёнки над зоной инфаркта может быть утолщена и покрыта фибринозным налётом (фибринозный периспленит).
При массивных инфарктах возможны вторичные изменения, такие как асептический абсцесс или организация некротических масс.
        </p>
    )
}
export default MiocarditSpleen