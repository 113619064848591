function HibsPechen(){
    return (
        <p id="MsoBodyTextIndent" className="green">При  хронической ишемической болезни сердца (ХИБС) возможно обнаружить следующие изменения в печени:
        -	Жировой гепатоз (печень увеличена, дряблая, на разрезе желтоватая, жирная на ощупь) - "гусинная печень".
        -	Хронический венозный (застойный, конгестивный) гепатоз (печень увеличена, капсула натянута, чередование темно-красных (застойные зоны в центре долек) и желтоватых 
        участков (жировая дистрофия гепатоцитов на периферии долек) из-за застоя крови вследствии хронической сердечной недостаточности) - "мускатная печень".
	    -   Фиброз (в случае длительного застоя возможно развитие кардиального цирроза печени (формирование соединительной ткани вокруг центральных вен)).
        </p>
    )
}
export default HibsPechen