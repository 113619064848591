function SepsisPechen(){
    return (
        <p id="MsoBodyTextIndent" className="green">При сепсисе возможно обнаружить следующие изменения в печени:
        увеличение размеров (гепатомегалия), застой крови, дистрофия гепатоцитов, жировая инфильтрация, желтушность, связанная с гемолизом или нарушением функции печени.
Абсцессы печени, возникающие при сепсисе (септикопиемии), являются следствием гематогенного распространения инфекции или проникновения микроорганизмов из первичного очага воспаления. Они представляют собой гнойные 
очаги в тканях органа, различающиеся по размеру, количеству и стадии развития.
1. Основные макроскопические характеристики абсцессов печени при септикопиемии:
1.1. Размер абсцессов:
Мелкие абсцессы (микроабсцессы): Диаметр до 1–2 мм, часто множественные.
Крупные абсцессы: Диаметр от 1 см и более, одиночные или множественные.
Абсцессы могут сливаться, образуя более обширные очаги.
1.2. Количество:
Абсцессы чаще множественные при сепсисе, так как инфекция распространяется через кровоток.
Возможно единичное поражение при локализованной инфекции.
1.3. Локализация:
Располагаются преимущественно в периферических отделах печени или вблизи ворот органа.
Вены портальной системы и центральные зоны долек могут быть вовлечены.
1.4. Форма:
Абсцессы округлые или неправильной формы.
Контуры четкие, в стадии формирования могут быть нечеткими.
2. Макроскопическое строение абсцессов:
2.1. Центр абсцесса:
Наполнен гнойным содержимым.
Цвет: Желтовато-зеленоватый или грязно-серый.
Консистенция: Кремообразная или вязкая, иногда с примесью крови.
2.2. Капсула абсцесса:
При хроническом течении образуется плотная соединительнотканная капсула, отделяющая очаг некроза от здоровой ткани.
При остром процессе капсула отсутствует или слабо выражена.
2.3. Окружающая ткань:
Печеночная ткань вокруг абсцесса гиперемирована, уплотнена.
Нередко видны участки некроза, кровоизлияния и отека.
3. Связанные изменения печени:
3.1. Гнойно-септические изменения:
Наличие тромбов в венах печени (септический тромбофлебит).
Некрозы в окружающей ткани.
3.2. Общие признаки воспаления:
Печень увеличена в размерах.
Капсула напряжена, поверхность может быть бугристой (при множественных крупных абсцессах).
3.3. Дистрофические изменения:
Жировая или белковая дистрофия гепатоцитов в участках, отдаленных от абсцессов.
4. Разрез печени:
На разрезе абсцессы видны как участки гнойного расплавления в тканях, окруженные зоной воспалительной инфильтрации.
При надавливании выделяется гной, иногда с запахом, зависящим от возбудителя (например, неприятный гнилостный запах при анаэробной инфекции).
5. Возможные осложнения:
Прорыв абсцесса: В брюшную полость (с развитием перитонита), плевральную полость или в соседние органы.
Септический тромбоз: В сосудах печени или портальной системы.
Образование множественных мелких очагов (микроабсцессов): Часто свидетельствует о системной инфекции и тяжелом септическом процессе.
        </p>
    )
}
export default SepsisPechen