import { useSelector } from 'react-redux';

//Импорт компонентов пат изменений:
import VichSpleen from '../../patan/vich/VichSpleen';
import GepatBSpleen from '../../patan/gepatB/GepatBSpleen';
import GepatCSpleen from '../../patan/gepatС/GepatCSpleen';
import SifilisSpleen from '../../patan/sifilis/SifilisSpleen';
import GrippSpleen from '../../patan/gripp/GrippSpleen';
import TubercSpleen from '../../patan/tuberc/TubercSpleen';
import NjakSpleen from '../../patan/njak/NjakSpleen';
import OglomeruloSpleen from '../../patan/oglomerulo/OglomeruloSpleen';
import FeAnemiSpleen from '../../patan/feAnemi/FeAnemiSpleen';
import PostgemAnemiSpleen from '../../patan/postgemAnemi/PostgemAnemiSpleen';
import IshInsultSpleen from '../../patan/ishInsult/IshInsultSpleen';
import GemInsultSpleen from '../../patan/gemInsult/GemInsultSpleen';
import InfarctMiocSpleen from '../../patan/infarctMioc/InfarctMiocSpleen';
import AlcoSpleen from '../../patan/alco/AlcoSpleen';
import DilCardioSpleen from '../../patan/dilCardio/DilCardioSpleen';
import GipCardioSpleen from '../../patan/gipCardio/GipCardioSpleen';
import MiocarditSpleen from '../../patan/miocardit/MiocarditSpleen';
import SepsisSpleen from '../../patan/sepsis/SepsisSpleen';
import HibsSpleen from '../../patan/hibs/HibsSpleen';

function PatanSpleen() {

    //Получаем из хранилища состояния пат изменений:
    const vich = useSelector((state) => state.pat.vich);
    const gepatB = useSelector((state) => state.pat.gepatB);
    const gepatC = useSelector((state) => state.pat.gepatC);
    const sifilis = useSelector((state) => state.pat.sifilis);
    const gripp = useSelector((state) => state.pat.gripp);
    const tuberc = useSelector((state) => state.pat.tuberc);
    const njak = useSelector((state) => state.pat.njak);
    const oglomerulo = useSelector((state) => state.pat.oglomerulo);
    const feAnemi = useSelector((state) => state.pat.feAnemi);
    const postgemAnemi = useSelector((state) => state.pat.postgemAnemi);
    const ishInsult = useSelector((state) => state.pat.ishInsult);
    const gemInsult = useSelector((state) => state.pat.gemInsult);
    const infarctMioc = useSelector((state) => state.pat.infarctMioc);
    const alco = useSelector((state) => state.pat.alco);
    const dilCardio = useSelector((state) => state.pat.dilCardio);
    const gipCardio = useSelector((state) => state.pat.gipCardio);
    const miocardit = useSelector((state) => state.pat.miocardit);
    const sepsis = useSelector((state) => state.pat.sepsis);
    const hibs = useSelector((state) => state.pat.hibs);
    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);
    
    return (
        <>
            {/* Туберкулез: */}
            {tuberc && <><TubercSpleen/>
            <br></br></>}
            {/* Сифилис: */}
            {sifilis && <><SifilisSpleen/>
            <br></br></>}
             {/* Гепатит B: */}
             {gepatB && <><GepatBSpleen/>
            <br></br></>}
            {/* Гепатит C: */}
            {gepatC && <><GepatCSpleen/>
            <br></br></>}
            {/* ВИЧ: */}
            {vich && <><VichSpleen/>
            <br></br></>}   
            {/* Грипп: */}
            {gripp && <><GrippSpleen/>
            <br></br></>}
            {/* Железодеф анемия: */}
            {feAnemi && <><FeAnemiSpleen/>
            <br></br></>} 
            {/* Острая постгеморрагическая анемия: */}
            {postgemAnemi && <><PostgemAnemiSpleen/>
            <br></br></>}
             {/* Хронический алкоголизм: */}
             {alco && <><AlcoSpleen/>
            <br></br></>}
            {/* Инфаркт миокарда: */}
            {infarctMioc && <><InfarctMiocSpleen/>
            <br></br></>}
            {/* ХИБС: */}
            {hibs && <><HibsSpleen/>
            <br></br></>}
             {/* Сепсис: */}
             {sepsis && <><SepsisSpleen/>
            <br></br></>}
            {/* Миокардит: */}
            {miocardit && <><MiocarditSpleen/>
            <br></br></>}
            {/* Дилатационная кардиомиопатия: */}
            {dilCardio && <><DilCardioSpleen/>
            <br></br></>}
            {/* Гипертрофическая кардиомиопатия: */}
            {gipCardio && <><GipCardioSpleen/>
            <br></br></>}
            {/* Геморрагический инсульт: */}
            {gemInsult && <><GemInsultSpleen/>
            <br></br></>}
            {/* Ишемический инсульт: */}
            {ishInsult && <><IshInsultSpleen/>
            <br></br></>} 
            {/* НЯК: */}
            {njak && <><NjakSpleen/>
            <br></br></>}
            {/* О. гломерулонефрит: */}
            {oglomerulo && <><OglomeruloSpleen/>
            <br></br></>}
            {/* Ссадины: */}
            {/* {ssadiny  && <><SsadinyDiagnoz/>
            <br></br></>} */}
        </>
    );
}

export default PatanSpleen;