function GepatСPochki(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При вирусном гепатите С в почках иногда наблюдаются изменения, связанные с хроническим воспалением, такие как 
        гломерулонефрит. При остром гломерулонефрите происходит
        увеличение почек (до 12–15 см в длину) из-за воспалительного процесса и отека, 
        поверхность почек бледная, иногда с серовато-желтоватым оттенком из-за отека коркового вещества, с множественными мелкими точечными или пятнистыми кровоизлияниями (так 
        называемые «пестрые почки»), капсула снимается с трудом из-за отека ткани, обнажая гладкую, блестящую поверхность; на разрезе: корковое вещество утолщено, отечное, 
        серовато-белое или бледно-красное с участками гиперемии, а мозговое вещество темно-красное, контрастирующее с корковым (границы между корковым и мозговым веществом четкие), 
        могут наблюдаться кровоизлияния в корковом веществе и на поверхности почек. При подстром гломерулонефрите почки так же увеличены в размерах, с поверхности и на разрезе 
        бледно-серые - "большая белая почка", в случае с гематурией - почка может быть пестрого вида (как при остром гломерулонефрите). При хроническом гломерулонефрите - на ранних 
        стадиях почки увеличены в размерах, с гладкой бледно-серой поверхностью. В дальнейшем, по мере нарастания нефросклероза, они уменьшаются в размерах, их поверхность становится 
        мелкозернистой; на разрезах корковое вещество истончено и границы его не различаются (вторично сморщенные почки).
        </p>               
    )
}
export default GepatСPochki