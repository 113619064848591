function MkbPochki(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для мочекаменной болезни, характерно:
        камни в чашечно-лоханочной системе — видны конкременты различной формы, размера и состава (уратные, оксалатные, фосфатные и др.). 
        Пиелоэктазия из-за механического препятствия оттока мочи — патологическое расширение чашечно-лоханочной системы почки (в зависимости от степени расширения выделяют 
        несколько стадий пиелоэктазии: умеренную, выраженную и тяжёлую), при прогрессировании заболевания стенки почечной лоханки могут утолщаться и 
        становятся менее эластичными, что ухудшает её способность к нормальному оттоку мочи. Если пиелоэктазия сопровождается выраженным расширением почечной лоханки и чашечек, то может развиться гидронефроз -
        почка превращается в тонкостенный, заполненный мочой мешок (происходит деформация и уменьшение функциональной ткани почки). Атрофия паренхимы — истончение почечной ткани вокруг расширенной лоханки. 
        Если камень находится в чашечке, то нарушение оттока из нее ведет к расширению только этой чашечки - гидрокаликозу и атрофии подвергается только часть почечной паренхимы. Рубцовые изменения — свидетельство 
        перенесённых воспалительных процессов (хронический пиелонефрит). При присоединении инфекции к гидронефрозу, он становится пионефрозом —  заполненная гноем полость в почке с разрушением её структуры.
Инфекция ведет к развитию пиелита, пиелонефрита, гнойничкового нефрита, к гнойному расплавлению паренхимы. Если воспаление переходит на околопочечную клетчатку - возможен хронический паранефрит при котором почка 
замурована в толстой капсуле из грануляционной ткани (панцирный паранефрит), а иногда и вовсе замещена склерозированной жировой клетчаткой (жировое замещение почки).
Расширение мочеточника (уретерогидронефроз) — наблюдается выше уровня обструкции (камня), также расширяется и почечная лоханка. 
При этом возникает воспаление стенки мочеточника - уретерит, происходит гипертрофия стенок мочеточника — из-за длительного сопротивления току мочи, все это может приводить к стриктурам.
Может образоваться пролежень с перфорацией мочеточника в месте его закупорки.
При длительном застое мочи — камни могут быть в мочевом пузыре. Гипертрофия детрузора (мышечного слоя) мочевого пузыря возможна из-за увеличения нагрузки при попытках опорожнения при затруднении оттока мочи, также 
возможны дивертикулы (выпячивания) стенки мочевого пузыря из-за повышенного давления. 
У мужчин — часто сопутствующее хроническое воспаление или гиперплазия предстательной железы, что усугубляет нарушение оттока мочи.
Основные типы камней:
1. Уратные камни (мочевая кислота):
Цвет: от светло-жёлтого до красно-коричневого.
Форма: обычно гладкие, округлые или овальные.
Текстура: относительно мягкие, могут иметь шероховатую поверхность.
Размер: варьируются от мелких (менее 1 см) до крупных (более 3 см), часто могут быть кристаллическими.
Плотность: менее плотные по сравнению с другими типами камней.
Признаки: могут быть склонны к образованию в кислой среде мочи, обладают склонностью к образованию "гладких" камней с ярко выраженной внешней поверхностью.
2. Оксалатные камни (оксалат кальция):
Цвет: тёмно-коричневый, черный или серо-белый.
Форма: часто игольчатая, кристаллическая, камни могут быть острыми и угловатыми.
Текстура: очень твёрдые, с шероховатой поверхностью.
Размер: варьируются от мелких песчинок до крупных камней (несколько см в диаметре).
Плотность: очень плотные и тяжёлые.
Признаки: имеют высокую склонность к образованию при избытке оксалатов в моче. Такие камни часто имеют шершавую, "кристаллическую" текстуру, и их трудно разрушить.
3. Фосфатные камни (фосфат кальция, аммоний магниевый фосфат):
Цвет: белый, светло-серый, иногда молочно-белый.
Форма: обычно более гладкие и округлые, но могут быть и угловатыми.
Текстура: твёрдые, с более гладкой поверхностью по сравнению с оксалатными камнями.
Размер: могут быть как мелкими, так и крупными (иногда достигают 4 см и более).
Плотность: средняя плотность.
Признаки: часто встречаются в щелочной среде мочи, могут образовывать камни с "гладкой" или полированной поверхностью.
4. Струвитные камни (аммоний магниевый фосфат):
Цвет: светло-белые или жёлтые.
Форма: часто имеют форму "молочных" или "ветвящихся" образований, могут быть коронообразными (в виде кораллов).
Текстура: хрупкие, с характерной гладкой или слегка шероховатой поверхностью.
Размер: могут быть крупными, часто достигают больших размеров (иногда до 5-7 см), что может привести к образованию коралловидных камней.
Плотность: мягкие и менее плотные, чем оксалатные камни.
Признаки: образуются в связи с инфекциями мочевыводящих путей, при которых происходит изменение pH мочи. Струвитные камни могут быть легко разрушены.
5. Цистиновые камни (цистин):
Цвет: желтоватый или светло-коричневый.
Форма: часто округлые или овальные, могут быть гладкими или слегка шероховатыми.
Текстура: твердые, но ломкие.
Размер: чаще мелкие или среднего размера, но могут быть и крупными.
Плотность: средняя.
Признаки: эти камни образуются из-за дефекта метаболизма цистина, обычно имеют гладкую поверхность и могут образовываться при хронической инфекции.
6. Холестериновые камни:
Цвет: желтоватые или коричневые.
Форма: могут быть округлыми или неправильной формы.
Текстура: мягкие, с восковой или маслянистой текстурой.
Размер: часто крупные, но могут быть и мелкими.
Плотность: низкая.
Признаки: редкие, могут образовываться в результате заболеваний обмена веществ, таких как гиперлипидемия.
        </p>                
    )
}
export default MkbPochki