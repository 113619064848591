function GemInsultLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При геморрагическом инсульте при проведении <u>гистологического исследования</u> можно обнаружить следующие изменения:
        1.	Головной мозг:
        - Кровоизлияние: участки скопления крови в ткани мозга, видимые как скопления эритроцитов вне сосудов. Вокруг гематомы часто наблюдаются разрушенные нейроны и глиальные клетки.
        - Отек ткани мозга: разрыхление структуры мозга за счет пропитывания межклеточного пространства жидкостью. Это сопровождается сдавлением и раздвиганием нервных волокон и клеток.
        - Нейрональная дегенерация: признаки некроза нейронов, включая пикноз (сжатие ядра), кариолизис (растворение ядра) и кариорексис (распад ядра).
        - Глиоз: реактивное разрастание глиальных клеток в ответ на повреждение ткани, которое может проявляться в виде глиальных рубцов вокруг области кровоизлияния.
        - Диапедезные кровоизлияния: мелкие точечные кровоизлияния, возникающие из-за выхода эритроцитов через стенки мелких сосудов без их разрыва.
        2.	Сосуды мозга:
        - Фибриноидный некроз стенок сосудов: дегенерация сосудистых стенок с пропитыванием фибрином, что может быть характерно для гипертонической болезни и предшествовать разрыву сосуда.
        - Периваскулярные отложения плазмы: накопление плазматических компонентов вокруг сосудов, что указывает на повышенную проницаемость сосудистой стенки.
        - Микроаневризмы: небольшие выпячивания сосудистых стенок, предрасполагающие к разрыву при повышенном давлении.
        3.	Изменения в других органах:
        - Сердце: признаки гипертрофии миокарда, часто наблюдаемые при длительно существующей артериальной гипертензии. Гистологически это проявляется увеличением размеров мышечных волокон и их ядра.
        - Почки: признаки гипертонической ангиопатии с утолщением и склерозом стенок артериол, что может свидетельствовать о длительном повышенном артериальном давлении.
        - Легкие: застой крови в капиллярах с возможными кровоизлияниями в альвеолы, особенно в условиях острой сердечной недостаточности.
Эти изменения помогают судить о механизмах развития инсульта и его системных проявлениях, включая реакции со стороны сосудов и мозга. <u>Токсикологическое исследование:</u> исключение 
наличия токсических веществ и препаратов, которые могли бы способствовать развитию инсульта (например, антикоагулянтов или наркотиков). <u>Биохимические анализы крови и других жидкостей:</u> 
        1. Электролитный баланс:
при геморрагическом инсульте часто наблюдаются нарушения в уровне электролитов:
Повышение калия (гиперкалиемия): связано с распадом клеток мозга и выходом калия из клеток в кровь.
Нарушения уровня натрия: гипонатриемия (снижение натрия) может быть следствием стресса или синдрома неадекватной секреции антидиуретического гормона (SIADH), что нередко сопровождает инсульт.
2. Уровень глюкозы:
гипергликемия: повышенный уровень глюкозы может быть реакцией на стресс, вызванный инсультом, либо проявлением скрытого или диагностированного сахарного диабета, который является фактором риска инсульта.
Локальное потребление глюкозы: ткани мозга, подвергшиеся повреждению, интенсивно потребляют глюкозу, что может привести к локальным изменениям её концентрации.
3. Показатели маркеров повреждения тканей:
лактат: повышение уровня лактата в крови и спинномозговой жидкости может указывать на гипоксию тканей, которая развивается в результате геморрагического инсульта.
С-реактивный белок (СРБ): его повышение может свидетельствовать о воспалительной реакции, связанной с повреждением сосудов и тканей мозга.
4. Коагулограмма:
поскольку геморрагический инсульт связан с разрывом сосуда и кровоизлиянием, анализ свертываемости крови может быть полезен:
Повышенная активность фибринолиза или снижение тромбоцитов может указывать на нарушения свертываемости, способствовавшие кровоизлиянию.
5. Исследование спинномозговой жидкости (СМЖ):
наличие крови в СМЖ (смешение с геморрагическим компонентом) подтверждает кровоизлияние в мозг.
Изменения уровня белка и глюкозы в СМЖ могут дополнительно указывать на природу и давность процесса.
6. Маркерные исследования на стрессовые состояния:
кортизол: повышение уровня кортизола может свидетельствовать о гиперактивности гипоталамо-гипофизарно-надпочечниковой системы, связанной с острым стрессом, вызванным инсультом.
        </p>                
    )
}
export default GemInsultLab