function MiocarditVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При жизни имелся миокардит вирусного генеза, хроническое течение: очаговая воспалительная инфильтрация миокарда (лимфоциты, 
        макрофаги); деструкция кардиомиоцитов в отдельных участках; умеренный интерстициальный фиброз, что подтверждается макро- и микро- скопическими изменениями сердца,
        экспрессией воспалительных маркеров (CD3, CD68) при иммуногистохимическом исследовании, выявлением вирусной ДНК (РНК) в миокарде методом ПЦР, отсутствием признаков 
        бактериальной или иной (неинфекционной) природы миокардита при бактериологическом и токсикологическом исследованиях.
        Миокардит — это воспалительное заболевание сердечной мышцы (миокарда), характеризующееся инфильтрацией миокарда воспалительными клетками, повреждением кардиомиоцитов и, в 
        ряде случаев, развитием интерстициального фиброза. Причинами миокардита могут быть инфекции (вирусные, бактериальные, паразитарные, грибковые), аутоиммунные процессы, 
        токсическое воздействие (лекарственные препараты, алкоголь, токсины) или аллергические реакции. Клинические проявления варьируются от бессимптомного течения до тяжелой 
        сердечной недостаточности, аритмий и внезапной сердечной смерти. Диагностика включает клинические данные, инструментальные методы (ЭКГ, эхокардиография, МРТ сердца), 
        лабораторные маркеры (тропонины, СРБ) и подтверждается гистологическим или иммуногистохимическим исследованием биоптатов миокарда.
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.</p>
    )
}
export default MiocarditVyvody