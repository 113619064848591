function HoshimotoLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для аутоиммунного тиреоидита с гипотиреозом при <u>гистологическом исследовании:</u> 
        1. Щитовидная железа: - лимфоцитарная инфильтрация (в строме щитовидной железы наблюдаются плотные инфильтраты из лимфоцитов, плазматических клеток и макрофагов, иногда 
        формируются лимфоидные фолликулы с герминативными центрами); - деструкция тиреоцитов (фолликулы железы частично разрушены, в некоторых местах наблюдаются признаки некроза 
        тиреоцитов, на месте разрушенных фолликулов могут образовываться фиброзные участки); - гиперплазия и метаплазия тиреоцитов (некоторые фолликулы демонстрируют гиперплазию 
        оставшихся тиреоцитов, возможно развитие оксифильной метаплазии (тиреоциты превращаются в клетки Гюртле, характеризующиеся эозинофильной цитоплазмой и увеличенными 
        митохондриями)); - фиброз (значительное увеличение стромы с замещением тиреоидной ткани фиброзной, фиброз может быть диффузным или очаговым); - в сохранившихся фолликулах 
        отмечается уменьшение количества коллоида, либо он имеет более вязкую консистенцию с характерной зернистостью, "замороженный" коллоид (это плотный, иногда вакуолизированный 
        коллоид, который формируется при нарушении обменных процессов в фолликулах) может наблюдаться, но встречается реже и обычно на фоне менее выраженных деструктивных 
        изменений; - сосудистые изменения (утолщение стенок сосудов вследствие хронического воспаления, иногда наблюдается умеренный периваскулярный фиброз).
2. Миокард: возможна жировая дистрофия кардиомиоцитов, межуточный отек, очаговые отложения мукополисахаридов в интерстициальной ткани, разрежение поперечной исчерченности кардиомиоцитов.
3. Перикард: возможно наличие серозного выпота, признаки хронического серозно-фибринозного перикардита.
4. Печень: возможна жировая дистрофия гепатоцитов (макро- и микровезикулярного типа), уплощение синусоидов из-за отека, скопление мукополисахаридов в межуточной ткани.
5. Почки: возможен отек интерстициальной ткани, утолщение базальной мембраны капилляров, очаговые изменения клубочков (возможна гипертрофия мезангиальных клеток).
6. Кожа и подкожная жировая клетчатка: возможен межуточный отек, накопление мукополисахаридов (гликозаминогликанов) в дерме, утолщение коллагеновых волокон, уменьшение количества 
эластических волокон.
7. Легкие: возможен отек интерстициальной ткани, скопление серозного транссудата в альвеолах.
8. Головной мозг: возможны отек нейроглии, периваскулярное накопление жидкости, уплотнение стенок капилляров.
9. Гипофиз: возможно умножение клеток, синтезирующих ТТГ (базофильных эндокриноцитов - тиреотропоцитов) в передней доли гипофиза, гипертрофия самих этих клеток (тиреотропоцитов) и 
их ядер, которые могут быть гиперхромными, увеличение кровоснабжения передней доли гипофиза как реакция на усиленную работу тиреотропоцитов, капилляры могут быть расширены, 
иногда наблюдаются признаки их активной проницаемости. <u>Биохимическое исследование:</u> анализ посмертной крови на уровни: ТТГ (тиреотропного гормона) — повышенный; Т4 — 
низкий. <u>Cерологическое исследование:</u> определение уровня антител к тиреоглобулину (anti-Tg) и тиреоидной пероксидазе (anti-TPO).
Нормальные значения anti-Tg: Обычно до 115 МЕ/мл (в зависимости от лаборатории). При аутоиммунном тиреоидите показатели могут превышать этот уровень в несколько раз.
Нормальные значения anti-TPO: Обычно до 34 МЕ/мл (в зависимости от лаборатории). При аутоиммунном тиреоидите с гипотиреозом уровень anti-TPO может быть повышен в десятки или сотни 
раз. <u>Иммуногистохимическое исследование</u> (необходимо для подтверждения аутоиммунного характера заболевания): - Выявление маркеров лимфоцитов (CD3, CD20) для оценки степени 
инфильтрации. - Иммунореактивность на тиреоглобулин (Tg) и тиреоидную пероксидазу (TPO). - Определение экспрессии маркеров воспаления, таких как интерлейкин-2, интерлейкин-6.
        </p>
    )
}
export default HoshimotoLab