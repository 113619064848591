function PostgemAnemiKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При острой постгеморрагической анемии характерно:
1. Выраженная бледность кожи, ногтевых лож и слизистых оболочек (губы, конъюнктива глаз, ротовая полость имеют выраженно бледный оттенок), что связано с уменьшением объема циркулирующей 
крови и снижением содержания гемоглобина. Иногда кожа приобретает восковидный или мраморный оттенок, особенно при значительной кровопотере.
2. Возможно наличие следов крови вокруг тела, на одежде или на коже, повреждения кожных покровов (раны, язвы, открытые травмы), из которых могло происходить кровотечение (при внешнем кровотечении).
И отсутствие внешних признаков кровотечения - при внутреннем (например, желудочно-кишечном).
3. Гиповолемические изменения:
-	Западение крупных вен, особенно на шее.
-	Слабая наполненность венозной сети конечностей.
-	Холодная, влажная кожа (в случаях острого гиповолемического шока перед смертью).
4. Признаки гипоксии:
-	Цианоз (синюшность) дистальных отделов тела (губы, уши, пальцы), если анемия сопровождалась сердечно-сосудистой недостаточностью.
-	Возможно отсутствие выраженного цианоза, так как при острой анемии кислородная транспортная функция крови резко снижена.
5. Признаки сопутствующих состояний:
-	Отёчность лица и конечностей может свидетельствовать о сопутствующей сердечно-сосудистой недостаточности.
-	Синяки или гематомы на теле при коагулопатии (например, если кровотечение связано с нарушением свёртываемости крови).
6. Возможные следы медицинских манипуляций:
-	Следы инъекций, катетеров, интубации или других реанимационных мероприятий, если смерть наступила в медицинском учреждении.
7. Состояние глаз:
-	Бледность конъюнктивы.
-	Иногда выявляется мидриаз (расширение зрачков), если смерть наступила вследствие шока.
8. Общее истощение или признаки обезвоживания (если анемия сочеталась с хроническими заболеваниями или массивным кровотечением):
- Значительное уменьшение подкожно-жировой клетчатки, особенно в областях, где она обычно развита (живот, бёдра, плечи).
- Видимое выступание костей (ребра, ключицы, подвздошные кости, лопатки).
- Сухость кожи (она выглядит тусклой, тонкой, сухой, дряблой, лишённой упругости и эластичности, шероховатой на ощупь, может приобретать сероватый оттенок или выглядеть 
цианотичной или мраморной), возможно появление трещин и шелушений.
- Мускульная атрофия (резкое истончение мышц, особенно на конечностях, возможно заметное уменьшение объёма мускулатуры в области плеч и бёдер).
- Изменения на лице: лицо становится «осунувшимся», подчёркиваются костные структуры (скулы, орбиты), затонувшие глаза, заострённые черты лица.
- Возможна тусклость волос и ломкость ногтей.
- Сухость слизистых оболочек (слизистая оболочка рта сухая, губы потрескавшиеся, язык может быть покрыт налётом, с ярко выраженными трещинами.
- Вены на конечностях могут быть обеднёнными и просвечивать через истончённую кожу.
Эти особенности зависят от объёма и скорости кровопотери, а также от времени, прошедшего между началом кровотечения и смертью.
        </p>
    )
}
export default PostgemAnemiKozha