function HronPieloLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для хронического пиелонефрита при <u>гистологическом исследовании</u> можно обнаружить следующие характерные изменения в почках:
1.	Клубочки:
-	Частичная или полная склерозированность клубочков (гломерулосклероз).
-	Уменьшение числа функционирующих клубочков, их замещение соединительной тканью.
-	В оставшихся клубочках возможны признаки гипертрофии.
2.	Канальцы:
-	Атрофия канальцев, особенно в корковом веществе.
-	Расширение просвета канальцев, заполненных белковыми цилиндрами или детритом.
-	Признаки дистрофии эпителия канальцев (вакуолизация, пикноз ядер, деструкция клеток).
-	Очаги регенерации эпителия, часто неравномерные.
3.	Интерстициальная ткань:
-	Диффузный или очаговый фиброз интерстиция.
-	Лимфоцитарная инфильтрация, возможно наличие плазматических клеток и макрофагов.
-	Вокруг очагов воспаления нередко выявляются участки некроза.
4.	Сосуды:
-	Утолщение стенок артериол из-за фибриноидного некроза и гиалиноза, часто связанное с сопутствующей гипертензией.
-	Сужение просвета сосудов и ишемические изменения в окружающих тканях.
5.	Чашечно-лоханочная система:
-	Эпителий лоханок и чашечек местами утолщён, со структурными изменениями или десквамацией.
-	Фиброз и склероз подэпителиального слоя. <u>Бактериологическое исследование:</u> выделение микрофлоры из чашечно-лоханочной системы почек или мочевого пузыря. Определение 
возможных возбудителей, характерных для пиелонефрита (например, Escherichia coli, Proteus mirabilis, Klebsiella spp.). <u>Молекулярно-генетическое исследование (ПЦР):</u> для 
выявления возбудителей инфекций.
        </p>
    )
}
export default HronPieloLab