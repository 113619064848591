function DilCardioSerdceAorta(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для дилатационной кардиомиопатии характерно:
увеличение размеров и массы сердца (кардиомегалия), масса превышает 500–600 г. и может достигать 1 кг. Верхушка сердца сглажена, поперечный размер сердца приближается к 
продольному, из-за чего оно имеет шарообразную форму. Значительное расширение (дилатация) всех четырех камер сердца. Возможно наличие жидкости в полости перикарда (гидроперикард).
Миокард на разрезе дряблый, серо-красного или бурого оттенка. Внутренняя поверхность сердца выглядит утолщенной, матовой, с белесоватым или сероватым оттенком из-за отложения 
соединительной ткани (фиброэластоз пристеночного эндокарда). Возможна относительная недостаточность митрального и трикуспидального клапанов из-за растяжения фиброзных колец.
В половине наблюдений имеются тромботические наложения (пристеночные тромбы), особенно в области ушка левого предсердия или на стенках желудочков.
Стенки сердца могут казаться истонченными из-за растяжения полостей, но иногда сохраняется относительная толщина стенки.
При раздельном взвешивание частей сердца (РВЧС) можно обнаружить следующие отклонения:
1. Значительное увеличение массы левого желудочка (ЛЖ) из-за дилатации и компенсаторной гипертрофии.
Несмотря на увеличение массы, толщина стенки ЛЖ может быть умеренно истончена (менее 10 мм) или в пределах нормы (10–12 мм), что указывает на растяжение и истощение миокарда.
2. Увеличение массы правого желудочка (ПЖ), особенно на поздних стадиях, когда дилатация затрагивает правые отделы сердца.
Масса ПЖ может быть выше нормы, хотя его стенка обычно остается тонкой (норма – около 3 мм).
3. Увеличение массы обоих предсердий, особенно левого, из-за хронического перегрузки давлением и объемом.
4. Соотношение масс отделов сердца:
Нарушение нормального соотношения масс между ЛЖ и ПЖ.
В норме масса ЛЖ примерно в 2–3 раза больше массы ПЖ (соотношение 2:1 или 3:1).
При дилатационной кардиомиопатии это соотношение может снизиться до 1,5:1 или даже 1:1 из-за выраженной дилатации ПЖ.
5. Индекс левого желудочка (ИЛЖ) (процентное отношение веса левого желудочка к чистому весу сердца):
Масса ЛЖ относительно чистого веса сердца может быть снижена из-за прогрессирующей дилатации и истончения стенки.
6. Индекс правого желудочка (ИПЖ) (процентное отношение веса правого желудочка к чистому весу сердца):
Масса ПЖ относительно чистого веса сердца может увеличиваться из-за перегрузки объемом.
7. Индекс миокардиально-ростовой (ИМР) (отношение чистого веса сердца к росту; единица измерения — г/см с округлением до сотых долей):
Увеличение индекса из-за кардиомегалии.
8. Желудочковый индекс (ЖИ) (отношение веса правого желудочка к весу левого, измеряемое безразмерным, неименованным числом с точностью до сотых долей единицы):
Рост индекса из-за относительного увеличения массы правого желудочка.
9. Индекс Фултона (ИФ) (отношение суммы весов свободной стенки левого желудочка и межжелудочковой перегородки к весу свободной стенки правого желудочка, измеряется 
неименованными числами с десятыми долями):
Снижение индекса из-за прогрессирующей дилатации и гипертрофии правого желудочка.
10. Индекс кровоснабжения миокарда (ИКМ) (отношение чистого веса сердца к суммарной площади просвета венечных артерий):
Увеличение индекса из-за относительного несоответствия массы миокарда и площади просвета венечных артерий (масса сердца значительно увеличивается за счет гипертрофии и дилатации, 
но коронарные артерии не расширяются адекватно для обеспечения гипертрофированного миокарда кислородом).
        </p>                
    )
}
export default DilCardioSerdceAorta