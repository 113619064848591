function PostgemAnemiLab(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Для острой постгеморрагической анемии при <u>гистологическом исследовании</u> обнаруживаются изменения, указывающие на последствия гипоксии и ишемии тканей, вызванных 
            острой потерей крови, а также на попытки организма компенсировать возникший дефицит кислорода и клеточных элементов:
            1. Сердце (миокард):
-	Жировая дистрофия кардиомиоцитов: клетки миокарда имеют зернистую цитоплазму с каплями жира.
-	Очаговая гипоксия и некроз кардиомиоцитов.
-	Отёк интерстициальной ткани и слабая инфильтрация клетками (лимфоциты, макрофаги).
-	При образовании пятен Минакова между эндотелием и миокардом образуется полоса крови. В области пятен наблюдается кровь, содержащая эритроциты, которые могут быть видны в виде 
красных масс, а также фрагменты тромбоцитов и, возможно, лейкоцитов. В зонах кровоизлияний может быть отмечено набухание миокардиальных клеток, признаки отека и сдавления 
капилляров, что указывает на гипоксическое состояние тканей сердца. 
2. Лёгкие:
-	Отёк альвеолярных перегородок: утолщение стенок альвеол за счёт накопления белкового экссудата.
-	Склонность к микротромбозам в капиллярах альвеол.
-	В просвете альвеол может наблюдаться пенистая жидкость, свидетельствующая о развитии отёка.
3. Печень:
-	Острая жировая дистрофия: гепатоциты содержат вакуоли жира, преимущественно в периферических зонах печёночных долек.
-	Центролобулярный некроз: гепатоциты центральной зоны долек подвергаются гипоксическому повреждению.
-	Венозный застой в центральных венах.
4. Почки:
-	Шоковая почка: признаки острого тубулярного некроза (гибель эпителиальных клеток проксимальных канальцев).
-	Дистрофия эпителия канальцев: клетки увеличены, вакуолизированы, ядра пикнотичные или отсутствуют.
-	Отёк интерстициальной ткани, капиллярный стаз в клубочках.
5. Селезёнка:
-	Истощение кровяных депо: количество эритроцитов в синусах резко снижено.
-	Трабекулы и пульпа истончены, в красной пульпе наблюдается застой крови.
6. Костный мозг:
-	Активная гиперплазия красного костного мозга: увеличение количества эритроидных предшественников как компенсаторная реакция на кровопотерю.
-	В некоторых случаях может отмечаться нарушение соотношения клеточных элементов (миелоидно-эритроидное соотношение сдвинуто в пользу эритроидного ростка).
7. Головной мозг:
-	Отёк нейроглии и периваскулярные отёчные пространства.
-	Микрогеморрагии в мягкой мозговой оболочке и периваскулярной ткани.
-	Дистрофические изменения нейронов: гиперхроматоз или пикноз ядер, исчезновение ядрышек. <u>Общий анализ крови:</u> гемоглобин (значительное снижение уровня), эритроциты 
(уменьшение количества), гематокрит (снижение гематокритного числа). <u>Биохимическое исследование крови (постмортальная диагностика):</u> повышение уровня свободного гемоглобина 
и снижение кислородной ёмкости крови. <u>Оценка приблизительного объема кровопотери (метод взвешивания или метод определения остаточной крови в организме):</u> 
1. Подготовительный этап:
Перед вскрытием измеряется масса трупа в целом.
Во время вскрытия тщательно собирается вся кровь, обнаруженная в полостях тела (плевральной, брюшной, перикардиальной и других), а также в крупных сосудах и сердце.
2. Сбор крови:
Жидкая кровь собирается в мерные емкости.
Сгустки крови также извлекаются и взвешиваются после удаления лишней жидкости (высушивания фильтровальной бумагой).
3. Оценка кровенаполнения тканей:
Извлекаются и взвешиваются крупные органы (печень, легкие, селезенка, почки и др.).
Затем органы подвергаются промыванию или центрифугированию для удаления крови из их сосудов.
Разница в массе органов до и после удаления крови используется для оценки объема оставшейся крови в них.
4. Подсчет общего объема крови:
Подсчитывается объем собранной жидкой крови и кровь, содержащаяся в сгустках.
Прибавляется кровь, оставшаяся в тканях и органах.
На основе средней физиологической нормы (около 7–8% массы тела для взрослого человека) определяется исходный объем циркулирующей крови (ОЦК).
5. Определение дефицита крови:
Выявляется разница между расчетным нормальным ОЦК и объемом крови, обнаруженной в организме.
Эту разницу интерпретируют как потерянный объем крови.
Пример расчета:
Средний ОЦК человека массой 70 кг:
70 х 0.07 = 4.9 л.
Обнаружено:
Жидкая кровь в полостях: 300 мл.
Кровь из тканей и органов: 800 мл.
300 + 800 = 1.1 л.
Оценка кровопотери:
Потерянный объем крови = Нормальный ОЦК – Обнаруженный объем.
4.9 л - 1.1 л = 3.8 л.
Таким образом, пациент потерял около 3.8 литров крови, что соответствует тяжелой массивной кровопотере.
        </p>
    )
}
export default PostgemAnemiLab