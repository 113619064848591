function AlcoPechen(){
    return (
        <p id="MsoBodyTextIndent" className="green">При хроническом алкоголизме возможно обнаружить следующие изменения в печени:
        -	Жировой гепатоз (печень увеличена, дряблая, на разрезе желтоватая, жирная на ощупь) - "гусинная печень".
        -	Хронический венозный (застойный, конгестивный) гепатоз (печень увеличена, капсула натянута, чередование темно-красных (застойные зоны в центре долек) и желтоватых 
        участков (жировая дистрофия гепатоцитов на периферии долек) из-за застоя крови вследствии хронической сердечной недостаточности) - "мускатная печень".
        -   Алкогольный гепатит (печень увеличена, плотная, с пестрым видом (желтые участки жировой инфильтрации, красные - зоны воспаления и некроза), возможны кровоизлияния, 
        микроскопически - выраженное воспаление (воспалительный инфильтрат - нейтрофилы), некроз гепатоцитов и начальные признаки фиброза).
        -   Алкогольный цирроз печени (уменьшение размеров печени, сморщенная, плотная консистенция; поверхность мелкобугристая или крупнобугристая; на разрезе ткань плотная, 
            желтовато-коричневого или сероватого цвета).
        </p>
    )
}
export default AlcoPechen