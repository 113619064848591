function IshInsultGm(){
    return (
        <>
        <p id="MsoBodyTextIndent" className="green"> Для ишемического инсульта, характерно: 
        1.	Очаг инфаркта мозга:
-	На разрезе мозга можно обнаружить участок размягчения ткани (инфаркт). В острых стадиях он имеет сероватый или желтоватый оттенок, ткань может быть нечетко отграничена от окружающей.
-	Локализация обычно соответствует зоне кровоснабжения пораженной артерии (например, средней мозговой артерии).
2.	Отек головного мозга:
-	Объем мозга увеличен, извилины сглажены, борозды сужены, размытость границ между белым и серым веществом.
-	Возможно смещение срединных структур (дислокация), что проявляется смещением средней линии, грыжей мозга (например, вклинением височной доли в щель мозжечка или вклинение 
миндалин мозжечка в большое затылочное отверстие).
3.	Геморрагическое пропитывание:
-	При реканализации тромба или эмбола (восстановлении кровотока) возможны кровоизлияния в ткань инфаркта, что проявляется пятнистыми красными участками в зоне некроза.
4.	Сосудистые изменения:
-	Тромбозы или эмболы в магистральных сосудах головного мозга (сонная артерия, внутренняя сонная или мозговые артерии), утолщение стенок сосудов.
-	Атеросклеротические бляшки с изъязвлением в пораженных сосудах.
-	Патологическая извилистость или удлинение мозговых артерий.
5.	Хронические изменения (если больной прожил длительное время после инсульта):
-	Киста на месте инфаркта — заполненное жидкостью образование, окруженное глиозной тканью.
-	Умеренная атрофия прилегающих участков.
</p>
<p id="MsoBodyTextIndent" className="green">
Эволюция очага ишемического инсульта включает этапы от острого некроза и отека до формирования кисты и рубцово-глиозных изменений. Эти изменения макроскопически выражают динамику 
повреждения и адаптации мозга к повреждению:
1. Острая стадия (первые 1–3 дня)
-	Видимые изменения: На первых часах инсульта макроскопические изменения могут быть незаметны. Однако через 12–24 часа ткань мозга в пораженной области становится слегка отечной 
и бледной.
-	Отек мозга: Объем мозга увеличивается, извилины сглаживаются, борозды становятся узкими.
-	Мозговое вещество: На разрезе пораженная зона может иметь слегка размягченную консистенцию.
2. Подострая стадия (4–10 дней)
-	Размягчение тканей: Очаг инфаркта становится более выраженным, ткани теряют плотность и приобретают желтоватый оттенок за счет разложения липидов в некротических клетках.
-	Границы очага: Появляется зона демаркации, окружающая область некроза, вызванная активизацией микроглии и воспалением.
-	Геморрагическое пропитывание: Возможно наличие красноватых участков, если произошла реканализация сосуда и кровоизлияние в зону некроза.
-	Отек: Максимальный пик отека наблюдается на 5–7 день, что может сопровождаться дислокацией мозга.
3. Ранняя стадия восстановления (2–4 недели)
-	Лакунарное (цистическое)  размягчение: Некротизированная ткань начинает рассасываться, очаг размягчения заполняется жидкостью.
-	Формирование кисты: Образуется полость, заполненная светло-желтой жидкостью, четко отграниченная от окружающих тканей.
-	Рубцовые изменения: Вокруг кисты разрастается глиальная ткань (глиоз), что формирует четкую границу между пораженной и здоровой тканью.
4. Поздняя стадия восстановления (месяцы–годы)
-	Киста: Очаг инфаркта полностью замещается кистой, которая сохраняется на протяжении жизни. Размер кисты зависит от первоначальной площади поражения.
-	Атрофия окружающих тканей: Наблюдается уменьшение объема прилегающего мозгового вещества, что связано с его дегенерацией.
-	Глиоз: Вокруг кисты формируется плотная глиозная ткань, свидетельствующая о завершении восстановительных процессов.
</p>
        </>               
    )
}
export default IshInsultGm