function InfarctMiocLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для инфаркта миокарда, характерно: при <u>гистологическом исследовании:</u>       
1. Острый период инфаркта (первые часы – до 1-2 суток):
- Оксифильные полосы (контрактурные полосы) (появляются в саркомерах кардиомиоцитов из-за локальной гиперконцентрации кальция и активации протеаз, выглядят как плотные 
укороченные участки миофибрилл) - начинают формироваться через 30 минут – 2 часа после начала ишемии, хорошо заметны под световым микроскопом через 4–12 часов после начала инфаркта.
-   Гиперэозинофилия цитоплазмы (цитоплазма кардиомиоцитов становится ярко окрашенной (эозинофильной) при окраске гематоксилин-эозином, это связано с коагуляцией белков и утратой 
органелл); некроз кардиомиоцитов (нарушение структуры клеток: исчезновение поперечной исчерченности; ядерные изменения: пикноз, кариорексис или кариолизис, гиперэозинофилия 
цитоплазмы) - через 4–6 часов после начала ишемии.
-	Капиллярные изменения (полнокровие капилляров; мелкие кровоизлияния в межмышечных пространствах) - в первые часы после начала ишемии (до 6-12 часов).
-   Феномен "пустых капилляров" (просветы капилляров кажутся пустыми из-за оттока эритроцитов или их разрушения) - после 12-24 часов ишемии.
-	Начало воспалительной реакции (появление периинфарктального отека, скопление нейтрофилов по периферии зоны некроза) - через 4–12 часов после начала ишемии.
2. Подострый период (3–10 сутки):
-	Граница некроза (активная инфильтрация лейкоцитами, преимущественно нейтрофилами, затем макрофагами; фагоцитоз разрушенных клеток).
-	Начало репаративных процессов (пролиферация фибробластов; появление молодой соединительной ткани по краю инфаркта).
3. Период формирования рубца (2 недели и далее):
-	Замещение некротической ткани (активная васкуляризация в области грануляционной ткани; упорядоченная коллагенизация и уменьшение количества клеточных элементов).
-	Формирование зрелой соединительной ткани (зона инфаркта превращается в плотный фиброзный рубец; полная утрата кардиомиоцитов в области поражения).
Сопутствующие изменения в других органах:
-	Почки: дистрофия эпителия канальцев из-за гипоксии.
-	Легкие: застой крови и микротромбы в сосудах, отек легочной ткани.
-	Печень: венозное полнокровие, центролобулярный некроз. При <u>поляризационной микроскопии</u>: полное исчезновение двулучепреломления; изменение структуры саркомеров, что приводит к изменению ширины темных дисков 
        (анизотропных полос) под поляризационной микроскопией – при некрозе наблюдается отсутствие видимых дисков и полос вследствие полной деструкции саркомеров.
При <u>биохимическом исследовании крови (определение маркеров некроза миокарда)</u> :
        - Повышение тропонина I или T выше референтных значений свидетельствует о некрозе миокарда. Степень повышения коррелирует с размером зоны инфаркта.
- Креатинфосфокиназа (МВ-фракция). Повышение МВ-фракции более 5% от общей креатинфосфокиназы (КФК) свидетельствует о поражении миокарда. Значительное увеличение подтверждает 
диагноз ИМ.
- Лактатдегидрогеназа (ЛДГ-1). Повышение изофермента ЛДГ-1, особенно в соотношении ЛДГ-1/ЛДГ-2 более 1 (инверсия), является признаком инфаркта. Используется, если тропонины 
недоступны.
- Миоглобин. Быстрое повышение миоглобина может быть полезным для ранней диагностики, но он неспецифичен (также повышается при повреждении скелетных мышц). 
При <u>поляризационно-оптическом исследовании:</u> 
        - Окраска тканей хлоридом тетразолия (ТТХ) для выявления зоны некроза: зона некроза не окрашивается (бледная), а здоровая ткань приобретает красный цвет.
        - Реакция с нитросиним тетразолием (НСТ) для выявления зоны некроза: зона некроза светло-сиреневого цвета (светлая и бледная), а здоровая ткань приобретает темно-синий или 
        фиолетовый цвет.
        - Реакция с теллуритом калия (K₂TeO₃) для выявления зоны некроза: зона некроза остается светлой и бледной, а здоровая ткань приобретает тёмно-серый или чёрный цвет.
        </p>                
    )
}
export default InfarctMiocLab