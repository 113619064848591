function NjakLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для НЯК при <u>гистологическом исследовании</u> можно обнаружить следующие характерные изменения:
В толстом кишечнике:
1.	Слизистая оболочка: - эрозии и язвы(поверхностные и глубокие дефекты слизистой оболочки, преимущественно в области толстого кишечника); - воспалительный инфильтрат (массивная 
инфильтрация слизистой и подслизистой оболочек лимфоцитами, плазматическими клетками, нейтрофилами, реже эозинофилами); - криптит и абсцессы крипт (накопление нейтрофилов в 
криптах, образование микроскопических гнойников); - атрофия крипт (уменьшение количества крипт и их деформация); - дистрофия эпителия (вакуольная дистрофия, утрата бокаловидных 
клеток, разрушение эпителиального слоя).
2.	Подслизистая оболочка: - отек и расширение сосудов; - воспалительный инфильтрат (может проникать в мышечный слой); - фиброз в хронической стадии.
3.	Сосуды: - расширение капилляров и венул; - признаки тромбоза в микрососудах в случае тяжелых форм заболевания.
В лимфатических узлах:
1. Гиперплазия лимфоидной ткани (увеличение фолликулов с активной пролиферацией лимфоцитов).
2. Синусовый гистиоцитоз (накопление макрофагов в синусах).
В печени (возможные изменения при осложнениях):
1. Гепатит: воспалительная инфильтрация портальных трактов (ассоциирована с первичным склерозирующим холангитом).
2. Холестаз: накопление желчных пигментов в гепатоцитах и канальцах.
3. Жировая дистрофия: накопление липидов в гепатоцитах.
В прочих органах:
- Селезенка: может наблюдаться гиперплазия лимфоидной ткани или реактивные изменения при системных воспалительных реакциях.
- Кожа: при вторичных поражениях (узловатая эритема) воспалительный инфильтрат и некроз в дерме.
Эти изменения зависят от стадии болезни, интенсивности воспаления и наличия осложнений. <u>Микробиологическое исследование:</u> исключение инфекционной природы воспаления, 
отрицательные результаты посевов или ПЦР-диагностики на кишечные инфекции (например, шигеллы, сальмонеллы, клостридии, амёбы и другие патогены). <u>Иммуногистохимическое 
    исследование:</u> для исключения других воспалительных заболеваний кишечника (например, болезни Крона). Результаты: отрицательные специфические маркеры гранулематозного 
    воспаления (CD68).
        </p>
    )
}
export default NjakLab