function SepsisVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При жизни имелся септический бактериальный эндокардит: изъязвления и вегетации на створках митрального клапана, абсцессы в 
        миокарде и участки рубцового замещения, гнойный межуточный миокардит, что подтверждается макро- и микро- скопическими изменениями сердца, в сочетании с результатами 
        бактериологического исследования (обнаружение Corynebacterium в крови и в вегетациях на створках митрального клапана).
        Септический бактериальный эндокардит — это инфекционное заболевание, характеризующееся воспалением внутренней оболочки сердца (эндокарда), преимущественно с поражением 
        клапанов сердца, вызванное гематогенным заносом микроорганизмов (чаще бактерий) на фоне бактериемии. Заболевание сопровождается формированием вегетаций (скоплений фибрина, 
        тромбоцитов и бактерий) на клапанах, развитием некротических и гнойных процессов в тканях сердца, а также возможным эмболическим поражением внутренних органов и 
        септическими осложнениями. Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default SepsisVyvody