function NjakVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">При жизни имелся неспецифический язвенный колит, хроническое течение, стадия ремиссии (рубцовые изменения слизистой оболочки 
        толстой кишки; умеренная атрофия слизистой оболочки; частичное восстановление структуры крипт), на что указывают макро- и микро- скопические изменения в толстой кишке; 
        отрицательные результаты при микробиологическом исследовании на кишечные инфекции (шигеллы, сальмонеллы, клостридии, амёбы, кампилобактер), отрицательные специфические 
        маркеры гранулематозного воспаления (CD68) при иммуногистохимическом исследовании.
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        Неспецифический язвенный колит (НЯК) — это хроническое воспалительное заболевание толстой кишки аутоиммунной природы, характеризующееся развитием диффузного воспаления 
        преимущественно в слизистой оболочке и подслизистом слое, образованием язв, эрозий и псевдополипов. Клинически проявляется диареей с примесью крови и слизи, абдоминальной 
        болью, анемией и системными проявлениями. Заболевание имеет волнообразное течение с чередованием обострений и ремиссий. Причина возникновения связана с генетической 
        предрасположенностью, нарушением иммунного ответа и влиянием факторов внешней среды.
        </p>
    )
}
export default NjakVyvody