import { useSelector } from 'react-redux';

//Импорт компонентов пат изменений:
import TubercBrushina from '../../patan/tuberc/TubercBrushina';
import KronaBrushina from '../../patan/krona/KronaBrushina';
import HoshimotoBrushina from '../../patan/hoshimoto/HoshimotoBrushina';

function PatanBrushina() {

    //Получаем из хранилища состояния пат изменений:
    
    const tuberc = useSelector((state) => state.pat.tuberc);
    const krona = useSelector((state) => state.pat.krona);
    const hoshimoto = useSelector((state) => state.pat.hoshimoto);
    
    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);
    
    return (
        <>
            {/* Туберкулез: */}
            {tuberc && <><TubercBrushina/>
            <br></br></>}
            {/* Хашимото: */}
            {hoshimoto && <><HoshimotoBrushina/>
            <br></br></>}
            {/* болезнь Крона: */}
            {krona && <><KronaBrushina/>
            <br></br></>}
            {/* Ссадины: */}
            {/* {ssadiny  && <><SsadinyDiagnoz/>
            <br></br></>} */}
        </>
    );
}

export default PatanBrushina;