function MiocarditPodj(){
    return (
        <p id="MsoBodyTextIndent" className="green">При миокардите в результате системных изменений, вызванных воспалением миокарда и осложнениями (например, тромбоэмболией, 
        гипоперфузией или токсическим воздействием), в поджелудочной железе возможны следующие изменения (в некоторых случаях макроскопические изменения могут отсутствовать, 
        особенно если поражение железы ограничивается функциональными нарушениями без явного некроза или ишемии): 
        1. Гиперемия и отёк (из-за усиления кровотока или застой венозной крови в результате сердечной недостаточности, связанной с миокардитом): железа увеличена в объёме, ткань 
        отёчна. На разрезе отмечается полнокровие сосудов, особенно в области головки и тела железы.
        2. Увеличение (при хроническом процессе с развитием фиброза) или снижение (ассоциируется с участками некроза или разжижения ткани) плотности ткани.
        3. Геморрагические изменения (из-за повышения сосудистой проницаемости или разрыва капилляров при системных гемодинамических нарушениях (например, при гипотонии или 
            тромбоэмболии)): участки тёмно-красного или багрового цвета, связанные с кровоизлияниями в ткань железы.
4. Очаги ишемии или некроза (из-за нарушения кровоснабжения в результате тромбоэмболии артерий поджелудочной железы): участки неправильной формы с плохо выражеными границами 
сероватого или жёлтоватого цвета (на ранней стадии), соответствующие зонам ишемического повреждения (может поражать как тело, так и хвост или головку поджелудочной железы). 
По мере прогрессирования некроза формируется чёткая демаркационная зона. Также, при тромбоэмболии, могут наблюдаться участки геморрагического некроза тёмно-красного или бурого 
цвета. Некротизированная ткань становится мягкой и хрупкой. Возможна секвестрация поражённых участков при их разрушении. Возможное вовлечение окружающих тканей: при 
прогрессировании инфаркта возможно вовлечение жировой ткани, окружающей поджелудочную железу, с развитием жирового некроза. Это проявляется появлением беловатых меловых пятен в 
забрюшинной клетчатке. Реактивные изменения: отёк органа, асептическое воспаление, усиление ферментативной активности в окружающих тканях.
Этапы макроскопических изменений инфаркта ПЖ:
- Острый период (первые часы - сутки): участок инфаркта выглядит бледным, гиперемия отсутствует; орган может быть слегка увеличен за счёт отёка.
- Подострый период (2-5 дней): поражённая ткань становится жёлтой или серой с участками геморрагической трансформации; зона некроза окружена реактивной гиперемией.
- Хронический период (через несколько недель): некротическая ткань подвергается лизису и замещается фиброзной тканью; могут формироваться кистозные изменения в местах некроза.
Макроскопически инфаркт поджелудочной железы характеризуется некрозом ткани с изменением цвета, мягкостью и разрушением структуры органа. В зависимости от стадии изменения 
варьируются от бледных, плотных участков до мягких и геморрагически трансформированных зон, которые в хронической стадии замещаются рубцовой тканью или кистами.    
5. Очаги жирового некроза (из-за активации ферментов поджелудочной железы на фоне гипоперфузии или токсического стресса): в окружающей жировой клетчатке могут наблюдаться белёсые 
меловидные пятна (результат ферментативного повреждения).
        </p>
    )
}
export default MiocarditPodj