function OglomeruloPochki(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При остром гломерулонефрите характерно увеличение почек (до 12–15 см в длину) из-за воспалительного процесса и отека, 
        поверхность почек бледная, иногда с серовато-желтоватым оттенком из-за отека коркового вещества, с множественными мелкими точечными или пятнистыми кровоизлияниями (так 
        называемые «пестрые почки»), капсула снимается с трудом из-за отека ткани, обнажая гладкую, блестящую поверхность; на разрезе: корковое вещество утолщено, отечное, 
        серовато-белое или бледно-красное с участками гиперемии, а мозговое вещество темно-красное, контрастирующее с корковым (границы между корковым и мозговым веществом четкие), 
        могут наблюдаться кровоизлияния в корковом веществе и на поверхности почек.
        </p>                
    )
}
export default OglomeruloPochki