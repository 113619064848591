function AlcoSerdceAorta(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При хроническом алкоголизме (алкогольный стаж от 3-4 лет до 10 лет и более при ежедневном приеме от 60 г. до 150 г. в сутки 
        в переводе на чистый этанол) возможно развитие алкогольной кардиомиопатии:
Увеличение массы и размеров сердца (кардиомегалия, масса сердца может быть увеличенной максимум до 600 г. - не за счет истинной гипертрофии миокарда, а вследствие отека, разрастаний соединительной ткани), дилятация 
его полостей (расширение желудочков и предсердий, особенно левого желудочка; переполнение их кровью; пристеночные тромбы в полостях сердца), сердце может выглядеть объемным и дряблым на ощупь (распластанное сердце), 
не контурированный левый желудочек. На начальных стадиях наблюдается гипертрофия миокарда, которая в дальнейшем (когда развивается дилатация сердечных камер и сердечная недостаточность), на фоне длительного 
токсического воздействия этанола, из-за жировой дистрофии, фиброза, атрофии и апоптоза кардиомиоцитов - сменяется истончением миокарда (миокард истончен, на разрезе бледно-серый или желтоватый, тусклый, нередко 
глинистого вида или типа вареного мяса, легко раздавливается между пальцами, отмечается его жировая инфильтрация и участки фиброза, особенно в правом желудочке). Под эпикардом большое скопление жира. Коронарные 
артерии, как правило, без атеросклероза. Если с алкогольной кардиомиопатией связана непосредственная причина смерти, то она должна выступать как самостоятельная нозологическая единица в рубрике основного заболевания. 
Макро- и микро- скопическая картина алкогольной кардиомиопатии неспецифична, поэтому постановка этого диагноза должна быть подтверждена соответствующим анамнезом.
        </p>                
    )
}
export default AlcoSerdceAorta