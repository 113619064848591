function FeAnemiKishki(){
    return (
        <p id="MsoBodyTextIndent" className="green">
       При железодефицитной анемии можно обнаружить следующие изменения жкт, которые могут являться причиной дефицита железа:
       - очаги хронической кровопотери (язвы, опухоли, полипы, кровоточащие эрозии), 
       - атрофический гастрит (атрофическая, бледная, истонченная слизистая оболочка желудка, отсутствие складчатости в области тела и дна желудка, повышенный объем слизи в 
       содержимом желудка), 
       - нарушение всасывания в тонком кишечнике (- целиакия или атрофический энтерит - атрофия ворсинок и сглаживание складок тонкого кишечника, бледность и повышенная прозрачность 
       слизистой оболочки из-за анемии и истощения, слизистая тонкой кишки может выглядеть сглаженной или истонченной, умеренное расширение просвета кишечника (при длительном 
       процессе, связанном с мальабсорбцией), истончение стенки кишечника из-за атрофии слизистой и подслизистого слоя, возможна умеренная гиперемия (при воспалительных процессах); 
       - укорочение тонкого кишечника (например, синдром короткой кишки после резекции))      
</p>
    )
}
export default FeAnemiKishki