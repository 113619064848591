function MkbLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для мочекаменной болезни, характерно: при <u>гистологическом исследовании:</u>  1. Почки:
        -	Хронический пиелонефрит: инфильтрация тканей лимфоцитами, плазматическими клетками и макрофагами; атрофия канальцев: канальцы с уплощённым эпителием, иногда 
        заполненные детритом; склероз и фиброз интерстициальной ткани (замещение нормальной ткани соединительной); гиалиноз клубочков (замещение клубочков гомогенной стекловидной 
        массой); отложения солей (оксалатов, фосфатов) в канальцах или интерстиции.
        -	Гидронефроз: атрофия и деструкция почечных канальцев из-за давления; уплощение эпителия чашечно-лоханочной системы; сдавление сосудов, что вызывает ишемические 
        изменения.
        -	Пионефроз (при гнойном воспалении): очаги гнойного расплавления ткани, заполненные нейтрофилами и клеточным детритом; деструкция эпителия чашечно-лоханочной системы; 
        формирование грануляционной ткани вокруг зон воспаления.
        2. Мочеточники:
        -	Гипертрофия мышечного слоя: утолщение мышечных волокон за счёт их гипертрофии; признаки ишемии при длительном обструктивном процессе.
        -	Хроническое воспаление: инфильтрация слизистой лимфоцитами и макрофагами; фиброз подслизистого слоя; повреждение уротелия с очагами десквамации (отслоения) эпителия.
        3. Мочевой пузырь:
        -	Гипертрофия мышечного слоя (детрузора): увеличение размеров и количества гладкомышечных клеток; очаги ишемического повреждения при длительном повышении внутрипузырного 
        давления.
        -	Хронический цистит: инфильтрация слизистой и подслизистой оболочки лимфоцитами, плазматическими клетками и макрофагами; метаплазия уротелия (переходный эпителий 
        замещается плоским); очаговые изъязвления слизистой при осложнениях.
        4. Общее:
        -	Амилоидоз (в редких случаях): отложения амилоида в клубочках, стенках сосудов и интерстиции почек (при хронических воспалительных процессах); отложение амилоида в 
        стенках мочевых путей.
        -	Признаки уремии: уремический гломерулосклероз; минеральные отложения (гиперкальциемия, связанная с нарушением обмена фосфора и кальция).
        Эти микроскопические изменения связаны с длительным течением заболевания, частыми воспалениями и нарушением уродинамики. <u>Химическое исследование (анализ состава 
            конкрементов):</u> проведение спектрального анализа или поляризационной микроскопии для определения типа камней (оксалаты, 
        ураты, фосфаты и др.); химический анализ мочи, взятой из мочевого пузыря или почечной лоханки, на присутствие солей, белков, продуктов воспаления; анализ биологических 
        материалов (моча, кровь, ткань почек) на предмет содержания токсичных веществ, связанных с уремией или нарушением функции почек. <u>Бактериологическое 
            исследование (изучение содержимого почечной лоханки, мочеточников или мочевого пузыря на наличие патогенной микрофлоры):</u> идентификация 
        возбудителей инфекций (Escherichia coli, Proteus, Klebsiella и др.). <u>Биохимическое исследование (изучение тканей почек, мочевого пузыря и крови):</u> наличие отложений 
        мочевой кислоты или других метаболитов; анализ возможных проявлений хронической почечной недостаточности.
        </p>                
    )
}
export default MkbLab