function MkbKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При мочекаменной болезни возможны:
1. Кожные покровы:
-	Бледность кожи (связана с анемией, развивающейся на фоне хронической почечной недостаточности).
-	Желтушный оттенок кожи: может быть обусловлен уремией или нарушением обмена билирубина.
-	Сухость кожи: часто наблюдается при хронической почечной недостаточности из-за нарушений водно-электролитного баланса.
-	Уремический иней: беловатый налёт на коже и слизистых оболочках, вызванный отложением кристаллов мочевины и мочевой кислоты при уремии.
2. Отёки:
-	Общие отёки: особенно в области лица ("почечное лицо") и нижних конечностей, связаны с нарушением фильтрационной функции почек.
-	Отёчность век: характерна для почечных заболеваний.
3. Общее состояние:
-	Кахексия или истощение: развивается в случаях длительного течения болезни с хронической почечной недостаточностью и интоксикацией.
4. Следы медицинских вмешательств:
-	Шрамы или следы пункций: в области поясницы могут свидетельствовать о проведении хирургического или инструментального лечения (например, нефролитотрипсии или дренирования 
почки).
-	Следы катетеров: в области уретры, если пациент нуждался в обеспечении оттока мочи.
5. Наличие вторичных признаков:
-	Следы хронической боли: возможны изменения осанки или напряжение мышц в поясничной области, связанные с постоянной болью (почечная колика).
-	Следы расчёсов: в области поясницы или живота, возникающие из-за кожного зуда при уремии.
6. Неспецифические признаки интоксикации:
-	Аммиачный запах от тела: характерен для уремической интоксикации.
-	Общая слабость и гипотония мышц: видимые признаки истощения и длительного хронического заболевания.
7. Наличие рубцов или образований:
-	Грыжевые выпячивания: возможно развитие грыж из-за повышенного внутрибрюшного давления при частых приступах почечной колики.
        </p>
    )
}
export default MkbKozha