function MiocarditPechen(){
    return (
        <p id="MsoBodyTextIndent" className="green">При миокардите возможно обнаружить следующие изменения в печени:
        -	Жировой гепатоз (печень увеличена, дряблая, на разрезе желтоватая, жирная на ощупь) - "гусинная печень".
        -	Хронический венозный (застойный, конгестивный) гепатоз (печень увеличена, капсула натянута, чередование темно-красных (застойные зоны в центре долек) и желтоватых 
        участков (жировая дистрофия гепатоцитов на периферии долек) из-за застоя крови вследствии хронической сердечной недостаточности) - "мускатная печень".
Иногда, в результате тромбоэмболии, можно обнаружить инфаркты в печени (обычно имеют клиновидную форму с основанием, обращённым к поверхности печени, и вершиной, направленной к 
воротам, четко очерчены (вокруг инфаркта развивается реактивное воспаление), размер может варьироваться от небольших участков до крупных некрозов. Свежий инфаркт: участок инфаркта 
может быть мягким, серовато-белого или серо-жёлтого цвета из-за некроза ткани. Периферия может быть слегка гиперемирована. Старый инфаркт: из-за организации некротической ткани 
инфаркт становится плотным, постепенно приобретает серовато-коричневый цвет за счёт гемосидероза и фиброзных изменений). В поражённой артерии можно обнаружить тромб. Вокруг 
инфаркта возможны мелкоточечные кровоизлияния из-за рефлекторного перераспределения крови или разрывов мелких сосудов.
        </p>
    )
}
export default MiocarditPechen