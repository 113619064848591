function HronPieloKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При хроническом пиелонефрите возможны: 
1. Изменения, связанные с хронической почечной недостаточностью (ХПН):
-   Бледность кожи, связанная с хронической анемией, развивающейся на фоне хронической почечной недостаточности.
-   Желтоватая окраска кожи (уремическая интоксикация при терминальной стадии хронической почечной недостаточности).
-   Сухость кожи и слизистых оболочек (дегидратация вследствие уремической интоксикации).
-   Умеренные или выраженные отёки лица, век, нижних конечностей (результат нарушенной функции почек по выведению жидкости).
-	Уремический "иней": возможное появление белёсого налёта на коже из-за кристаллов мочевины (характерно для терминальной стадии ХПН).
-	Запах аммиака от тела и изо рта (результат нарушения азотистого обмена).
2. Признаки артериальной гипертензии (нефрогенная гипертензия):
-	Гиперемия лица (в случае декомпенсации гипертензии).
-	Следы кровоизлияний (петехиальные высыпания на коже (при нефрогенной гипертензии и нарушении сосудистой проницаемости), кровоизлияния на слизистых оболочках).
3. Наличие следов от медицинских манипуляций:
-	Следы уколов в области предплечий или других участков кожи (при длительном проведении гемодиализа в условиях хронической почечной недостаточности).
-	Гиперпигментация в области поясницы или других участков кожи (следы длительного применения грелок при попытках облегчить боль).
-   Рубцы в поясничной области (после нефростомии, операций на мочевых путях).
-   Швы в области брюшной стенки (если проводились вмешательства на мочевых органах).
4. Косвенные признаки хронической интоксикации:
-	Истощение: снижение массы тела, истончение подкожной жировой клетчатки (особенно в терминальных стадиях).
-	Изменения волос и ногтей: ломкость, сухость, истончение, признаки дистрофии.
5. Следы перенесённых инфекций мочевых путей (возможное присутствие свищей в области промежности (например, уретровлагалищные или мочеточниковые свищи при хроническом 
пиелонефрите на фоне обструкции).
        </p>
    )
}
export default HronPieloKozha