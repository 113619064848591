function HibsLungs(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При хронической ишемической болезни сердца (ХИБС) из-за хронической сердечной недостаточности в легких возможны следующие 
        изменения:
-	Застойная гиперемия и отек (легкие тяжелые, плотные, увеличены в объеме, багрового цвета, на разрезе выделяется обильная пенистая кровянистая жидкость)
-	"Бурая индурация" (в долгосрочной перспективе возможно развитие бурой индурации легких, связанной с отложением гемосидерина в альвеолярных макрофагах).
Также возможны	гидроторакс и асцит.
        </p>
    )
}
export default HibsLungs