function DilCardioLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для дилатационной кардиомиопатии (ДКМП), характерно: при <u>гистологическом исследовании:</u>  1. Сердце:
        - Гипертрофия и атрофия кардиомиоцитов: одни клетки увеличены в размерах, другие — уменьшены, что отражает дезорганизацию структуры миокарда.
        - Фиброз: диффузное разрастание соединительной ткани (интерстициальный и/или периваскулярный).
        - Дегенерация кардиомиоцитов: жировая, вакуольная или зернистая дистрофия; в тяжелых случаях — некроз отдельных клеток.
        - Интерстициальный отёк: пропитка ткани жидкостью, особенно в случаях декомпенсации.
        - Расширенные, переполненные кровью капилляры (признак венозного застоя).
        2. Лёгкие: признаки отёка лёгких: заполнение альвеол серозным экссудатом, иногда с примесью эритроцитов (геморрагический компонент). Гемосидерофаги ("клетки сердечной 
        недостаточности") в просвете альвеол. Утолщение межальвеолярных перегородок из-за хронического венозного застоя. Признаки фиброза в интрстиции при длительном застое.
        3. Печень:
        - Центролобулярный застой: расширение синусоидов, полнокровие в центральной зоне долек.
        - Некрозы гепатоцитов: очаговые некрозы в центре долек из-за гипоксии.
        - Жировая дистрофия: жировые вакуоли в гепатоцитах, особенно в периферических зонах долек.
        - Фиброз: возможное развитие перицентрального фиброза при длительном хроническом венозном застое.
        4. Селезёнка:
        - Венозный застой: расширение венозных синусов и полнокровие.
        - Фиброз: разрастание соединительной ткани при длительном застое.
        5. Почки:
        - Застойные изменения: полнокровие клубочков, расширение капилляров; застой крови в мозговом слое.
        - Атрофия эпителия канальцев: дистрофические изменения эпителия, иногда признаки некроза;
        - Интерстициальный отёк: скопление жидкости в межуточной ткани.
        6. Головной мозг:
        - Отёк мозга: пропитывание ткани жидкостью, расширение периваскулярных и перивенозных пространств.
        - Застойные явления: полнокровие сосудов, особенно венозных.
        - Гипоксия нейронов: хроматолиз, вакуолизация цитоплазмы, исчезновение ядер у отдельных клеток. <u>Биохимическое исследование:</u> BNP (натрийуретический пептид) или 
        NT-proBNP: повышены при сердечной недостаточности. Тропонин I и T, креатинфосфокиназа (МВ-фракция): исключение ишемической природы повреждения. Снижение уровня АТФ и 
        креатинфосфокиназы в тканях миокарда (указывающее на энергетическую недостаточность). Повышенные уровни маркеров оксидативного стресса (маркеры перекисного окисления 
        липидов). <u>Токсикологическое исследование:</u> исключение токсических воздействий (алкоголь, наркотики, кардиотоксические вещества), которые могли вызвать вторичную 
        дилатацию миокарда. <u>Генетическое исследование:</u> при наличии семейного анамнеза для выявления мутаций, связанных с наследственными формами ДКМП (например, мутации 
        генов саркомерных белков). <u>Молекулярно-биологическое исследование:</u> выявление вирусных агентов в тканях миокарда (например, парвовирус B19, аденовирус, вирус Коксаки) 
        для исключения вирусного миокардита как причины дилатации.
        </p>                
    )
}
export default DilCardioLab