function HibsLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для хронической ишемической болезни сердца (ХИБС), характерно: при <u>гистологическом исследовании:</u> 1.	Сердце: в миокарде 
        наблюдаются участки замещения нормальных кардиомиоцитов соединительной тканью. Эти зоны выглядят как плотные, волокнистые структуры, 
окрашивающиеся в синий цвет при окраске по Массону. Сохранённые кардиомиоциты часто увеличены в размерах, с гиперхромными и увеличенными ядрами ("клетки с гиперхромным ядром").
В некоторых участках миокарда выявляются атрофированные клетки из-за хронической гипоксии.
Могут присутствовать признаки дегенерации кардиомиоцитов (признаки ишемического повреждения): зернистая дистрофия, вакуолизация цитоплазмы, липофусциноз.
Выраженный атеросклероз коронарных артерий, утолщение интимы, сужение просвета сосудов. Иногда встречаются признаки тромбоза или реканализации.
Утолщение соединительной ткани вокруг мелких сосудов в миокарде.
В местах рубцов или повреждения миокарда иногда обнаруживается лимфогистиоцитарная инфильтрация, указывающая на постишемическое воспаление.
- Изменения в области кардиосклероза (диффузного и очагового): замещение некротизированных участков миокарда плотной волокнистой соединительной тканью. Преобладание 
грубоволокнистого коллагена. Снижение плотности капиллярной сети в зонах фиброза. Увеличение размеров сохранившихся мышечных клеток на границе с зоной склероза. Гиперхромные ядра 
с признаками полиплоидии. В зоне, прилегающей к фиброзу, наблюдаются признаки жировой, зернистой или гидропической дистрофии. Сужение просвета капилляров, утолщение базальной 
мембраны. Признаки венозного застоя (полнокровие вен и капилляров).
- Изменения в зоне аневризмы сердца: истончённая стенка аневризмы (замещение миокарда соединительной тканью (практически полное отсутствие мышечных волокон)).
Грубоволокнистая структура фиброзной ткани, с очагами гиалиноза. Тромбоз в полости аневризмы: пристеночные тромбы, состоящие из фибрина, тромбоцитов и эритроцитов, со слоистой 
структурой. Признаки организации тромба: прорастание фиброзной ткани и новообразованных сосудов в старых тромботических массах. Воспалительные изменения (иногда): хронический 
воспалительный инфильтрат на границе аневризматической стенки (лимфоциты, макрофаги, единичные плазматические клетки).
2.	Лёгкие: признаки застойных изменений (расширение капилляров, отёк интерстициальной ткани, гемосидерофаги ("клетки сердечных пороков") в альвеолах).
3.	Печень: застойная печень с выраженной центролобулярной атрофией гепатоцитов, полнокровием синусоидов и разрастанием соединительной ткани вокруг центральных вен.
4.	Почки: признаки хронической ишемии (артериолосклероз, атрофия канальцев, фиброз стромы, уменьшение размеров клубочков).
5.	Головной мозг: возможны изменения в сосудах (атеросклероз) и признаки гипоксического повреждения нейронов, такие как их пикноз, вакуолизация или некроз.
Эти гистологические изменения отражают хронический характер ишемии, компенсаторные процессы (гипертрофию) и дегенеративные изменения, развивающиеся вследствие недостаточного 
кровоснабжения и гипоксии. <u>Биохимическое исследование:</u> определение уровня холестерина и липидов в крови (высокий уровень липопротеинов 
        низкой плотности и холестерина может указывать на атеросклеротический процесс); исследование активности ферментов (например, креатинкиназы - помогает оценить возможные 
        повреждения миокарда, если были подозрения на недавний инфаркт).
        </p>                
    )
}
export default HibsLab