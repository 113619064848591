function KronaLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для болезни Крона при <u>гистологическом исследовании:</u> можно обнаружить следующие характерные изменения:
1.	Трансмуральное воспаление: одним из характерных признаков болезни Крона является воспаление, которое захватывает все слои стенки кишки, включая слизистую, подслизистую, 
мышечную и серозную оболочки.
2.	Гранулемы: наличие неказеозных гранулем (неспецифический гранулематоз) является диагностическим критерием. Эти гранулемы имеют саркоидоподобное строение и состоят из 
скоплений эпителиоидных клеток и многоядерных гигантских клеток, окруженных лимфоцитами. Они могут быть обнаружены в любом слое стенки кишечника.
3.	Фокальный характер поражений: в отличие от язвенного колита, для болезни Крона характерно наличие участков нормальной слизистой оболочки (так называемые «пропущенные» или 
сегментарные поражения) между пораженными участками.
4.	Глубокие язвы: язвенные поражения часто глубокие и могут достигать мышечной и серозной оболочек. Они имеют вид трещин или длинных продольных дефектов.
5.	Лимфоидная инфильтрация: в стенке кишечника наблюдается значительная инфильтрация лимфоцитами, плазматическими клетками, нейтрофилами и макрофагами, что свидетельствует о 
хроническом воспалении.
6.	Фиброз: в стенке кишечника может обнаруживаться фиброз, особенно в подслизистом и мышечном слоях, что приводит к утолщению стенки и сужению просвета.
7.	Крипт-абсцессы: возможно образование крипт-абсцессов — скоплений нейтрофилов внутри кишечных крипт, что также указывает на воспалительный процесс.
8.	Микроэрозии и деформация крипт: нарушение структуры и архитектуры слизистой оболочки проявляется в виде эрозий и деформаций крипт.
Эти микроскопические изменения помогают дифференцировать болезнь Крона от других воспалительных заболеваний кишечника, таких как язвенный колит, и уточнить степень активности 
патологического процесса. <u>Бактериологическое исследование</u> (оно не может подтвердить диагноз болезни Крона. Для диагностики необходим комплексный подход, включающий 
    морфологические, иммуногистохимические и молекулярно-генетические исследования): направлено на выявление потенциальных инфекционных триггеров и исключение других причин 
    воспаления:
1. Mycobacterium avium subsp. paratuberculosis (MAP) - гипотетический инфекционный агент, связанный с болезнью Крона (по аналогии с болезнью Йоне у жвачных животных).
Обнаружение:
- Посев на специальные среды (с медленным ростом бактерий).
- Полимеразная цепная реакция (ПЦР) для выявления ДНК возбудителя.
- Микроскопия окрашенных препаратов на кислотоустойчивые бактерии.
Вероятность нахождения: MAP может быть обнаружен в поражённых тканях или в содержимом кишечника у части пациентов, но это не является обязательным диагностическим критерием.
2. Состав микробиоты кишечника: у пациентов с болезнью Крона часто выявляется дисбактериоз:
Уменьшение полезных бактерий (Faecalibacterium prausnitzii).
Увеличение патогенных и условно-патогенных бактерий (Escherichia coli, Clostridium difficile).
Эти изменения могут быть обнаружены при посеве кишечного содержимого или при использовании методов метагеномного секвенирования.
3. Исключение инфекционных заболеваний: бактериологическое исследование может подтвердить или исключить:
сальмонеллёз, шигеллёз, кампилобактериоз. Clostridium difficile (включая токсигенные штаммы). Эти инфекции могут вызывать симптомы, схожие с болезнью Крона. <u>Молекулярно-генетические 
    исследования:</u> подтверждение наследственного характера заболевания или выявление мутаций, связанных с воспалительным процессом.

NOD2/CARD15 мутации:
генетическое исследование на наличие мутаций в гене NOD2/CARD15, которые часто ассоциируются с болезнью Крона (особенно у пациентов европейского происхождения).

ATG16L1 и IRGM:
исследование генов, участвующих в аутофагии, нарушенная функция которых может способствовать хроническому воспалению.

IL23R (рецептор интерлейкина-23):
полиморфизмы в этом гене связаны с воспалительными заболеваниями кишечника, включая болезнь Крона.

GWAS (исследования ассоциаций по всему геному):
выявление других генетических вариаций, ассоциированных с повышенным риском болезни Крона. <u>Иммуногистохимическое исследование:</u> выявление воспалительных 
процессов, гранулём и активации иммунных клеток.

CD68 - маркер для идентификации макрофагов и эпителиоидных клеток гранулём.
Помогает дифференцировать болезнь Крона от других воспалительных заболеваний кишечника (например, язвенного колита).
При болезни Крона CD68: выраженная экспрессия, подтверждающая наличие гранулём из эпителиоидных клеток и макрофагов.

CD3, CD4, CD8
Оценка инфильтрации Т-лимфоцитами.
CD4+ Т-клетки указывают на активацию воспалительного процесса.

CD20
Маркер В-лимфоцитов, используется для оценки смешанной воспалительной инфильтрации.
При болезни Крона CD3/CD20: лимфоцитарная инфильтрация с преобладанием CD3+ (Т-клеток).

TNF-α (фактор некроза опухоли альфа)
Выявление повышенной экспрессии, характерной для активного воспаления в стенке кишечника.
При болезни Крона TNF-α: высокая экспрессия в гранулёмах и воспалённых участках слизистой.

S100, Langerin
Для выявления дендритных клеток и оценки иммунного ответа.

Маркеры васкуляризации (CD31, VEGF)
Для оценки ангиогенеза, который часто усиливается в поражённых тканях.  
        </p>
    )
}
export default KronaLab