function GemInsultLungs(){
    return (
        <p id="MsoBodyTextIndent" className="green">
       При геморрагическом инсульте, в легких может быть застой крови из-за нарушения сердечно-сосудистой регуляции и недостаточности кровообращения:
       они могут быть увеличены в объеме, плотные на ощупь, темно-красного или буроватого цвета, особенно в нижних долях, при разрезе выделяется большое количество темной, 
       венозной крови, часто отмечается скопление серозной жидкости в бронхах (при застойной сердечной недостаточности) и наблюдаются мелкие кровоизлияния в межальвеолярных 
       перегородках.
На фоне нарушения мозговой регуляции дыхания и сердечно-сосудистой деятельности возможен вторичный отек легких: значительное увеличение их массы, они влажные, тяжелые; при 
разрезе выделяется пенистая жидкость розоватого или кровянистого оттенка, альвеолы заполнены серозной жидкостью, могут содержать кровянистый экссудат; имеются участки пропитывания 
легочной ткани жидкостью, преимущественно в нижних отделах. В плевральной полости нередко наблюдается гидроторакс (скопление серозной жидкости).
        </p>
    )
}
export default GemInsultLungs