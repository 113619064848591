function FeAnemiSpleen(){
    return (
        <p id="MsoBodyTextIndent" className="green">
        При железодефицитной анемии может наблюдаться легкая пастозность и увеличение селезенки, что связано с хронической гипоксией и 
        нарушением микроциркуляции. (При В12/фолиеводефицитной анемии селезенка может приобретать буроватый оттенок - из-за разрушения крупных эритроцитов и последующего гемосидероза
        внутренних органов, также как и при гемолитических анемиях). В случаях, когда причиной дефицита железа являются аутоиммунные заболевания (например, системная красная 
        волчанка, ревматоидный артрит): можно наблюдать спленомегалию (масса увеличена до 300–500 г), консистенция селезенки плотная, на разрезе ткань темно-красного цвета с 
        заметными, увеличенными фолликулами (гиперплазия лимфоидной ткани).
        </p>
    )
}
export default FeAnemiSpleen