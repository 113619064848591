import { useSelector } from 'react-redux';

//Импорт компонентов пат изменений:
import GbSerdce from '../../patan/gb/GbSerdceAorta';
import SaharDiab1Serdce from '../../patan/saharDiab1/SaharDiab1Serdce';
import SaharDiab2Serdce from '../../patan/saharDiab2/SaharDiab2Serdce';
import CovidSerdce from '../../patan/covid/CovidSerdce';
import SifilisSerdce from '../../patan/sifilis/SifilisSerdce';
import GrippSerdce from '../../patan/gripp/GrippSerdce';
import TubercSerdce from '../../patan/tuberc/TubercSerdce';
import GreyvsSerdceAorta from '../../patan/greyvs/GreyvsSerdceAorta';
import HoshimotoSerdceAorta from '../../patan/hoshimoto/HoshimotoSerdceAorta';
import OglomeruloSerdce from '../../patan/oglomerulo/OglomeruloSerdce';
import FeAnemiSerdce from '../../patan/feAnemi/FeAnemiSerdce';
import PostgemAnemiSerdce from '../../patan/postgemAnemi/PostgemAnemiSerdce';
import IshInsultSerdce from '../../patan/ishInsult/IshInsultSerdce';
import GemInsultSerdce from '../../patan/gemInsult/GemInsultSerdce';
import InfarctMiocSerdceAorta from '../../patan/infarctMioc/InfarctMiocSerdceAorta';
import AlcoSerdceAorta from '../../patan/alco/AlcoSerdceAorta';
import DilCardioSerdceAorta from '../../patan/dilCardio/DilCardioSerdceAorta';
import GipCardioSerdceAorta from '../../patan/gipCardio/GipCardioSerdceAorta';
import MiocarditSerdceAorta from '../../patan/miocardit/MiocarditSerdceAorta';
import SepsisSerdceAorta from '../../patan/sepsis/SepsisSerdceAorta';
import HibsSerdceAorta from '../../patan/hibs/HibsSerdceAorta';

function PatanSerdceAorta() {

    //Получаем из хранилища состояния пат изменений:
    const gb = useSelector((state) => state.pat.gb);
    const saharDiab1 = useSelector((state) => state.pat.saharDiab1);
    const saharDiab2 = useSelector((state) => state.pat.saharDiab2);
    const covid = useSelector((state) => state.pat.covid);
    const sifilis = useSelector((state) => state.pat.sifilis);
    const gripp = useSelector((state) => state.pat.gripp);
    const tuberc = useSelector((state) => state.pat.tuberc);
    const greyvs = useSelector((state) => state.pat.greyvs);
    const hoshimoto = useSelector((state) => state.pat.hoshimoto);
    const oglomerulo = useSelector((state) => state.pat.oglomerulo);
    const feAnemi = useSelector((state) => state.pat.feAnemi);
    const postgemAnemi = useSelector((state) => state.pat.postgemAnemi);
    const ishInsult = useSelector((state) => state.pat.ishInsult);
    const gemInsult = useSelector((state) => state.pat.gemInsult);
    const infarctMioc = useSelector((state) => state.pat.infarctMioc);
    const alco = useSelector((state) => state.pat.alco);
    const dilCardio = useSelector((state) => state.pat.dilCardio);
    const gipCardio = useSelector((state) => state.pat.gipCardio);
    const miocardit = useSelector((state) => state.pat.miocardit);
    const sepsis = useSelector((state) => state.pat.sepsis);
    const hibs = useSelector((state) => state.pat.hibs);
    // const ushibRanaNoogr = useSelector((state) => state.dopPovrezhden.ushibRanaNoogr);
    
    return (
        <>
            {/* Туберкулез: */}
            {tuberc && <><TubercSerdce/>
            <br></br></>}
            {/* Сифилис: */}
            {sifilis && <><SifilisSerdce/>
            <br></br></>}
            {/* Грипп: */}
            {gripp && <><GrippSerdce/>
            <br></br></>}
            {/* Covid-19: */}
            {covid && <><CovidSerdce/>
            <br></br></>}
            {/* Железодеф анемия: */}
            {feAnemi && <><FeAnemiSerdce/>
            <br></br></>} 
            {/* Острая постгеморрагическая анемия: */}
            {postgemAnemi && <><PostgemAnemiSerdce/>
            <br></br></>} 
            {/* болезнь Грейвса: */}
            {greyvs && <><GreyvsSerdceAorta/>
            <br></br></>}
            {/* Хашимото: */}
            {hoshimoto && <><HoshimotoSerdceAorta/>
            <br></br></>}
            {/* Сахар диаб 1: */}
            {saharDiab1 && <><SaharDiab1Serdce/>
            <br></br></>}
            {/* Сахар диаб 2: */}
            {saharDiab2 && <><SaharDiab2Serdce/>
            <br></br></>}
             {/* Хронический алкоголизм: */}
             {alco && <><AlcoSerdceAorta/>
            <br></br></>}
            {/* ГБ: */}
            {gb && <><GbSerdce/>
            <br></br></>}
            {/* Инфаркт миокарда: */}
            {infarctMioc && <><InfarctMiocSerdceAorta/>
            <br></br></>}
            {/* ХИБС: */}
            {hibs && <><HibsSerdceAorta/>
            <br></br></>}
            {/* Сепсис: */}
            {sepsis && <><SepsisSerdceAorta/>
            <br></br></>}
            {/* Миокардит: */}
            {miocardit && <><MiocarditSerdceAorta/>
            <br></br></>}
            {/* Дилатационная кардиомиопатия: */}
            {dilCardio && <><DilCardioSerdceAorta/>
            <br></br></>}
            {/* Гипертрофическая кардиомиопатия: */}
            {gipCardio && <><GipCardioSerdceAorta/>
            <br></br></>}
            {/* Геморрагический инсульт: */}
            {gemInsult && <><GemInsultSerdce/>
            <br></br></>}
            {/* Ишемический инсульт: */}
            {ishInsult && <><IshInsultSerdce/>
            <br></br></>}
            {/* О. гломерулонефрит: */}
            {oglomerulo && <><OglomeruloSerdce/>
            <br></br></>}
            {/* Ссадины: */}
            {/* {ssadiny  && <><SsadinyDiagnoz/>
            <br></br></>} */}
        </>
    );
}

export default PatanSerdceAorta;