function MiocarditLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для миокардита, характерно: при <u>гистологическом исследовании:</u>  1. Сердце:
- Миокард: воспалительная инфильтрация (преобладание лимфоцитов, макрофагов, иногда нейтрофилов или эозинофилов в межклеточном пространстве; в тяжелых случаях — очаги с некрозом 
кардиомиоцитов, окруженные воспалительными клетками); дезорганизация кардиомиоцитов (отек цитоплазмы, вакуолизация, пикноз ядер); интерстициальный отек (расширение межклеточных 
пространств, заполненных серозной жидкостью); фиброз (участки замещения некротизированных кардиомиоцитов соединительной тканью (при хроническом течении миокардита)).
При идиопатическом миокардите Абрамова-Фидлера в составе воспалительного инфильтрата имеются гигантские клетки, похожие на клетки Пирогова-Лангханса; при миокардите Чагаса - 
своеобразные включения паразита в саркоплазме кардиомиоцитов.
- Сосуды: полнокровие капилляров и венул, иногда тромбоз мелких сосудов; периваскулярный отек и воспалительная инфильтрация.       
2. Легкие:
-	Отек легочной ткани: расширенные альвеолы, заполненные белковым экссудатом; гиперемия капилляров межальвеолярных перегородок.
-	Мелкие кровоизлияния: эритроциты в просвете альвеол и межальвеолярных перегородках.
-	Воспалительные изменения: лимфогистиоцитарная инфильтрация интерстиция в тяжелых случаях.
-       При тромбоэмболии: тромб в ветвях лёгочной артерии; зона некроза альвеолярных структур (разрушение альвеолярных стенок, наличие клеточного детрита в просветах альвеол); 
геморрагический инфаркт (cкопления эритроцитов в межальвеолярных перегородках, периваскулярный отёк и воспалительная инфильтрация).
3. Печень:
-	Застойные изменения: расширение центральных вен и синусоидов; накопление эритроцитов в синусоидах.
-	Гепатоцеллюлярная дистрофия: баллонная дистрофия и вакуолизация гепатоцитов, иногда очаговый некроз.
-	Инфильтрация воспалительными клетками: в портальных трактах или вокруг центральных вен (при сочетании с системным воспалением).
-       При тромбоэмболии: центролобулярный некроз (гепатоциты в зоне центральных вен теряют ядра и цитоплазматическую структуру); венозный застой (расширение синусоидов с 
накоплением эритроцитов, периферия долек может оставаться сохранной при компенсации).
4. Почки:
-	Застойная гиперемия: полнокровие капилляров клубочков и сосудов межуточной ткани.
-	Дистрофические изменения в эпителии канальцев: гранулярная и вакуолярная дистрофия эпителия, иногда участки некроза.
-	Интерстициальный отек: расширение межклеточных пространств с накоплением серозного экссудата.
-       При тромбоэмболии: ишемический инфаркт (некроз клеток проксимальных и дистальных канальцев, коллапс капилляров в клубочках, геморрагическая демаркационная зона по 
периферии инфаркта); cосудистые изменения (обтурация мелких артерий тромбами, артериолы и капилляры показывают признаки стаза).
5. Селезенка:
-	Полнокровие красной пульпы: расширение синусов, заполненных эритроцитами.
-	Гиперплазия белой пульпы: увеличение размеров лимфоидных фолликулов.
-       При тромбоэмболии: белая пульпа (полнокровие сосудов, снижение количества лимфоидных клеток); красная пульпа (зона некроза: деструкция ткани селезёнки, утрата клеточной 
структуры, скопления макрофагов, содержащих фагоцитированные эритроциты).
6. Головной мозг:
-	Отек: увеличение межклеточного пространства и отек астроцитов.
-	Мелкие кровоизлияния: эритроциты в тканях или субарахноидальном пространстве.
-	Полнокровие сосудов: расширенные капилляры и вены.
-       При тромбоэмболии: колликвационный некроз (гибель нейронов: потеря ядер, формирование "теневых" клеток, деструкция глиальных клеток, отёк периваскулярных пространств); 
геморрагические изменения (разрывы мелких сосудов, кровоизлияния в некротическую ткань, пропитывание тканей эритроцитами).
7. Поджелудочная железа:
-       При тромбоэмболии: ишемия(в ацинарных клетках утрата гранул зимогена, отёк и деструкция межуточной ткани); некроз (зона коагуляционного некроза с утратой клеточной 
структуры, возможен ферментативный жировой некроз в окружающей ткани).
8. Общие изменения:
-	Системное воспаление: лимфогистиоцитарная инфильтрация в различных органах.
-	Дистрофические процессы: проявления дистрофии и гипоксии в органах, связанных с нарушением системной гемодинамики.
-       При тромбоэмболии: окклюзия сосуда (обнаруживается тромб или эмбол, закупоривающий артерию или её ветвь. эмболы могут быть плотными, фибринозными или состоять из жировых, 
инфекционных или тканевых элементов); ишемия (ткани, находящиеся в зоне кровоснабжения поражённого сосуда, демонстрируют признаки острой ишемии: отёк, потерю ядер в клетках, 
исчезновение гликогена); некроз (в области ишемии формируется коагуляционный (в плотных органах) или колликвационный некроз (в мозге), некротизированные клетки теряют структуру, 
отмечается гиперхромия ядер или их кариолизис); геморрагическая трансформация (в случае повторного кровоснабжения некротических тканей отмечаются кровоизлияния, скопления 
эритроцитов, повреждение сосудов микроциркуляторного русла). <u>Биохимическое исследование тканей сердца (оценка уровня воспалительных маркеров):</u> измерение концентрации ферментов и медиаторов воспаления 
(цитокины, интерлейкины); Тропонин I/T (свидетельствует о повреждении кардиомиоцитов). <u>Бактериологическое и вирусологическое исследование (выявление инфекционного агента):</u> использование ПЦР для обнаружения 
ДНК/РНК вирусов (например, вирус Коксаки, парвовирус B19, вирус Эпштейна-Барр); посев тканей на бактериальную флору. <u>Иммуногистохимическое исследование:</u> оценка экспрессии маркеров воспаления (CD3, CD4, CD8 
(лимфоциты), CD68 (макрофаги)); маркировка клеточной гибели и фиброза(Caspase-3, TUNEL (апоптоз), коллаген I и III типов (фиброз)). <u>Токсикологический анализ:</u> исключение токсического воздействия веществ 
(алкоголь, наркотики, яды). <u>Генетическое исследование:</u> для исключения наследственных кардиомиопатий.

        </p>                
    )
}
export default MiocarditLab