function MiocarditKishki(){
    return (
        <p id="MsoBodyTextIndent" className="green">
       При миокардите возможны инфаркты кишечника в результате тромбоэмболии: обычно сегментарное поражение стенки кишки, соответствующее зоне кровоснабжения артерии.
       Возможны:
- Ишемический инфаркт: участок становится серовато-бледным или тёмным (при некрозе).
- Геморрагический инфаркт: при перекрытии мелких сосудов ткани кишки могут быть пропитаны кровью, что придаёт им багрово-красный оттенок.
Некротическая ткань становится мягкой и хрупкой.
Появление фибринозного или геморрагического выпота в брюшной полости при перитоните.
</p>
    )
}
export default MiocarditKishki