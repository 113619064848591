function GreyvsLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для болезни Грейвса (диффузный токсический зоб) при <u>гистологическом исследовании:</u> 
        1. Щитовидная железа: - гиперплазия и гипертрофия тироцитов (эпителиальные клетки щитовидной железы становятся высокими, призматическими, иногда с участками 
        псевдостратификации (многослойный вид)); - узкие коллоидные пространства (коллоид в фолликулах уменьшен, иногда наблюдаются его вакуолизация и светлый, "пенистый" вид);
- фолликулярный эпителий активен (могут наблюдаться ресорбционные лакуны на границе коллоида и тироцитов); - инфильтрация ткани лимфоцитами (часто встречается лимфоцитарная 
инфильтрация с формированием герминативных центров).
2. Сердце: - дистрофия кардиомиоцитов (в клетках миокарда выявляются признаки зернистой и вакуольной дистрофии, а также разрушение миофибрилл); - микроочаги некроза (в тяжелых 
случаях возможны небольшие участки некроза кардиомиоцитов); - интерстициальный отёк и лимфогистиоцитарная инфильтрация (признаки воспалительной реакции вокруг сосудов и в 
межклеточном пространстве).
3. Печень: - жировая дистрофия гепатоцитов (гепатоциты содержат мелкие и крупные капли жира (стеатоз), преимущественно в центральных зонах долек); - гиперплазия клеток Купфера
(увеличение количества макрофагов в синусоидах); - вакуолизация цитоплазмы (признаки токсического повреждения клеток из-за тиреотоксикоза).
4. Костно-мышечная система: - атрофия поперечно-полосатых мышц (уменьшение диаметра мышечных волокон с потерей поперечной исчерченности); - жировая инфильтрация мышц (в тяжелых 
случаях мышечная ткань может замещаться жировой тканью).
5. Костный мозг: - гиперплазия клеток кроветворения (усиление эритропоэза и гранулопоэза, вызванное ускорением обменных процессов).
6. Надпочечники: - гиперплазия коры (утолщение пучковой зоны, увеличение количества клеток с вакуолизированной цитоплазмой, что связано с гиперпродукцией глюкокортикоидов в ответ 
на стресс).
7. Глазные ткани (при офтальмопатии): -	инфильтрация ретробульбарной клетчатки (выявляются лимфоциты, плазматические клетки и макрофаги); - фиброз ретробульбарной клетчатки (в 
поздних стадиях наблюдается разрастание соединительной ткани); - увеличение объема жировой ткани (локальная гиперплазия адипоцитов).
8. Лимфатические узлы и селезёнка: - гиперплазия лимфоидной ткани (в лимфоузлах и селезёнке может наблюдаться увеличение количества лимфоцитов и активация герминативных центров). <u>
    Биохимическое исследование:</u> анализ посмертной крови на уровни: ТТГ (тиреотропного гормона) — низкий; Т4 свободного и Т3 — повышенные. <u>Cерологическое исследование:</u> 
    определение антител к рецептору ТТГ (AT-rTTG) в крови, что характерно для аутоиммунной природы болезни Грейвса. 
Нормальный уровень AT-rTTG: менее 1,75 МЕ/л (может варьироваться в зависимости от лаборатории).
При болезни Грейвса: уровень часто увеличен в несколько раз (например, ≥10 МЕ/л).      
        </p>
    )
}
export default GreyvsLab