function MkbVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При жизни имелась мочекаменная болезнь (конкременты в чашечно-лоханочной системе обеих почек (мелкие, диаметром до 0,5 см), 
        хронический калькулёзный пиелонефрит с интерстициальным фиброзом, гидронефроз правой почки (умеренная степень)), на что указывают макро- и микро- скопические 
        изменения в почках. Мочекаменная болезнь (нефролитиаз) — это хроническое заболевание, характеризующееся образованием конкрементов (камней) в органах мочевыделительной 
        системы, включая почки, мочеточники, мочевой пузырь и уретру, вследствие нарушения обмена веществ (водно-солевого, белкового) и изменения состава мочи. Клинически 
        заболевание проявляется болями в поясничной области (почечная колика), дизурией, гематурией, а также возможными инфекционно-воспалительными осложнениями, такими как 
        пиелонефрит. Мочекаменная болезнь может приводить к обструкции мочевых путей, гидронефрозу и развитию хронической почечной недостаточности. Указанное заболевание в 
        причинной связи с наступлением смерти - не состоит.</p>
    )
}
export default MkbVyvody