function OglomeruloKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При остром гломерулонефрите возможны: 
1. Отеки (особенно характерны для начальной стадии острого гломерулонефрита):
- Заметны в области лица (особенно выражены вокруг глаз — "почечные отеки").
- Могут распространяться на шею, верхние и нижние конечности, туловище.
- Кожа в местах отеков напряженная, бледная, гладкая.
- Анасарка (общий отек всего тела) в тяжелых случаях.
- Жидкость в серозных полостях (гидроторакс, асцит) — как косвенный признак, иногда сопровождающийся выпячиванием живота.	
2. Изменение цвета кожи и слизистых оболочек:
- Бледность (характерна для больных с почечной недостаточностью из-за анемии).
- Возможна легкая желтушность кожи, связанная с нарушением обмена билирубина.
- В некоторых случаях отмечаются цианоз или мраморный оттенок кожи, особенно на конечностях, что связано с гипоксией тканей.	
3. Петехиальные кровоизлияния: могут наблюдаться точечные кровоизлияния на коже (петехии) или на слизистых оболочках, чаще всего в области лица, шеи и верхней части туловища. 
Это связано с повышенной ломкостью капилляров на фоне гипертонии и нарушений свертываемости крови.	
4. Признаки артериальной гипертензии: видимые сосуды (особенно в области шеи и склер) могут быть напряженными и переполненными кровью.	
5. Признаки вторичных инфекций: если пациент страдал осложнениями (например, инфекциями кожи, язвами), могут быть видимы следы воспаления или заживления.	
6. Косвенные признаки хронической интоксикации:
- Сухость кожи или шелушение как проявление дегидратации.
- Неприятный запах от тела, возможен "аммиачный" запах от кожи из-за накопления мочевины и продуктов распада в тканях.	
7. Особенности конечностей
- Отмечается пастозность или более выраженные отеки нижних конечностей.
- Возможны следы нарушений кровообращения, например, акроцианоз.
        </p>
    )
}
export default OglomeruloKozha