function GipCardioVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При жизни имелась гипертрофическая кардиомиопатия (асимметричная форма): гипертрофия межжелудочковой перегородки (толщина МЖП 22 мм); гипертрофия 
        свободной стенки левого желудочка (толщина 16 мм); масса сердца – 480 г.; дезорганизация кардиомиоцитов и интерстициальный фиброз; уменьшение объема полости левого желудочка; 
        что подтверждается макро- и микро- скопическими изменениями сердца, 
        наряду с отсутствием других причин гипертрофии сердца (артериальной гипертензии, клапанных пороков, миокардита, ишемической болезни сердца, эндокринных заболеваний, 
        нарушений водно-электролитного баланса) и обнаружением мутаций в генах связанных с гипертрофической кардиомиопатией  MYH7 и MYBPC3.
        Гипертрофическая кардиомиопатия (ГКМП) — это генетически обусловленное заболевание миокарда, характеризующееся необъяснимой (не связанной с нагрузкой) гипертрофией стенок 
        левого и/или правого желудочков, чаще всего асимметричной гипертрофией межжелудочковой перегородки. Основными морфологическими особенностями являются гипертрофия 
        кардиомиоцитов, их дезорганизация, интерстициальный фиброз и уменьшение объема полости левого желудочка.
Заболевание приводит к нарушению диастолической функции сердца, обструкции выносящего тракта левого желудочка (в ряде случаев), аритмиям и повышенному риску внезапной сердечной 
смерти.
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.</p>
    )
}
export default GipCardioVyvody