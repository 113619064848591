function HibsSerdceAorta(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для хронической ишемической болезни сердца (ХИБС) характерно:
увеличение размеров сердца (кардиомегалия) за счет гипертрофии миокарда, особенно левого желудочка.
Уплотнение миокарда: миокард выглядит плотным, с серовато-белыми участками склероза (рубцевания), которые могут быть разбросаны диффузно или локализованы в зонах ранее 
перенесенных инфарктов миокарда.
Неравномерный цвет миокарда: чередование нормальной мышечной ткани с белесоватыми рубцовыми участками.
Уменьшение толщины стенки миокарда: в рубцовых участках стенка может быть истончена, что увеличивает риск аневризмы сердца.
Сосудистые изменения: коронарные артерии нередко имеют выраженный атеросклероз, иногда с наличием старых или свежих тромбов.
Дилатация камер сердца: камеры сердца, особенно левого желудочка, могут быть расширены в результате хронической перегрузки давлением или объемом.
Склеротические изменения клапанов: иногда наблюдаются сопутствующие изменения клапанов сердца (фиброз или кальциноз).
Жировая дистрофия эпикарда: в области эпикарда может отмечаться избыточное отложение жировой ткани.
Наличие внутрисердечных тромбов: в ушке левого предсердия или в области аневризмы может обнаруживаться пристеночный тромб.
Эти изменения являются следствием хронической гипоксии миокарда, нарушений кровоснабжения из-за атеросклероза коронарных артерий и замещения некротизированных участков миокарда 
соединительной тканью. Как признак хронической сердечной недостаточности - возможно накопление жидкости в полости перикарда (гидроперикард), полнокровие и расширение крупных вен 
(в нижней полой вене, печёночных венах) из-за застоя крови.
При наличии аневризмы сердца:
наиболее часто встречается в области передней стенки левого желудочка или верхушки сердца.
Стенка аневризмы истончена, состоит из плотной соединительной ткани.
Размер аневризмы варьируется от небольшого выпячивания до значительного дефекта с выраженной деформацией сердечной стенки.
Поверхность на разрезе серо-белого цвета (фиброзная ткань), иногда с участками тромбоза.
В аневризматической области могут находиться пристеночные тромбы, фиксированные к стенке. Эти тромбы различной консистенции: от рыхлых до плотных, со слоистым строением.
Стенки левого желудочка (в областях, не затронутых аневризмой) утолщены. Масса сердца увеличена.
Расширение левого желудочка, иногда правого желудочка и предсердий, связанное с хронической сердечной недостаточностью.
На внутренней поверхности коронарных артерий — атеросклеротические бляшки, иногда с кальцинозом и сужением просвета до 70–90%.
        </p>                
    )
}
export default HibsSerdceAorta