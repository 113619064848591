function GipCardioLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для гипертрофической кардиомиопатии (ГКМП), характерно: при <u>гистологическом исследовании:</u>  1. Сердце:
        - Гипертрофия кардиомиоцитов: значительное увеличение размеров мышечных клеток сердца (кардиомиоцитов), диаметр которых может превышать нормальные значения (более 20 мкм); 
        ядра кардиомиоцитов увеличены, гиперхромны, иногда имеют неправильную форму (зазубренность контуров ядер) - крупные уродливые ядра.
- Дезорганизация кардиомиоцитов: кардиомиоциты расположены хаотично (картина "завихрений"), утрачивают упорядоченность в нормальной поперечно-полосатой структуре, этот признак особенно выражен в области 
межжелудочковой перегородки.
- Интерстициальный фиброз: увеличение соединительной ткани в межклеточном пространстве, которое выглядит как фиброзные полосы, фиброз часто развивается в результате ишемии 
миокарда и увеличенной нагрузки на ткани.
- Признаки ишемии миокарда: возможно наличие участков микронекроза миокарда в результате недостаточного кровоснабжения гипертрофированных тканей, в отдельных кардиомиоцитах не 
различаются миофибриллы.
- Перенуклеарный нимб (гало): светлая зона вокруг ядер кардиомиоцитов, в которой при гистохимическом исследовании выявляется гликоген. 
- Изменения капиллярной сети: отмечается редукция капилляров на фоне гипертрофии кардиомиоцитов, что усугубляет гипоксию ткани.
- Артериолы и микрососуды: утолщение стенок артериол с гиперплазией медии, это может приводить к нарушению микроциркуляции. 
        2. Лёгкие: признаки отёка лёгких: заполнение альвеол серозным экссудатом, иногда с примесью эритроцитов (геморрагический компонент). Гемосидерофаги ("клетки сердечной 
        недостаточности") в просвете альвеол. Утолщение межальвеолярных перегородок из-за хронического венозного застоя. Признаки фиброза в интрстиции при длительном застое.
        3. Печень:
        - Центролобулярный застой: расширение синусоидов, полнокровие в центральной зоне долек.
        - Некрозы гепатоцитов: очаговые некрозы в центре долек из-за гипоксии.
        - Жировая дистрофия: жировые вакуоли в гепатоцитах, особенно в периферических зонах долек.
        - Фиброз: возможное развитие перицентрального фиброза при длительном хроническом венозном застое.
        4. Селезёнка:
        - Венозный застой: расширение венозных синусов и полнокровие.
        - Фиброз: разрастание соединительной ткани при длительном застое.
        5. Почки:
        - Застойные изменения: полнокровие клубочков, расширение капилляров; застой крови в мозговом слое.
        - Атрофия эпителия канальцев: дистрофические изменения эпителия, иногда признаки некроза;
        - Интерстициальный отёк: скопление жидкости в межуточной ткани.
        6. Головной мозг:
        - Отёк мозга: пропитывание ткани жидкостью, расширение периваскулярных и перивенозных пространств.
        - Застойные явления: полнокровие сосудов, особенно венозных.
        - Гипоксия нейронов: хроматолиз, вакуолизация цитоплазмы, исчезновение ядер у отдельных клеток. <u>Биохимическое исследование (исключение вторичных причин гипертрофии миокарда):</u> 
- Оценка гормонального профиля для исключения эндокринных заболеваний, таких как тиреотоксикоз (анализ уровня тиреоидных гормонов) или акромегалия (анализ уровня соматотропина, пролактина, 
инсулиноподобного фактора роста-1 (ИФР-1, IGF-1)).
- Определение уровня катехоламинов (например, при феохромоцитоме).
- Оценка уровня электролитов (натрий, калий, кальций) для исключения нарушений водно-электролитного баланса. <u>Генетическое исследование:</u> выявление мутаций в генах, связанных с ГКМП (например, MYH7, MYBPC3), 
подтверждение наследственного характера заболевания при семейной истории. <u>Иммуногистохимическое исследование (оценка экспрессии белков, участвующих в процессах гипертрофии миокарда):</u>  
        1. Белки саркомера:
- Бета-миозин тяжелой цепи (MYH7): усиленная экспрессия, так как мутации в гене MYH7 часто ассоциированы с ГКМП; может наблюдаться неоднородность окрашивания, указывающая на дезорганизацию саркомеров.
- Тропонин Т (TNNT2): изменения в экспрессии или локализации, что связано с нарушениями регуляции сократительной функции.
- Актин: гетерогенность окрашивания, отражающая структурные нарушения.
2. Белки, ассоциированные с внеклеточным матриксом и фиброзом:
- Коллаген I и III типа: увеличенная экспрессия в интерстиции, особенно вокруг кардиомиоцитов и сосудов, что подтверждает наличие интерстициального фиброза.
- Фибронектин: повышенная экспрессия в зонах фиброза, что свидетельствует о ремоделировании внеклеточного матрикса.
- Матриксные металлопротеиназы (MMP) и их ингибиторы (TIMP): избыточная экспрессия MMP указывает на деградацию внеклеточного матрикса, а повышенные уровни TIMP могут отражать попытку компенсаторного восстановления 
ткани.
3. Белки, связанные с гипертрофией и клеточным стрессом:
- Ангиотензин II-рецепторы (AT1R): усиленная экспрессия, связанная с активацией ренин-ангиотензиновой системы, способствующей гипертрофии миокарда.
- Фактор роста миокарда (TGF-β): повышенная экспрессия, стимулирующая фиброз и гипертрофию.
- HSP70 и HSP90 (тепловые шоковые белки): усиленная экспрессия, указывающая на клеточный стресс.
4. Белки клеточного цикла и апоптоза:
- p53: повышенная экспрессия в кардиомиоцитах, свидетельствующая о нарушении клеточного цикла.
- Bcl-2 и Bax: повышенный уровень Bax и снижение Bcl-2, указывающее на активацию апоптоза в миокарде.
5. Кальциевый обмен:
- Серкар (SERCA2a): сниженная экспрессия, что указывает на нарушения в кальциевом гомеостазе.
- Кальретикулин: повышенная экспрессия как ответ на стресс эндоплазматической сети.
6. Пролиферативные маркеры:
- Ki-67: в норме минимальная экспрессия, но может быть незначительно повышена в очагах гипертрофии.
Иммуногистохимическое исследование позволяет подтвердить структурные и функциональные изменения миокарда при ГКМП, включая гипертрофию, дезорганизацию саркомеров, фиброз, а также активацию клеточного стресса и 
апоптоза. Эти данные в комплексе подтверждают диагноз и помогают уточнить патогенез заболевания.
        </p>                
    )
}
export default GipCardioLab