function SepsisKishki(){
    return (
        <p id="MsoBodyTextIndent" className="green">    
Макроскопические изменения в ЖКТ при сепсисе включают гиперемию, кровоизлияния, дистрофические и некротические процессы в слизистой оболочке, увеличение лимфоидной ткани, 
тромбозы сосудов, а также возможное развитие язв, эрозий и перитонита. Эти изменения обусловлены системной гипоксией, ДВС-синдромом и токсическим действием циркулирующих 
медиаторов воспаления. При тромбозе венозных сосудов развивается геморрагический инфаркт кишечника (участки темно-красного или черного цвета, пропитанные кровью).
При некрозе и перфорации кишечной стенки развивается гнойно-фибринозный или геморрагический перитонит.
</p>
    )
}
export default SepsisKishki