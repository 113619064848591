function AlcoKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При хроническом алкоголизме возможны:
-	Кахексия (выраженное истощение тела, снижение массы мышц и жировой ткани).
-	Отеки и асцит, при наличии цирроза печени венозная сеть выражена вокруг пупка, заметна под кожей, вены утолщены, слегка извитые, расходятся радиально от пупка ("голова медузы").
-	Желтушность кожи и склер (признак нарушения билирубинового обмена).
-   Контрактура Дюпюитрена (утолщение и контрактура ладонной фасции в результате пролиферации фиброзной ткани, приводящее к сгибателыюй деформации и нарушению функции пальцев). 
-   Гинекомастия (как результат нарушения гормонального баланса, печеночных расстройств, нарушений метаболизма и воздействия алкоголя на различные гормональные системы организма).
-   Телеангиэктазии, сосудистые "звездочки" (из-за токсического воздействия алкоголя на сосудистую стенку, а также из-за метаболических нарушений, таких как цирроз печени, 
гормональный дисбаланс и повышение уровня эстрогенов).   
-   Белые пятна на ногтях (лейконихия) и линии Майра (белые или бледные полосы) возникают вследствие недостатка белка или других микроэлементов, дефицит которых часто наблюдается 
при хроническом алкоголизме.  
        </p>
    )
}
export default AlcoKozha