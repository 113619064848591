function NjakPechen(){
    return (
        <p id="MsoBodyTextIndent" className="green">При НЯК возможны признаки жировой дистрофии печени или первичного склерозирующего холангита (печень может быть уменьшена или 
        увеличена в размерах, в зависимости от стадии заболевания, на поздних стадиях часто наблюдается уменьшение печени из-за цирротических 
            изменений. На поздних стадиях поверхность печени становится бугристой из-за формирования узлов регенерации и фиброзных изменений, характерных для билиарного цирроза.
            В ранних стадиях поверхность может оставаться гладкой. Печень может иметь пятнистый вид с чередованием желтоватых, зеленоватых и сероватых участков. Желтоватые участки 
            обусловлены накоплением билирубина, а зеленоватый цвет – застоем желчи. Желчные протоки внутри печени неравномерно расширены и сужены из-за воспаления, фиброза и 
            рубцовых изменений. В крупных протоках могут быть видны наложения (желчные пробки) или беловатые фиброзные утолщения. На разрезе видны очаги желчного застоя 
            (зеленоватые участки) и разрастания фиброзной ткани. Чередование зон фиброза и регенерации придает печеночной ткани своеобразный "мозаичный" или "шнуровидный" вид.
            На поздних стадиях формирование билиарного цирроза приводит к значительным структурным изменениям: плотность ткани печени повышена, на разрезе хорошо заметны узлы 
            регенерации).
        </p>
    )
}
export default NjakPechen